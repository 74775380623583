import Button from '../../Button'
import InputNew from '../../InputNew'
import * as S from '../styles'

const Payment = () => {

  return (
    <>
      <S.Title>Altere suas informações de pagamento</S.Title>
      <S.WrapperSection>
        <S.Subtitle>Cartão de crédito salvo</S.Subtitle>
        <span> colocar cartões aqui</span>
      </S.WrapperSection>
      <S.WrapperSection configWidth='492px'>
        <InputNew label='Número do cartão' />
        <S.WrapperInputs>
          <S.WrapperCustom width='222px'>
            <InputNew label='Data de vencimento' mask='99/9999' />
          </S.WrapperCustom>
          <S.WrapperCustom width='120px'>
            <InputNew label='CVC' mask='9 9 9' />
          </S.WrapperCustom>

        </S.WrapperInputs>
        <InputNew label='Nome no cartão' />
        <InputNew label='CPF do cartão' />
      </S.WrapperSection>
      <S.WrapperButtons position='left'>
        <Button>Salvar Cartão</Button>
      </S.WrapperButtons>
    </>
  )
}

export default Payment