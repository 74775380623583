import BehavirIcon from "../../assets/icon_behavior.png";
import CalendarIcon from "../../assets/icon_calendar.png";
import CastredIcon from "../../assets/icon_castred.png";
import FurIcon from "../../assets/icon_fur.png";
import GenderIcon from "../../assets/icon_gender.png";
import MicroshipIcon from "../../assets/icon_microship.png";
import PawIcon from "../../assets/icon_paw.png";
import PersonIcon from "../../assets/icon_person.png";
import SpeciesIcon from "../../assets/icon_species.png";
import WeigthIcon from "../../assets/icon_weigth.png";
import LocationIcon from "../../assets/location_icon.png";
import ResizeIcon from "../../assets/resize_icon.jpg";
import DownloadIcon from "../../assets/download_icon.png";
import EditIcon from "../../assets/edit_icon.png";
import MoneyIcon from "../../assets/money_icon.png";
import HealthIcon from "../../assets/health_icon.png";

const Icon = (props) => {
  return <img width={16} height={19} src={props.src} />;
};

export const IconBehavior = <Icon src={BehavirIcon} />;
export const IconCalendar = <Icon src={CalendarIcon} />;
export const IconCastred = <Icon src={CastredIcon} />;
export const IconFur = <Icon src={FurIcon} />;
export const IconGender = <Icon src={GenderIcon} />;
export const IconMicroship = <Icon src={MicroshipIcon} />;
export const IconPaw = <Icon src={PawIcon} />;
export const IconPerson = <Icon src={PersonIcon} />;
export const IconSpecies = <Icon src={SpeciesIcon} />;
export const IconWeigth = <Icon src={WeigthIcon} />;
export const IconLocation = <Icon src={LocationIcon} />;
export const IconResize = <Icon src={ResizeIcon} />;
export const IconDownload = <Icon src={DownloadIcon} />;
export const IconEdit = <Icon src={EditIcon} />;
export const IconMoney = <Icon src={MoneyIcon} />;
export const IconHealth = <Icon src={HealthIcon} />;
