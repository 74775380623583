import { Button, Form, Input, InputNumber, Select } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import CollarSizeAPI from "./collarSizeApi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

const CreateCollarSize = (props) => {
  const { createCollarSize, findCollarSize, updateCollarSize } =
    CollarSizeAPI();
  const [collar, setCollar] = useState({ title: "fdfsf" });
  const [form] = useForm();
  const { id } = useParams();

  useEffect(() => {
    if (props.edit) {
      getCollar();
    }
  }, []);

  const getCollar = async () => {
    const data = await findCollarSize(id);
    form.setFieldsValue(data);
  };

  return (
    <Dashboard selected="3">
      <S.Wrapper>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          onFinish={props.edit ? updateCollarSize(id) : createCollarSize}
        >
          <Form.Item label="Titulo" name="title">
            <Input value={collar.title} />
          </Form.Item>
          <Form.Item
            initialValue={collar.qrcodeSize}
            name="qrcodeSize"
            label="Tamanho do QRCode"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>
          <Form.Item
            initialValue={collar.qrBoxSize}
            name="qrBoxSize"
            label="Tamanho da caixa do QRCode"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            initialValue={collar.qrSlimRange}
            name="qrSlimRange"
            label="Espessura do QRCode"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            initialValue={collar.codeBoxHeight}
            name="codeBoxHeight"
            label="Altura da Caixa do Código"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            initialValue={collar.codeBoxWidth}
            name="codeBoxWidth"
            label="Largura da Caixa do Código"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            initialValue={collar.codeLetterSpacing}
            name="codeLetterSpacing"
            label="Espaçamento das letras do código"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            initialValue={collar.codeBoxBorderRadius}
            name="codeBoxBorderRadius"
            label="Raio da Quina do Código"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            initialValue={collar.qrBoxBorderRadius}
            name="qrBoxBorderRadius"
            label="Raio da Quina do QR"
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>

          <Form.Item
            initialValue={collar.codeFontSize}
            name="codeFontSize"
            label="Tamanho da fonte"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            initialValue={collar.codeVerticalCompress}
            name="codeVerticalCompress"
            label="Esticamento da Fonte "
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            initialValue={collar.engrossamentoDaLetra}
            name="engrossamentoDaLetra"
            label="Grossura da Letra"
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>

          <Form.Item
            initialValue={collar.codeFont}
            label={"Fonte"}
            name={`codeFont`}
          >
            <Select
              options={[
                {
                  value: "RobotoMono-ExtraLight",
                  label: "ExtraLight",
                },
                {
                  value: "RobotoMono-Light",
                  label: "Light",
                },
                {
                  value: "RobotoMono-Regular",
                  label: "Regular",
                },
                {
                  value: "RobotoMono-Medium",
                  label: "Medium",
                },
              ]}
            />
          </Form.Item>

          <Button htmlType="submit">
            {props.edit ? "Editar" : "Criar"} Tamanho de Coleira
          </Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};

export default CreateCollarSize;
