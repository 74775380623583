import { MaskedInput } from "antd-mask-input";
import * as S from "./styles";
import { Button, Checkbox, Divider, Form, Input } from "antd";

import { ChangePassword } from "./changePasswordAPI";
import Logo from "../../../assets/Logo_Laranja.svg";

export const ChangePass = () => {
  const [form] = Form.useForm();

  const onFinish = ChangePassword();

  return (
    <S.Wrapper>
      <S.Content>
        <img width={200} src={Logo}></img>

        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          labelAlign="top"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ width: "100%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            label="Senha"
            rules={[
              { required: true, message: "Por favor informe sua nova senha!" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <S.SubmitButton
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              Entrar
            </S.SubmitButton>
          </Form.Item>
        </Form>
      </S.Content>
    </S.Wrapper>
  );
};
