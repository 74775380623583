import axios from "axios"
import { _baseUrl } from "../utils/constants"
import { getDoneeByEntityId, getDoneeByTotem } from "./donee-client"


export const createCustomLink = (body) => {
    return axios.post(`${_baseUrl}/customLink`, body)
        .then(res => {
            console.log('res: ', res)
            return res
        })
}

export const getCustomLinkByDoneeId = (doneeId) => {
    return axios.get(`${_baseUrl}/customLink/doneeId/${doneeId}`)
        .then(res => {
            console.log('res: ', res)
            return res
        })
}

export const getCustomLinkByTotem = (totem) => {
    return getDoneeByTotem(totem)
        .then(res => {
            return res
        })
}

export const getCustomLinkByEntityId = (id) => {
    return getDoneeByEntityId(id)
        .then(res => {
            console.log('res: ', res)
            if (res && res.data) {
                return getCustomLinkByDoneeId(res.data._id)
            }
            return res
        })
}

export const updateCustomLink = (newCustomLink) => {
    return axios.put(`${_baseUrl}/donee/update`, newCustomLink)
        .then(res => {
            console.log('res: ', res)
            return res
        })
}