import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../services/api";
import { setConfig } from "../register-pet/RegisterPetSlice";
import { setData, setPet } from "./simulator.slice";

const SimulatorAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const registerLead = (code) => async (data) => {
    dispatch(setData({ phone: data.telefone, name: data.nome }));
    try {
      await http.post("/lead", {
        ...data,
      });

      navigate("/pet-sim/success/" + code);
    } catch (error) {
      navigate("/pet-sim/success/" + code);
    }
  };

  const loadSimulation = async (code) => {
    try {
      const response = await http.get("/simulation/" + code);
      dispatch(setConfig(response.data.pet.config));
      dispatch(setPet(response.data.pet));
    } catch (error) {
      navigate("/");
    }
  };

  return { registerLead, loadSimulation };
};

export default SimulatorAPI;
