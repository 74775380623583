export default function FooterWeb() {
  return (
    <div className="bg-white w-full h-28 hidden lg:h-[60px] lg:flex flex-col lg:flex-row lg:items-center lg:justify-between lg:px-32 justify-start py-4 items-center text-zinc-500 mt-6">
      <p className="mb-4 lg:mb-0">© 2023  Ajudapet</p>
      <div className="flex flex-row gap-3 text-sm ">
        <a href="https://ajuda.pet/legal" rel="noreferrer" target="_blank">Termos</a>
        <a href="https://ajuda.pet/privacy" rel="noreferrer" target="_blank">Politica de privacidade</a>
      </div>
    </div>
  )
}