import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

const UsersAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const getUsers = async () => {
    try {
      const response = await http.get("/user/company/" + authState.user._id);
      return response.data;
    } catch (error) {}
  };

  const getUser = async (id) => {
    try {
      const response = await http.get("/user/" + id);

      return response.data;
    } catch (error) {}
  };

  const deleteUser = (id) => {
    try {
      const response = http.delete("user/" + id);

      //navigate("/admin/companies");
    } catch (error) {}
  };

  const createUser = (e) => {
    try {
      const response = http.post("user", e);

      navigate("/admin/users");
    } catch (error) {}
  };

  const updateUser = (id) => (e) => {
    try {
      const response = http.patch("user/" + id, e);

      navigate("/admin/users");
    } catch (error) {}
  };

  return {
    getUsers,
    getUser,
    updateUser,
    createUser,
    deleteUser,
  };
};

export default UsersAPI;
