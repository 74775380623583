import styled, { css } from "styled-components";
import { Button } from "antd";

export const WrapperDescription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const WrapperRegisterCellphone = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  margin-top: 28px;
`;

export const WrapperPhone = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
`;

export const WrapperDataUser = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const ContainerIconGender = styled.div`
  display: flex;
`;

export const ContainerTextGender = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TextGender = styled.p`
  font-size: 20px;
  font-weight: 600px;
  line-height: 30px;
  letter-spacing: 0.3px;
`;

export const WrapperGender = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  gap: 16px;
`;

export const SucessWrapperTitle = styled.div`
  max-width: 284px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const SucessTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
`;

export const SucessSubtitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
`;

export const WrapperSubtitle = styled.div`
  width: 284px;
  display: flex;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: 16px;
  `}
`;

export const SucessDescription = styled(Description)`
  text-align: center;
`;

export const WrapperSucessButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: 48px;
  gap: 20px;
`;

export const WrapperLogo = styled.div`
  max-width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 40px;
`;

export const imageLogo = styled.img``;

export const WrapperSucessDescription = styled(WrapperDescription)`
  margin-bottom: 96px;
`;

export const WrapperListBenefits = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 55px;
  gap: 32px;
`;

export const WrapperItemBenefits = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 327px;
  gap: 16px;
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
  height: 50px;
  width: 344px;
  margin-bottom: 40px;

  @media screen and (max-width: 480px) {
    width:100%;
  }
`;

export const TextBenefits = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #777e91;
`;
