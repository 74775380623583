import { Button, Dropdown, QRCode, Row, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TagsAPI from "./TagsAPI";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { isSuperAdmin } from "../login/loginSlice";

const MySwal = withReactContent(Swal);

export const MyTags = () => {
  const [collars, setCollars] = useState([]);
  const { getTags, generateTags } = TagsAPI();
  const [total, setTotal] = useState(0);
  const isSuperadmin = useSelector(isSuperAdmin);
  const navigate = useNavigate();
  useEffect(() => {
    loadTags(1);
  }, []);

  const columns = [
    {
      title: "Código",
      render: (row) => `${row.code}`,
    },
    {
      title: "Dominio",
      render: (row) => `${row.path}`,
    },
    {
      title: "Status",
      render: (row) => `${row.pet ? "Ativo" : "Não vinculado"}`,
      filters: [
        {
          text: "Ativo",
          value: "Ativo",
        },
        {
          text: "Não vinculado",
          value: "Não vinculado",
        },
      ],
      onFilter: (value, record) =>
        (record.pet ? "Ativo" : "Não vinculado") === value,
    },

    {
      title: "Data de Criação",
      render: (row) => `${new Date(row.createdAt).toLocaleDateString("pt-BR")}`,
    },

    {
      title: "Data de Ativação",
      render: (row) =>
        `${
          row.pet
            ? new Date(row.pet.createdAt).toLocaleDateString("pt-BR")
            : "Não vinculado"
        }`,
    },
    {
      title: "Ações",
      render: (row, index, column, i) => {
        const items = [
          {
            key: "actions_3_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  MySwal.fire({
                    html: (
                      <Row justify={"center"}>
                        <QRCode value={`${row.path}/${row.code}`}></QRCode>
                      </Row>
                    ),
                  });
                }}
              >
                Visualizar QR
              </Button>
            ),
          },
        ];
        if (row.pet) {
          items.push({
            key: "actions_4_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  window.open(
                    "https://plataforma-v2.ajuda.pet/profile/" + row.code
                  );
                }}
              >
                Visualizar Pet
              </Button>
            ),
          });
        }
        return (
          <Dropdown
            menu={{
              items: [...items],
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const loadTags = async (page) => {
    const response = await getTags((page - 1) * 50);
    setCollars(response.codes);
    setTotal(response.total);
  };

  return (
    <Dashboard selected="7">
      <S.Wrapper>
        {isSuperadmin && (
          <Row>
            <Button
              onClick={async () => {
                const { value: answer } = await Swal.fire({
                  title: "Quantos códigos deseja gerar?",
                  input: "number",
                  inputLabel: "Quantidade de códigos",
                  inputValue: 0,
                  showCancelButton: true,
                  inputValidator: (value) => {
                    if (!value) {
                      return "Informe a quantidade!";
                    }
                  },
                });

                if (answer) {
                  const quantity = parseInt(answer);
                  await generateTags(quantity);
                  loadTags();
                }
              }}
            >
              Gerar novos códigos
            </Button>
          </Row>
        )}
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={collars}
          pagination={{
            pageSize: 50,
            onChange: (e, size) => {
              loadTags(e);
            },
            total: total,
          }}
        />
      </S.Wrapper>
    </Dashboard>
  );
};
