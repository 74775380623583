import { Col } from "antd";
import styled, { css } from "styled-components";
import media from "styled-media-query";
import { CopyOutlined } from '@ant-design/icons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 24px;
  background-color: #fff;
`;

export const WrapperPage = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WrapperCustom = styled.div`
  ${({ width }) => css`
    max-width: ${width};
  `}
`;
export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e6e8ec;
  gap: 19px;
`;
export const PetWrapper = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  background: rgb(245, 245, 245);
  padding: 0.5rem;
  border-radius: 15px;
  background-color: #f2f2f233 !important;
`;

export const PetTitleWrapper = styled.div`
  margin-top: 2rem;
`;

export const PetAvatar = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 132px;
  object-fit: cover;
`;

export const PetTitle = styled.span`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */

  /* Neutrals/2 */

  color: #23262f;
`;

export const Title = styled.h2`
  font-size: 20px;
  line-height: 56px;
  font-family: "Readex pro";
  font-style: normal;
  font-weight: 700;
  color: #23262f;
  ${media.lessThan("medium")`
    font-size: 14px;
  `}
`;

export const Subtitle = styled.h3`
  font-family: "Readex pro";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #23262f;
  font-weight: 700;
  text-transform: capitalize;

  
`;

export const WrapperSection = styled.div`
  ${(configWidth) => css`
    font-family: "DM sans";
    display: flex;
    flex-direction: column;
    margin: 0 0 24px 0;
    gap: 27px;
    width: auto;

    ${configWidth} {
      max-width: ${configWidth};
    }
  `}
`;

export const SpanBold = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #000;
`;

export const WrapperInputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-height: 80px;
  gap: 12px;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid #e6e8ec;
  border-radius: 8px;
`;

export const Input = styled.input`
  font-family: "Readex pro";
  border: none;
  padding: 12px 16px;
  padding-right: 40px;
  border-radius: 8px;
  width: 100%;

  &::placeholder {
    color: #b1b5c4;
    font-size: 14px;
    line-height: 24px;
  }

  &:focus {
    outline: none;
  }
`;

export const IconButton = styled.button`
  position: absolute;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #353945;
`;

export const WrapperOptionDelete = styled.button`
  display: flex;
  flex-direction: column;
  border: none;
  background: none;
  gap: 4px;
  cursor: pointer;
`;

export const DescribeDeleteOption = styled.span`
  font-family: "DM Sans";
  color: #747a80;
  font-size: 12px;
  font-weight: 500;
`;

const modifierWrapperButtons = {
  right: () => css`
    justify-content: flex-end;
  `,

  left: () => css`
    justify-content: flex-start;
  `,
};

export const WrapperButtons = styled.div`
  ${({ position }) => css`
    display: flex;
    flex-direction: row;
    gap: 28px;

    ${modifierWrapperButtons[position]}

    ${media.lessThan("medium")`
      flex-direction: row;
      gap: 12px;
    `}
  `}
`;

export const WrapperButtonsDelete = styled.div`
  display: flex;
  flex-direction: column;
  gap: 67px;
`;

export const WrapperAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${media.lessThan("small")`
    flex-direction: column;
  `}
`;

export const DivEmpty = styled.div``;

export const Button = styled.button`
  padding: 5px 10px;
  background-color: #f67e48;
  color: white;
  border-radius: 20px
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative
`;

export const StyledCopyIcon = styled(CopyOutlined)`
  cursor: pointer;
  margin-left: 10px;
  color: #000; 
  &:hover {
    color: #1d4ed8; 
  }
  position: absolute;
  right: 10px;
  top: 65%;
  transform: translateY(-50%);
`;