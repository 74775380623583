import AuthPhoneApiPre from "./AuthPhoneApiPre";
import * as S from "./styles";
import { Form, Input, Space, Spin } from "antd";
import Swal from "sweetalert2";
import { useAPIPet, useAPITag } from "../../../services/api";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { useSelector } from "react-redux";
import { getWhatsappStatus, selectRegisterstate } from "../RegisterPetSlice";
import { WhatsAppOutlined } from "@ant-design/icons";
import { navigate } from "react-router-dom";

export const FinalRegister = () => {
  const { id } = useParams();
  const { http } = useAPITag();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { ValidateTag, AuthPhone } = AuthPhoneApiPre();
  const [inputDisabled, setInputDisabled] = useState(true);
  const registerState = useSelector(selectRegisterstate);
  const phoneRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const wppStatus = useSelector(getWhatsappStatus);
  const [userPhone, setUserPhone] = useState("");

  const getTag = async (id) => {
    try {
      const response = await http.get("tags/" + id);
      const userPhone = response.data.tag.userPhone;
      setUserPhone(userPhone);
    } catch (error) {
      throw error;
    }
  }
  useEffect(() => {
    setSearchParams([]);
    getTag(id);
    ValidateTag(id);
  }, []);

  const onFinishFailed = () => {
    Swal.fire({
      title: "Preencha todos os campos",
      icon: "error",
    });
  };

  const AuthPhonePre = async (values) => {
    console.log(values)
    try {
      const { ddd, telefone } = values;
      const cleanedTelefone = telefone ? telefone.replace(/\D/g, "") : "";

      if (ddd + cleanedTelefone !== userPhone) {
        console.log("aqui")
        Swal.fire({
          title: "O número de telefone não corresponde ao registrado",
          icon: "error",
        });
        return;
      }
      form.submit();
    } catch (error) {
      console.error("Erro em AuthPhone:", error);
    }
  };

  const savePreRegister = async (data) => {
    try {
      const response = await http.patch('/tags/change-status/' + id, data);
    } catch (error) {
      console.log(error);
    }
  }

  const onNavigateToConfirmCode = async () => {
    const formValues = form.getFieldValue();
    await savePreRegister({ statusQr: "pending", userPhone: formValues.ddd + formValues.telefone.replace(/\D/g, "") });
    navigate(`/confirm-code/${id}`);
  };

  const onChange = (e) => {
    if (e.unmaskedValue.length > 5) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  };

  const onEditAreaCode = (e) => {
    const target = e.srcElement || e.target;

    const maxLength = parseInt(target.attributes["maxlength"].value, 10);

    const currentLength = target.value.length;

    if (currentLength >= maxLength) {
      phoneRef?.current?.focus();
    }
  };

  return !registerState.config.logo ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large"> </Spin>
    </div>
  ) : (
    <RegisterPetLayout
      hasHeader
      description="Insira o número do seu celular e receba o código de ativação da coleira."
      title="Cadastrar nova coleira"
      size={5}
      step={1}
    >
      <Form
        form={form}
        name="basic"
        layout="horizontal"
        colon={false}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 25,
          marginTop: "1rem",
        }}
        onFinishFailed={onFinishFailed}
        onFinish={AuthPhone}
        autoComplete="off"
      >
        <Space>
          <Form.Item
            name="ddd"
            rules={[{ required: true, message: "Informe o DDD" }]}
          >
            <S.StyledAreaInput
              maxLength={2}
              type="tel"
              onKeyUp={onEditAreaCode}
            />
          </Form.Item>
          <Form.Item
            name="telefone"
            rules={[{ required: true, message: "Informe o telefone" }]}
          >
            <S.StyledInput
              ref={phoneRef}
              onChange={onChange}
              mask={"00000-0000"}
              type="tel"
            />
          </Form.Item>

          <Form.Item initialValue={"sms"} name="grantType">
            <Input type="hidden" />
          </Form.Item>
        </Space>
      </Form>
      <div className="flex flex-col w-full gap-4 mt-12 justify-center items-center">
        {wppStatus && (
          <S.SubmitWhatsappButton
            onClick={() => {
              form.setFieldValue("grantType", "whatsapp");
              const formValues = form.getFieldValue();
              AuthPhonePre(formValues);
            }}
            disabled={inputDisabled}
            type="primary"
            htmlType="submit"
          >
            <WhatsAppOutlined></WhatsAppOutlined>
            Receber por Whatsapp
          </S.SubmitWhatsappButton>
        )}
        <S.SubmitButton
          className={"whatsapp "}
          onClick={() => {
            form.setFieldValue("grantType", "sms");
            const formValues = form.getFieldValue();
            AuthPhonePre(formValues);
          }}
          disabled={inputDisabled}
          type="primary"
          htmlType="submit"
        >
          Receber por SMS
        </S.SubmitButton>
      </div>
    </RegisterPetLayout>
  );
};
