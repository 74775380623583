import { Button, Col, Input } from "antd";
import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;

  ${media.lessThan("small")`
    padding: 0px;
  `}
  .ant-col {
    background: #f5f5f5;
  }
`;

export const DesktopHeader = styled.img`
  width: 95%;
`;

export const KnowButton = styled(Button)`
  margin-top: 2rem;
  background-color: #ff6e30;
  color: white;
  width: 326px;
  border-radius: 50px;
  height: 56px;
  ${media.lessThan("small")`
    font-size: 16px !important;
    margin-top: 16px;
  `}
`;

export const FooterContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 32px 24px;
  gap: 10px;

  background: #f4f5f6;
`;

export const StyledIconLink = styled.a`
  transition: all ease-out 0.3s;
  color: black;

  &:hover {
    color: #ff6e30;
  }
`;

export const FooterTitle = styled.span`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height, or 100% */
  margin-bottom: 8px;
  margin-top: 15px;

  text-transform: uppercase;

  /* Neutrals/2 */

  color: #23262f;
`;

export const FooterDescription = styled.span`
  /* Button 2 */

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  /* identical to box height, or 114% */

  /* Neutrals/4 */

  color: #777e91;
`;

export const Copyright = styled.span`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  /* Neutrals/4 */

  color: #777e91;
`;

export const CollarContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  background-color: #e6e8ec;

  img.img-icon {
    width: 38px;
  }

  .ant-row {
    width: 100%;
    margin-bottom: 1rem;
  }

  .youtube-player {
    cursor: pointer;
    transition: all 0.3s ease-out;

    :hover {
      opacity: 0.85;
      transform: scale(1.01);
    }
  }
`;

export const CollarInfo = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  /* identical to box height, or 131% */

  color: #23262f;
`;

export const SearchInput = styled(Input.Search)`
  margin-top: 2rem;
  width: 586px;
  margin-top: 2rem;
  width: 586px;
  height: 58px;
  background-color: white;
  border-radius: 70px;
  padding: 1rem;
  .ant-input-search-button {
    background-color: #ff6e30;
    border-radius: 50px;
    color: white;
    border-start-start-radius: 50px !important;
    border-start-end-radius: 50px !important;
    border-end-end-radius: 50px !important;
    border-end-start-radius: 50px !important;
  }

  svg {
    fill: white;
  }
  input {
    border-radius: 50px !important;
  }
`;

export const InfoSmartLabel = styled.div`
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 85%;
  /* identical to box height, or 150% */
  margin: auto;
  color: #6b7280;
  ${media.lessThan("small")`
    font-size: 16px;
  `}
`;

export const InputInfo = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */
  margin-bottom: 1rem;
  color: #777e90;
`;

export const CollarTitle = styled.span`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ff6e30;
`;

export const Content = styled.div`
  display: flex;
  align-content: center;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const WrapperContent = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
`;
