import { Button, Col, DatePicker, Form, Modal, Row } from "antd";
import * as S from "./styles";
import Swal from "sweetalert2";
import { GestorPetAPI } from "./gestorPetAPI";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { UploadInput } from "../../components/uploadInput";

export const Medicines = (props) => {
  const { setNewHistoric } = GestorPetAPI();
  const { updatePet } = GestorPetAPI();
  const user = useSelector(getUser);
  const [newConsultModal, setNewCosultModal] = useState(false);
  const [photo, setPhoto] = useState([]);
  const isOwner = () => {
    if (user == null) return false;

    return props.pet.tutor?._id === user._id;
  };
  const [form] = useForm();
  const createHistoric = async () => {
    setNewCosultModal(true);
  };

  const onFinish = async (e) => {
    if (!props.pet.medicines) {
      props.pet.medicines = [];
    }
    e.photo = photo[0] ?? null;
    props.pet.medicines.push(e);

    await updatePet(props.pet._id, {
      medicines: props.pet.medicines,
    });

    setNewCosultModal(false);
    props.refresh();
    form.setFieldsValue({});
  };

  return (
    <Col style={{ overflowX: "scroll" }} span={24}>
      <Modal
        onCancel={() => {
          setNewCosultModal(false);
          form.setFieldsValue({});
        }}
        onOk={form.submit}
        open={newConsultModal}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          labelAlign="top"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ width: "80%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="Nome do Remédio"
            rules={[{ required: true, message: "Informe o nome" }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            name="period"
            label="Período"
            rules={[{ required: true, message: "Informe o Período" }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item name="prescription" label="Prescrição">
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            name="dataInicio"
            label="Data de Inicio"
            rules={[{ required: true, message: "Informe a data do inicio" }]}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              placeholder="Selecione a Data do Inicio"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="dataFim"
            label="Data de Fim"
            rules={[{ required: true, message: "Informe a data de fim" }]}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              placeholder="Selecione a Data do Final"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <UploadInput
            label={"Upload Imagem do medicamento"}
            onChange={setPhoto}
            paths={photo}
          ></UploadInput>
        </Form>
      </Modal>
      {isOwner() && (
        <Row justify={"end"}>
          <Button onClick={createHistoric}>Novo Medicamento</Button>
        </Row>
      )}

      <Col className="w-full">
        {props.pet.medicines &&
          props.pet.medicines.map((e) => {
            return (
              <Row style={{ marginTop: "3rem" }}>
                <InfoPet title="Nome" info={e.title} />
                <InfoPet title="Período" info={e.period} />
                <InfoPet title="Prescrição" info={e.prescription} />
                <InfoPet
                  title="Data de Inicio"
                  info={new Date(e.dataInicio).toLocaleDateString("pt-BR")}
                />
                <InfoPet
                  title="Data de Fim"
                  info={new Date(e.dataFim).toLocaleDateString("pt-BR")}
                />
                <Button
                  onClick={() => {
                    if (e.photo) {
                      Swal.fire({
                        imageUrl: e.photo.url,
                        imageAlt: "imagemUrl",
                      });
                    }
                  }}
                >
                  {" "}
                  Ver imagem
                </Button>
              </Row>
            );
          })}
      </Col>
    </Col>
  );
};

const InfoPet = (props) => {
  return (
    <Col span={4}>
      <Row>
        <Col span={24}>
          <Col>
            <S.InfoTitle>{props.title}</S.InfoTitle>
          </Col>
          <S.InfoPet>{props.info}</S.InfoPet>
        </Col>
      </Row>
    </Col>
  );
};
