import Title from "../../../../components/Title";
import Description from "../../../../components/Description";
import { useDonation } from "../../../../context/DonationContext";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import confetti from "canvas-confetti";

const ThankYou = () => {
  const { donationValue } = useDonation();

  useEffect(() => {
    confetti({
      particleCount: 150,
      spread: 180,
      origin: { y: 0.6, zIndex: 0 },
    });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <S.WrapperImg>
        <S.Img src="/images/ThanksDog.png" />
      </S.WrapperImg>
      <S.WrapperThanks>
        <Title>Obrigado por sua doação!</Title>
        <Description>
          A doação de R${donationValue} para o AjudaPet foi confirmada!
          <br />
          Sua contribuição impactará na vida de muitos pets!
        </Description>
      </S.WrapperThanks>
      <S.WrapperButton>
        <Description>
          Quer saber mais sobre nós? acesse nossa plataforma!
        </Description>

        <S.SubmitButton onClick={() => navigate("/")}>
          Conheça o Ajuda Pet
        </S.SubmitButton>

        <S.Description>
          AjudaPet Soluções para a Causa Animal© Copyright - Todos os direitos
          reservados.2021 CNPJ: 31.038.058/0001-58
        </S.Description>
      </S.WrapperButton>
    </>
  );
};

export default ThankYou;
