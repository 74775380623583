import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
  border-radius: 12px;
`;

export const Upload = styled.label`
  input[type="file"] {
    display: none;
  }
  margin-right: 1rem;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;
