import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../services/api";
import Swal from "sweetalert2";

export const HomeAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const findCode = async (code) => {
    try {
      const response = await http.get(`pets/${code}`);
      if (response.data.length > 0) {
        navigate(`/profile/${code}`);
        return;
      }
      Swal.fire({ title: "Pet não encontrado", icon: "error" });
    } catch (error) {
      Swal.fire({ title: "Pet não encontrado", icon: "error" });
    }
  };

  const getPDFUrl = async () => {
    const response = await http.get("global-config/pdf-api");
    return response.data.value;
  };
  return { findCode, getPDFUrl };
};
