// import Input from '../../Input'
import React, { useEffect, useState } from "react";
import * as S from "../styles";
import { useForm } from "react-hook-form";
import Button from "../../Button";
import { PetsAPI } from "./PetsAPI";
import { Col, Row } from "antd";
import { ModalRegisterPet } from "../../ModalRegisterPet";
import { useNavigate } from "react-router-dom";

function Pet() {
  const [pets, setPets] = useState([]);
  const [creatingPet, setCreatingPet] = useState(false);
  const { getPets } = PetsAPI();
  const navigate = useNavigate();

  const loadPets = async () => {
    setPets(await getPets());
  };
  useEffect(() => {
    loadPets();
  }, []);

  return (
    <>
      <S.WrapperTitle>
        <S.Title>Meus Pets</S.Title>
      </S.WrapperTitle>

      <S.WrapperSection>
        <Row>
          {pets &&
            pets.map((e) => {
              return (
                <S.PetWrapper
                  onClick={() => {
                    navigate("/gestor-pet/" + e._id);
                  }}
                  key={e._id}
                >
                  <S.PetAvatar src={e.avatar}></S.PetAvatar>
                  <S.PetTitleWrapper>
                    <S.PetTitle>{e.name}</S.PetTitle>
                  </S.PetTitleWrapper>
                </S.PetWrapper>
              );
            })}
        </Row>
        <Row justify={"end"}>
          <Button
            onClick={() => {
              setCreatingPet(true);
            }}
          >
            Adicionar PET
          </Button>
        </Row>

        {creatingPet && (
          <ModalRegisterPet
            open={creatingPet}
            cancel={() => {
              setCreatingPet(false);
              loadPets();
            }}
          />
        )}
      </S.WrapperSection>
    </>
  );
}

export default Pet;
