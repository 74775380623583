import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch } from "react-redux";

const CollarTypeAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteCollarType = (id) => {
    try {
      const response = http.delete("collar-types/" + id);

      navigate("/admin/collar-types");
    } catch (error) {}
  };

  const getCollarSizes = async () => {
    try {
      const response = await http.get("collar-size");
      return response.data;
    } catch (error) {}
  };

  const createCollarType = (e) => {
    try {
      const response = http.post("collar-types", e);

      navigate("/admin/collar-types");
    } catch (error) {}
  };

  const updateCollarType = (id) => (e) => {
    try {
      const response = http.patch("collar-types/" + id, e);

      navigate("/admin/collar-types");
    } catch (error) {}
  };

  const getCollarTypes = async () => {
    try {
      const response = await http.get("/collar-types");
      return response.data;
    } catch (error) {}
  };

  const findCollarType = async (id) => {
    try {
      const response = await http.get("/collar-types/" + id);

      return response.data;
    } catch (error) {}
  };

  return {
    deleteCollarType,
    createCollarType,
    findCollarType,
    updateCollarType,
    getCollarTypes,
    getCollarSizes,
  };
};

export default CollarTypeAPI;
