import Swal from "sweetalert2";
import { useAPITag } from "../../services/api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authCustomer } from "../../app/store/authSlice";

export const AuthAPI = (closeModal) => {
  const { http } = useAPITag();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authUser = async (data) => {
    try {
      const response = await http.post("usuario/auth", data);

      dispatch(
        authCustomer({
          token: response.data,
          user: response.data,
        }),
      );

      closeModal();
      navigate("/config-user");
    } catch (error) {
      Swal.fire({ ìcon: "error", title: "Usuário ou senha incorretos!" });
    }
  };

  const tryRecover = async (data) => {
    try {
      const response = await http.post("user", data);

      dispatch(authCustomer(response.data));

      closeModal();
      navigate("/config-user");
    } catch (error) {
      if (error.response.status === 404) {
        Swal.fire({ title: "Usuário não encontrado!", icon: "error" });
        return;
      }
      closeModal();
      navigate("/confirm-recover");
    }
  };

  const tryResetCode = async (data) => {
    try {
      const response = await http.post("password-recovery/request-otp", data);
      console.log(response.data);

      await Swal.fire({
        title: "Sucesso!",
        text: "Foi enviado um e-mail para você com o código.",
        icon: "success",
        confirmButtonText: "Ok",
      });

      closeModal();
      navigate("/request-otp");
    } catch (error) {
      let errorMessage =
        "Ocorreu um erro inesperado. Tente novamente mais tarde.";

      if (error.response && error.response.status === 404) {
        errorMessage = "E-mail não encontrado.";
      } else if (error.response && error.response.status === 429) {
        errorMessage = "Muitas tentativas. Tente novamente mais tarde.";
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      await Swal.fire({
        title: "Erro!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Ok",
      });

      closeModal();
    }
  };

  const verifyPhone = () => {};

  return { authUser, verifyPhone, tryRecover, tryResetCode };
};
