import * as S from "./styles";

const RegisterHeader = (props) => {
  return (
    <S.HeaderWrapper>
      <S.Title>{props.title}</S.Title>
      <S.Description>{props.description}</S.Description>
    </S.HeaderWrapper>
  );
};

RegisterHeader.defaultProps = {
  title: "",
  description: "",
};

export default RegisterHeader;
