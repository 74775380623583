import { forwardRef } from 'react'

import * as S from './styles'

const Button = (
  {
    children,
    icon,
    fullWidth = false,
    minimal = false,
    isLoading = false,
    color = 'primary',
    ...props
  },
  ref
) => (
  <S.Wrapper
    fullWidth={fullWidth}
    minimal={minimal}
    isLoading={isLoading}
    color={color}
    ref={ref}
    {...props}
  >
    <S.WrapperText>
      {!!children && <span>{children}</span>}
    </S.WrapperText>
  </S.Wrapper>
)

export default forwardRef(Button)