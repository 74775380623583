import styled, { css } from 'styled-components'

import { Button as ButtonAntd } from 'antd'
const wrapperModifiers = {
  fullWidth: () => css`
    width: 100%;
  `,

  minimal: () => css`
    background: none;
    background-color: #F67E48; // primary color: ;
    text-transform: none;
    letter-spacing: normal;
    font-weight: 600;
    font-size: 14px;
    max-height: 40px;
    max-width: 108px;
    line-height: 12px;
    background: none;
    
    min-height: fit-content;
    &:hover {
      color: #F67E48; //primary color
    }
  `,

  secundary: () => css`
    background-position: 100% 0%;
    background-size: 200% 1%;
    border: 2px solid #F67E48; // primary color
    background: #ffffff20; // secondary color
    color: #F67E48; // primary color
  `,

  disabled: () => css`
    &:disabled {
      cursor: not-allowed;
      filter: saturate(30%);
    }
  `,

  primary: () => css`
    background-position: 100% 0%;
    background-size: 200% 1%;
    background: #F67E48 !important; // primary color
    color: #fff; // secondary color
  `,

  gray: () => css`
    background: #bdbdbd;
  `,

  ghost: () => css`
    background-position: 100% 0%;
    background-size: 200% 1%;
    border: 2px solid #F67E48; // primary color
    background: #EFEFEF80; // secondary color
    border: 2px solid #EEEEEE80;
    color: #1A1D1F80; // primary color
  `
}

export const Button = styled(ButtonAntd)`
  ${({ theme, fullWidth, minimal, disabled, color }) => css`
    align-items: center;
    border-radius: 99px;
    border: 0;
    color: #F67E48;
    cursor: pointer;
    display: inline-flex;
    background: #F67E48;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 2px;
    line-height: 16px;
    min-height: 48px;
    padding: 12px 36px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: background 0.3s ease-in-out;
    position: relative;
    outline: none;
    &:hover {
      background-position: 0% 0%;
    }

    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${color && wrapperModifiers[color]}
    ${console.log(color)}
    ${!!minimal && wrapperModifiers.minimal(theme)};
    ${disabled && wrapperModifiers.disabled()};
  `}
`

export const WrapperText = styled.div`
  ${({ isLoading }) => css`
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    ${isLoading &&
    css`
      transform: translateY(12px) scale(0.75);
      opacity: 0;
    `}
  `}
`

export const Wrapper = styled.div`
  ${({maxSize}) => css`
    display: flex;
    justify-content: flex-start;
    max-width: ${maxSize};
  `}
`