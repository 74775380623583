export function getPetAge(startDate, endDate) {
  const diffInMs = Math.abs(endDate - startDate);
  const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365);

  if (diffInYears >= 1) {
    return Math.floor(diffInYears) + " anos";
  }

  const diffInMonths = diffInMs / (1000 * 60 * 60 * 24 * 30);

  if (diffInMonths >= 1) {
    return Math.floor(diffInMonths) + " meses";
  }

  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  return Math.floor(diffInDays) + " dias";
}
