import React from "react"

const PersonIcon = ({
  title = 'PersonIcon',
  titleId = 'person-icon',
  color = '#777E90',
  height = 16,
  width = 16,
  ...props
}) => (
  <svg
    height={height}
    width={width}
    viewBox={`0 0 ${height} ${width}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8.66659C5.42267 8.66659 3.33333 10.7559 3.33333 13.3333V14.6666C3.33333 15.0348 3.03486 15.3333 2.66667 15.3333C2.29848 15.3333 2 15.0348 2 14.6666V13.3333C2 10.0195 4.68629 7.33325 8 7.33325C11.3137 7.33325 14 10.0195 14 13.3333V14.6666C14 15.0348 13.7015 15.3333 13.3333 15.3333C12.9651 15.3333 12.6667 15.0348 12.6667 14.6666V13.3333C12.6667 10.7559 10.5773 8.66659 8 8.66659Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 7.33342C9.47276 7.33342 10.6667 6.13951 10.6667 4.66675C10.6667 3.19399 9.47276 2.00008 8 2.00008C6.52724 2.00008 5.33333 3.19399 5.33333 4.66675C5.33333 6.13951 6.52724 7.33342 8 7.33342ZM8 8.66675C10.2091 8.66675 12 6.87589 12 4.66675C12 2.45761 10.2091 0.666748 8 0.666748C5.79086 0.666748 4 2.45761 4 4.66675C4 6.87589 5.79086 8.66675 8 8.66675Z" fill={color}/>
  </svg>
)

export default PersonIcon