import React from "react"

const HelpIcon = ({
  title = 'HelpIcon',
  titleId = 'help-icon',
  color = '#B3B5BB',
  ...props
}) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.5341 12.181L17.2962 12.6714L16.5341 12.181ZM11.1774 10.8751C11.1774 11.3756 11.5831 11.7813 12.0837 11.7813C12.5842 11.7813 12.9899 11.3756 12.9899 10.8751H11.1774ZM13.5941 16.3126C13.5941 16.8131 13.9998 17.2188 14.5003 17.2188C15.0008 17.2188 15.4066 16.8131 15.4066 16.3126H13.5941ZM15.4066 19.3334C15.4066 18.8329 15.0008 18.4272 14.5003 18.4272C13.9998 18.4272 13.5941 18.8329 13.5941 19.3334H15.4066ZM13.5941 20.5417C13.5941 21.0423 13.9998 21.448 14.5003 21.448C15.0008 21.448 15.4066 21.0423 15.4066 20.5417H13.5941ZM25.6774 14.5001C25.6774 20.673 20.6733 25.6772 14.5003 25.6772V27.4897C21.6743 27.4897 27.4899 21.674 27.4899 14.5001H25.6774ZM14.5003 25.6772C8.32739 25.6772 3.32324 20.673 3.32324 14.5001H1.51074C1.51074 21.674 7.32638 27.4897 14.5003 27.4897V25.6772ZM3.32324 14.5001C3.32324 8.32715 8.32739 3.323 14.5003 3.323V1.5105C7.32638 1.5105 1.51074 7.32613 1.51074 14.5001H3.32324ZM14.5003 3.323C20.6733 3.323 25.6774 8.32715 25.6774 14.5001H27.4899C27.4899 7.32613 21.6743 1.5105 14.5003 1.5105V3.323ZM16.0107 10.8751C16.0107 11.1771 15.923 11.4559 15.772 11.6906L17.2962 12.6714C17.6298 12.153 17.8232 11.5352 17.8232 10.8751H16.0107ZM12.9899 10.8751C12.9899 10.0409 13.6661 9.36466 14.5003 9.36466V7.55216C12.6651 7.55216 11.1774 9.03988 11.1774 10.8751H12.9899ZM14.5003 9.36466C15.3345 9.36466 16.0107 10.0409 16.0107 10.8751H17.8232C17.8232 9.03988 16.3355 7.55216 14.5003 7.55216V9.36466ZM13.5941 15.7084V16.3126H15.4066V15.7084H13.5941ZM15.772 11.6906C15.6198 11.9271 15.4293 12.1718 15.2056 12.4499C14.9913 12.7162 14.7394 13.0215 14.5136 13.3319C14.0682 13.944 13.5941 14.7426 13.5941 15.7084H15.4066C15.4066 15.3395 15.5882 14.9357 15.9792 14.3983C16.1715 14.134 16.3829 13.8779 16.6177 13.5861C16.8429 13.3062 17.0872 12.9962 17.2962 12.6714L15.772 11.6906ZM13.5941 19.3334V20.5417H15.4066V19.3334H13.5941Z"
      fill={color}
    />
  </svg>
)

export default HelpIcon