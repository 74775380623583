import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth, selectAuthState } from "../../features/admin/login/loginSlice";
import { useAPI } from "../../services/api";

const useChangePassword = () => {
  const http = useAPI();
  const authState = useSelector(selectAuthState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const run = async (password) => {
    try {
      const response = await http.post("admin/auth/changePassword", {
        email: authState.user.email,
        password: password.password,
        session: authState.user.session,
      });

      dispatch(
        auth({
          token: response.data.changePassword.access_token,
          user: authState.user,
        })
      );

      navigate("/admin/collars");
    } catch (err) {
      throw err;
    }
  };
  return run;
};

export default useChangePassword;
