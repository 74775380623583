import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAPIPDF } from "../../services/api";

const useRequestCollarsMultiple = () => {
  const { http } = useAPIPDF();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const run = async (collarRequest) => {
    try {
      const response = await http.post("pdf/single", collarRequest);

      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return run;
};

export default useRequestCollarsMultiple;
