import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #DEDFE4B2;
`

export const WrapperLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
`

export const WrapperDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  font-family: 'Poppins';
  color: #000000;
  font-size: 12px;
  line-height: 18px;
  font-weight:600;
`

export const Describe = styled.span`
  font-family: 'Poppins';
  color: #747A80;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`
