import { useAPITag } from "../../services/api";

export const GestorPetAPI = () => {
  const { http } = useAPITag();

  const getPetData = async (id) => {
    try {
      const response = await http.get("/pets/id/" + id);
      return response.data;
    } catch (error) {}
  };

  const setNewHistoric = async (id, historic) => {
    try {
      const response = await http.patch("/pets/" + id, { historic });
      return response.data;
    } catch (error) {}
  };

  const updatePet = async (id, data) => {
    try {
      const response = await http.patch("/pets/" + id, data);
      return response.data;
    } catch (error) {}
  };

  const deactivateTag = async (id, code) => {
    try {
      const response = await http.patch(`pets/deactivate-tag/${id}/${code}`);
      const response2 = await http.patch(`tags/change-status/${code}`, {
        statusQr: "",
        userPhone: "",
      });
      return response.data;
    } catch (error) {
      console.log(error)
    }
  };

  return { getPetData, setNewHistoric, updatePet, deactivateTag };
}
