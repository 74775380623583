import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  border-radius: 12px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const Aside = styled.aside`
  margin-left: auto;
  left: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e6e8ec;
  align-items: center;
  padding: 28px;

  ${media.lessThan("medium")`
    margin-left: 0px;
    width: 100%;
    border-right: none;
    background: #fff;
  `}
`;

export const Header = styled.h1`
  top: 0;
  font-size: 24px;
  line-height: 56px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;

  ${media.lessThan("medium")`
    display: none;
  `}
`;

const menuModifiers = {
  isActive: () => css`
    background: #ffdfd0;

    span {
      color: #f67e48;
    }

    path {
      fill: #f67e48;
    }
  `,
};

export const WraperMenu = styled.div`
  ${({ active }) => css`
    width: auto;
    min-width: 227px;
    max-height: 32px;
    height: 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    padding: 8px 20px;
    align-items: center;
    gap: 20px;
    background: #fff;
    cursor: pointer;
    ${active && menuModifiers.isActive()}
  `}
`;

export const TextMenu = styled.span`
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #777e90;
  cursor: pointer;
`;

export const WrapperForm = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 12px;

  * {
    font-family: "Inter";
  }

  ${media.lessThan("medium")`
    flex-direction: column;
    align-items: center;
    padding: 20px;
  `}
`;

export const WrapperSelect = styled.div`
  display: none;
  ${media.lessThan("medium")`
    display: flex;
    justify-content: center;
    width: 100%;
  `}

  .ant-select-selector {
    border-radius: 35px !important;
  }
`;