import { createSlice } from "@reduxjs/toolkit";

export const simulatorSlice = createSlice({
  name: "simulator",
  initialState: {
    name: "",
    phone: "",
    pet: {},
  },
  reducers: {
    setData: (state, action) => {
      state.name = action.payload.name;
      state.phone = action.payload.phone;
    },

    setPet: (state, action) => {
      state.pet = action.payload;
    },
  },
});

export const selectSimState = (state) => state.simulator;
export const selectSimPet = (state) => state.simulator.pet;

export const { setData, setPet } = simulatorSlice.actions;

export default simulatorSlice.reducer;
