import { createSlice } from "@reduxjs/toolkit";
import { redirect } from "react-router-dom";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    pdfUrl: "",
    user: (() => {
      const userItem = localStorage.getItem("@customer");
      if (userItem && userItem !== "undefined") {
        return JSON.parse(userItem);
      }
      return null;
    })(),
    token: localStorage.getItem("token"),
  },
  reducers: {
    auth: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      localStorage.setItem("@customer", JSON.stringify(action.payload.user));
      localStorage.setItem("@customerToken", action.payload.token);
    },
    logout: (state, action) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
    setPDF: (state, action) => {
      state.pdfUrl = action.payload;
    },
  },
});

export const selectAuthState = (state) => state.auth;
export const getAdminId = (state) => state.auth.user._id;
export const getPDFUrl = (state) => state.auth.pdfUrl;
export const isSuperAdmin = (state) => state.auth.user.superadmin;

export const { auth, logout, setPDF } = authSlice.actions;

export default authSlice.reducer;
