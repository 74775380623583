import * as S from "./styles";
import { useEffect, useState } from "react";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { useForm } from "react-hook-form";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { ConfigUserAPI } from "../../../components/FormConfigUser/ConfigUserAPI";

export const ResetPassword = () => {
  const [inputDisabled, setInputDisabled] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { register, handleSubmit, watch } = useForm({});
  const { resetPassword } = ConfigUserAPI();
  const formData = watch();

  const handleCheck = (data) => {
    resetPassword(data);
  };

  useEffect(() => {
    const { email, otp, confirmPassword, newPassword } = formData;
    if (email && otp && confirmPassword && newPassword) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  }, [formData]);

  return (
    <>
      <RegisterPetLayout
        hasHeader
        description={<span>Informações de conta e segurança</span>}
        title="Verificação de conta"
        size={5}
        step={5}
      >
        <S.InputWrapper>
          <form onSubmit={handleSubmit(handleCheck)}>
            <S.ContainerInput>
              <S.Label>Email</S.Label>
              <S.InputContainer>
                <S.InputStyled
                  type={"text"}
                  name="email"
                  placeholder="Digite seu e-mail"
                  {...register("email")}
                />
              </S.InputContainer>
            </S.ContainerInput>

            <S.ContainerInput>
              <S.Label>Código</S.Label>
              <S.InputContainer>
                <S.InputStyled
                  type={"text"}
                  name="otp"
                  placeholder="Digite o código"
                  {...register("otp")}
                />
              </S.InputContainer>
            </S.ContainerInput>

            <S.ContainerInput>
              <S.Label>Nova senha</S.Label>
              <S.InputContainer>
                <S.InputStyled
                  type={showNewPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Senha nova"
                  {...register("confirmPassword")}
                />
                <S.IconButton
                  type="button"
                  onClick={() => setShowNewPassword((prev) => !prev)}
                >
                  {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </S.IconButton>
              </S.InputContainer>
            </S.ContainerInput>

            <S.ContainerInput>
              <S.Label>Confirmar nova senha</S.Label>
              <S.InputContainer>
                <S.InputStyled
                  type={showConfirmPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="Confirme a nova senha"
                  {...register("newPassword")}
                />
                <S.IconButton
                  type="button"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  {showConfirmPassword ? (
                    <EyeOutlined />
                  ) : (
                    <EyeInvisibleOutlined />
                  )}
                </S.IconButton>
              </S.InputContainer>
            </S.ContainerInput>

            <S.SubmitButton
              htmlType="submit"
              disabled={inputDisabled}
            >
              Confirmar
            </S.SubmitButton>
          </form>
        </S.InputWrapper>
      </RegisterPetLayout>
    </>
  );
};
