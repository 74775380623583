import styled, { css } from "styled-components";

export const Title = styled.div`
  cursor: pointer;
  color: #282828;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  cursor: auto;
  display: flex;
  background: #fff;
  border-radius: 10px;
  color: #282828;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 52;
  box-shadow: 5px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px 3px rgba(16, 24, 40, 0.1);
`;

export const Overlay = styled.div`
  background: transparent;
  box-shadow: 0px 14px 34px rgba(46, 108, 177, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
`;

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    min-width: 230px;
    pointer-events: auto;
    transform: translateY(0);
    visibility: visible;
    z-index: 10;
  `,

  close: (animated = true) => css`
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    ${animated &&
    css`
      transform: translateY(-2rem);
    `}
  `,
};

export const Wrapper = styled.div`
  ${({ isOpen }) => css`
    position: relative;
    width: 100%;

    ${Content} {
      transition: transform 0.2s ease-in, opacity 0.3s ease-in-out;

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
      z-index: 15;
    }

    ${Overlay} {
      transition: transform 0.2s ease-in, opacity 0.3s ease-in-out;

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close(false)}
    }
  `}
`;
