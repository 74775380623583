import Title from "../../../../components/Title";
import SubTitle from "../../../../components/Subtitle";
import { ContainerInput, Input, Label } from "../styles";
import * as S from "./styles";
import { useForm } from "react-hook-form";
import { getUserByEmail } from "../../../services/donee-client";

export default function CustomLinkCreate() {
  const { register, handleSubmit, setValue, watch } = useForm();
  console.log(watch('cpf'));

  const onSubmit = async (data) => {
    console.log(data.email);

    const userByEmail = await getUserByEmail(data.email);
    console.log("getUserByEmail", userByEmail);
    const bodyDonee = {
      type: data.type,
      entityId: userByEmail._id
    }
  }

  return (
    <>
      <S.SectionTitle>
        <Title>Criar cadastro donatário</Title>
      </S.SectionTitle>
      <S.SectionDetails>
        <SubTitle>Digite os dados para cadastro</SubTitle>
        <S.Form>
        <S.WrapperInputs>
            <ContainerInput>
              <Label>Tipo de donatário</Label>
              <S.WrapperSelect name="type" id="type"  onChange={(e) => {
                  setValue("type", e.target.value);
                }}
                {...register('type')} >
                <option value="user" name="user">Usuário</option>
                <option value="institution" name="institution">Instituição</option>
              </S.WrapperSelect>
            </ContainerInput>
          </S.WrapperInputs>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>Email</Label>
              <Input
                name="email"
                placeholder="Insira seu Email"
                control
                {...register('email')}
              />
            </ContainerInput>
          </S.WrapperInputs>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>Username</Label>
              <Input
                name="customlink"
                placeholder="Insira seu username"
                control
                {...register('customlink')}
              />
            </ContainerInput>
          </S.WrapperInputs>

          <S.WrapperButton>
            <S.SubmitButton fullWidth onClick={handleSubmit(onSubmit)}>
              Cadastrar
            </S.SubmitButton>
            <S.Description>
              AjudaPet Soluções para a Causa Animal© Copyright - Todos os
              direitos reservados.2021 CNPJ: 31.038.058/0001-58
            </S.Description>
          </S.WrapperButton>
        </S.Form>
      </S.SectionDetails>
    </>
  )
}
