import Styled, { css } from "styled-components";
import ButtonDonate from "../../../../components/ButtonDonate";

const typePaymentModifiers = {
  selected: (color) => css`
    border: 2px solid #f67e48;
    background-color: #fef5f0;

    p {
      color: #192126;
    }
  `,
};

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SectionTitle = Styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
  @media (max-width: 480px) {
margin-top: 40px;

}
`;

export const SubTitleSection = Styled.p`
  color: #777E90;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const SectionInfo = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperInput = Styled.div`
  display: flex;
  flex-direction: row;
  max-width: 142px;
`;

export const WrapperButton = Styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: center;
`;

export const SubmitButton = Styled(ButtonDonate)`
  margin-bottom: 20px;
`;

export const WrapperValueDonation = Styled.div`
width: 400px;
height: 88px;
display: flex;
flex-direction: row;
-webkit-box-pack: justify;
justify-content: space-evenly;
background: rgb(244, 245, 246);
padding: 21px 4px;
border-radius: 12px;
`;

export const WrapperDataInfos = Styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const DonationTag = Styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #23262F;
`;

export const LinkChange = Styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: #F67E48;
  cursor: pointer;
`;

export const SectionDetails = Styled.div`
display: flex;
flex-direction: column;
-webkit-box-pack: start;
justify-content: center;
align-items: center;
gap: 8px;
width: 100%;
`;

export const Form = Styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 17px;
`;

export const WrapperInputs = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const CardTypePayment = Styled.div`
  ${({ theme, styled }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 37px 24px;
    border-radius: 12px;
    width: 380px;
    height: 72px;
    color: #d7d7d7;
    border: 2px solid #d7d7d7;

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #d7d7d7;
    }

    ${styled && typePaymentModifiers[styled]}
  `}
`;

export const Description = Styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #b1b5c3;

  @media (max-width: 480px) {
    display: none;
  }
`;
