import { Button, Form, Input, InputNumber, Select } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import CollarTypeAPI from "./CollarTypeAPI";

const CreateCollarType = (props) => {
  const { createCollarType, findCollarType, updateCollarType, getCollarSizes } =
    CollarTypeAPI();
  const [collar, setCollar] = useState({ title: "fdfsf" });
  const [options, setOptions] = useState([]);
  const [form] = useForm();
  const { id } = useParams();

  useEffect(() => {
    const loadOptions = async () => {
      const data = await getCollarSizes();
      setOptions(data);
    };

    if (props.edit) {
      const getCollar = async () => {
        const data = await findCollarType(id);
        console.log(data);
        data.qrSize = data.qrSize._id;
        form.setFieldsValue(data);
      };

      getCollar();
    }

    loadOptions();
  }, []);

  const getSizeOptions = () => {
    return options.map((e) => ({ label: e.title, value: e._id }));
  };

  const handleFinish = (values) => {
    const qrSize = options.find((option) => option._id == values.qrSize);
    values.qrSize = qrSize;
    if (props.edit) {
      updateCollarType(id)(values);
      return;
    }

    createCollarType(values);
  };

  return (
    <Dashboard selected="2">
      <S.Wrapper>
        <Form form={form} labelCol={{ span: 24 }} onFinish={handleFinish}>
          <Form.Item
            rules={[{ required: true, message: "Insira um título" }]}
            label="Titulo"
            name="title"
          >
            <Input value={collar.title} />
          </Form.Item>

          <Form.Item
            rules={[{ required: true }]}
            label={"Espécie"}
            name={`kind`}
          >
            <Select
              options={[
                {
                  value: "dog",
                  label: "Cachorro",
                },
                {
                  value: "cat",
                  label: "Gato",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label={"Tamanho"} name={`size`}>
            <Select
              options={[
                {
                  label: "PP",
                  value: "PP",
                },
                {
                  label: "P",
                  value: "P",
                },

                {
                  label: "M",
                  value: "M",
                },

                {
                  label: "G",
                  value: "G",
                },
                {
                  label: "GG",
                  value: "GG",
                },
                {
                  label: "Único",
                  value: "Único",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Insira a altura da coleira" }]}
            name="height"
            label="Altura da Coleira"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Insira a largura da Coleira" }]}
            label="Largura da Coleira"
            name="width"
          >
            <InputNumber style={{ width: "100%" }} min={0.1} />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Insira o espaçamento da Coluna de frente e Verso",
              },
            ]}
            label="Espaçamento da Coluna Frente/Verso"
            name="columnGap"
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: "Insira o alinhamento vertical" },
            ]}
            name="qrBoxVerticalAlign"
            label="Distancia Vertical da Caixa do QR "
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: "Insira o alinhamento vertical" },
            ]}
            name="codeBoxVerticalAlign"
            label="Distancia Vertical da Caixa do Código "
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label={"Tamanho do QR"} name={`qrSize`}>
            <Select options={getSizeOptions()} />
          </Form.Item>

          <Button htmlType="submit">
            {props.edit ? "Editar" : "Criar"} Tipo de Coleira
          </Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};

export default CreateCollarType;
