import React from "react"

const ShareIcon = ({
  title = 'ShareIcon',
  titleId = 'share-icon',
  color = '#B3B5BB',
  ...props
}) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7757 23.8914C16.7757 24.771 16.9306 25.6144 17.2145 26.3959C16.3935 26.5733 15.5411 26.6668 14.667 26.6668C8.03958 26.6668 2.66699 21.2942 2.66699 14.6668C2.66699 8.03939 8.03958 2.66681 14.667 2.66681C21.2944 2.66681 26.667 8.03939 26.667 14.6668C26.667 15.4441 26.5931 16.2042 26.4519 16.9403C25.7162 16.6924 24.9283 16.5581 24.1091 16.5581C20.059 16.5581 16.7757 19.8413 16.7757 23.8914ZM14.667 18.3335C12.7328 18.3335 10.918 18.8318 9.34093 19.7064C8.85794 19.9742 8.24927 19.7998 7.98144 19.3168C7.7136 18.8338 7.88803 18.2251 8.37102 17.9573C10.2373 16.9224 12.3849 16.3335 14.667 16.3335C15.1545 16.3335 15.636 16.3603 16.1102 16.4128C16.6592 16.4734 17.055 16.9676 16.9943 17.5166C16.9336 18.0655 16.4394 18.4613 15.8905 18.4007C15.4891 18.3563 15.0809 18.3335 14.667 18.3335ZM14.667 6.66681C16.8761 6.66681 18.667 8.45767 18.667 10.6668C18.667 12.8759 16.8761 14.6668 14.667 14.6668C12.4579 14.6668 10.667 12.8759 10.667 10.6668C10.667 8.45767 12.4579 6.66681 14.667 6.66681ZM29.3339 24.0002C29.3339 21.709 27.8891 19.7553 25.8609 19.0003C25.2817 18.7847 24.6549 18.6669 24.0005 18.6669C21.055 18.6669 18.6672 21.0547 18.6672 24.0002C18.6672 24.6545 18.785 25.2813 19.0006 25.8606C19.7556 27.8888 21.7093 29.3335 24.0005 29.3335C26.946 29.3335 29.3339 26.9457 29.3339 24.0002ZM25.0005 21.3335C25.0005 20.7812 24.5528 20.3335 24.0005 20.3335C23.4482 20.3335 23.0005 20.7812 23.0005 21.3335V23.0002H21.3339C20.7816 23.0002 20.3339 23.4479 20.3339 24.0002C20.3339 24.5525 20.7816 25.0002 21.3339 25.0002H23.0005V26.6669C23.0005 27.2191 23.4482 27.6669 24.0005 27.6669C24.5528 27.6669 25.0005 27.2191 25.0005 26.6669V25.0002H26.6672C27.2195 25.0002 27.6672 24.5525 27.6672 24.0002C27.6672 23.4479 27.2195 23.0002 26.6672 23.0002H25.0005V21.3335Z"
      fill={color}
    />
  </svg>
)

export default ShareIcon