import { useNavigate } from "react-router-dom";
import { useAPIPDF, useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../app/store/authSlice";
import { getAdminId, selectAuthState } from "../login/loginSlice";
import Swal from "sweetalert2";

const CreatePlateAPI = () => {
  const { http } = useAPIPDF();
  const api = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const adminId = useSelector(getAdminId);
  const generatePlate = async (values) => {
    try {
      const {
        pageMarginLeft,
        pageMarginRight,
        pageMarginBottom,
        pageMarginTop,
        pageWidth,
        pageHeight,
        title,
        repetitions,
        ...custom
      } = values;

      custom.codeFontCMYKColor = JSON.parse(custom.codeFontCMYKColor);

      const requestBody = {
        pageMarginLeft,
        pageMarginRight,
        pageMarginBottom,
        pageMarginTop,
        pageWidth,
        pageHeight,
        companyId: authState.user._id,
        emailsToSend: ["foochaos@gmail.com", authState.user.email],
        tagDomain: "http://5e.ae",
        tagCodeLength: 4,
        pdfFileKey: authState.user._id + "/" + title + ".pdf",
        customs: [custom],
      };
      console.log(values);

      for (let i = 0; i < repetitions; i++) {
        await http.post("pdf/custom", requestBody);
      }

      Swal.fire({
        icon: "success",
        title: "PDF Enviado para a fila com sucesso",
      });
    } catch (error) {}
  };

  const saveConfig = async (values) => {
    try {
      values.companyId = adminId;
      await api.http.post("plates", values);

      Swal.fire({
        icon: "success",
        title: "Configuração salva com sucesso!",
      });
    } catch (error) {}
  };
  const loadPlatesConfigs = async () => {
    try {
      const response = await api.http.get("/plates/company/" + adminId);
      return response.data;
    } catch (e) {}
  };
  return {
    generatePlate,
    loadPlatesConfigs,
    saveConfig,
  };
};

export default CreatePlateAPI;
