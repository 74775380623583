import styled from "styled-components";
import InputMask from "react-input-mask";
import { MaskedInput } from "antd-mask-input";
import { Form, Input } from "antd";

export const Wrapper = styled(Form.Item)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-family: "Readex Pro";
  font-style: normal;
  text-transform: uppercase;
  color: #353945;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  &:focus {
    border: 2px solid #f67e48;
  }
`;

export const InputFieldMasked = styled(MaskedInput)`
  width: 100%;
  max-height: 48px;
  padding: 12px;
  font-size: 14px;
  color: #777e91;
  border: 2px solid #e6e8ec;
  border-radius: 8px;

  ${({ error }) =>
    error &&
    `
    border-color: #ff0000;
  `}
`;

export const InputField = styled(Input)`
  width: 100%;
  max-height: 48px;
  padding: 12px;
  font-size: 14px;
  color: #777e91;
  border: 2px solid #e6e8ec;
  border-radius: 8px;

  ${({ error }) =>
    error &&
    `
    border-color: #ff0000;
  `}
`;

export const InputIcon = styled.i`
  position: absolute;
  top: 12px;
  ${({ iconPosition }) => (iconPosition === "right" ? "right" : "left")}: 10px;
  font-size: 18px;
  color: #777e91;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #ff0000;
  margin-top: 4px;
`;
