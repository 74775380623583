import * as S from './styles'

const Box = ({children, ...props}) => {
  return (
    <S.Wrapper>
        {children}
    </S.Wrapper>
  )
}

export default Box;
