import axios from "axios"
import { _baseUrl } from "../utils/constants"

export const createDonnor = (body) => {
    return axios.post(`${_baseUrl}/donnor`, body)
        .then(res => {
            console.log('res.data: ', res.data)
            if (res && res.data) {
                return res.data
            }
        })
        .catch(err => {
            return {
                errors: [
                    err.response.data.errors
                ]
            }
        })
}

export const getDonnorById = (id) => {
    return axios.get(`${_baseUrl}/donnor/${id}`)
        .then(res => {
            return res
        })
        .catch(err => {
            return {
                err
            }
        })
}

export const getAllDonnor = () => {
    return axios.get(`${_baseUrl}/donnor/`)
        .then(res => {
            return res
        })
        .catch(err => {
            return {
                err
            }
        })
}