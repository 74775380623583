import Container from "../../../components/Container";
import BannerDonate from "../../../components/BannerDonate";
import BoxDonate from "../../../components/BoxDonate";
import ValueDonation from "./ValueDonation";
import SelectPayment from "./SelectPayment";
import BillinDetails from "./BillinDetails";
import PixPayment from "./PixPayment";
import CreditCard from "./CreditCard";
import ThankYou from "./ThankYou";
import DonationProvider, {
  STEPS,
  useDonation,
} from "../../../context/DonationContext";
import * as S from "./styles";
import { ThemeProvider } from "styled-components";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { Grid } from "antd";
import { Outlet } from "react-router-dom";
import HeaderDonate from "../../../components/HeaderDonate";
const { useBreakpoint } = Grid;

const theme = {
  colors: {
    primary: "#F67E48",
    secondary: "#FCFCFD",
    terciary: "#B1B5C3",
    background: "#f5f5f5",
    status: {
      destructive: "#E13600",
      warning: "#FFB800",
      success: "#049601",
    },
    //  {
    // primary: "#242424",
    // secondary: "#343434",
    // },
  },
};
const Doar = () => {
  const screens = useBreakpoint();

  const {
    step,
    donationValue,
    changeDonationValue,
    changeStep,
    changeTypePayment,
    typePayment,
    changeBillinDetails,
    billinDetails,
  } = useDonation();
  const { isMobile } = useMobileDetect();

  const onDonation = (value) => {
    return changeDonationValue(value);
  };

  const onStep = (value) => {
    return changeStep(value);
  };

  const onBillingDetails = (values) => {
    return changeBillinDetails(values);
  };

  console.log("isMobile => ", isMobile);

  return (
    <Container>
      <HeaderDonate />
      {screens.sm && <BannerDonate></BannerDonate>}
      <S.Wrapper>
        <BoxDonate>
          {step === STEPS.DONATION_VALUE && (
            <ValueDonation
              onDonation={onDonation}
              onStep={onStep}
              step={step}
            />
          )}
          {step === STEPS.TYPE_PAYMENT && (
            <SelectPayment
              donationValue={donationValue}
              onStep={onStep}
              onDonation={onDonation}
              changePayment={changeTypePayment}
              typePayment={typePayment}
            />
          )}
          {step === STEPS.BILLIN_DETAILS && (
            <BillinDetails
              onDonation={onDonation}
              donationValue={donationValue}
              onStep={onStep}
              step={step}
              typePayment={typePayment}
              billinDetails={billinDetails}
              onBillinDetails={onBillingDetails}
            />
          )}
          {step === STEPS.PIX_PAYMENT && (
            <PixPayment
              billinDetails={billinDetails}
              donationValue={donationValue}
            />
          )}
          {step === STEPS.CREDIT_CARD && (
            <CreditCard
              onDonation={onDonation}
              donationValue={donationValue}
              onStep={onStep}
              step={step}
              typePayment={typePayment}
              billinDetails={billinDetails}
              onBillinDetails={onBillingDetails}
            />
          )}
          {step === STEPS.THANKS && <ThankYou />}
        </BoxDonate>
      </S.Wrapper>
    </Container>
  );
};

export default function () {
  const screens = useBreakpoint();

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <HeaderDonate />
        {screens.sm && <BannerDonate></BannerDonate>}
        <S.Wrapper>
          <BoxDonate>
            <Outlet />
          </BoxDonate>
        </S.Wrapper>
      </Container>
    </ThemeProvider>
  );
}
