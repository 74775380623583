import React from 'react'


const ArrowIcon = ({
  title,
  titleId,
  ...props
}) => (
  <svg
    width={8}
    height={5}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.693.454A.5.5 0 0 1 1.4.421l2.882 2.624L7.163.42a.5.5 0 1 1 .674.74L4.618 4.09a.5.5 0 0 1-.673 0L.727 1.16A.5.5 0 0 1 .693.453Z"
      fill="#000"
    />
  </svg>
)

export default ArrowIcon
