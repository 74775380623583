import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

const PetShopsAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const getPetshops = async () => {
    try {
      const response = await http.get("/petshops");
      return response.data;
    } catch (error) {}
  };

  const getPetshop = async (id) => {
    try {
      const response = await http.get("/petshops/" + id);
      const responseTags = await http.get("/pet-shop-tags/" + id);

      return { ...response.data, tags: responseTags.data };
    } catch (error) {}
  };

  const deletePetshop = (id) => {
    try {
      const response = http.delete("petshops/" + id);

      //navigate("/admin/companies");
    } catch (error) {}
  };

  return {
    getPetshops,
    getPetshop,
    deletePetshop,
  };
};

export default PetShopsAPI;
