import { Button, Input } from "antd";
import { MaskedInput } from "antd-mask-input";
import styled from "styled-components";

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e6e8ec;
  gap: 19px;
`;

export const Title = styled.h2`
  font-size: 20px;
  line-height: 56px;
  font-family: "Readex pro";
  font-style: normal;
  font-weight: 700;
  color: #23262f;
`;

export const Subtitle = styled.h3`
  font-family: "Readex pro";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #23262f;
  font-weight: 700;
  text-transform: capitalize;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-height: 80px;
  gap: 12px;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid #e6e8ec;
  border-radius: 8px;
`;

export const InputStyled = styled.input`
  font-family: "Readex pro";
  border: none;
  padding: 12px 16px;
  padding-right: 40px;
  border-radius: 8px;
  width: 100%;

  &::placeholder {
    color: #b1b5c4;
    font-size: 14px;
    line-height: 24px;
  }

  &:focus {
    outline: none;
  }
`;

export const IconButton = styled.button`
  position: absolute;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #353945;
`;

export const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  margin-top: 16px;
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 95%;
  background-color: white;
  padding: 0 2rem 0 2rem;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

export const StyledInput = styled(MaskedInput)`
  height: 50px;
  border-radius: 50px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  border-radius: 50px;
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
  background-color: #f67e48;
  height: 50px;
  width: 344px;
  margin-bottom: 15px;
  margin-top: 25px;

  @media screen and (max-width: 480px) {
    width: 95%;
    position: fixed;
    bottom: 15px;
    margin-bottom: 0;
    margin: auto;
  }
`;
