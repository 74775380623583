import React from "react"

const LogoutIcon = ({
  title = 'LogoutIcon',
  titleId = 'logout-icon',
  color = '#E33B2A',
  ...props
}) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 7V9M15 7.99963C16.2144 8.91184 17 10.3642 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 10.3642 7.78555 8.91184 9 7.99963M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />

  </svg>
)

export default LogoutIcon