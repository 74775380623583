import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../services/api";

const useGetCollars = () => {
  const { http } = useAPITag();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const run = async (userId) => {
    try {
      const response = await http.get("collar-preset/" + userId);

      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return run;
};

export default useGetCollars;
