import { Button } from "antd";
import styled from "styled-components";
import Styled, { css } from "styled-components";

export const Wrapper = Styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.secondary};
    border: 1px solid #e3e7eb;

    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    width: 100%;
    z-index: 900000;
  `}
`;

export const WrapperHeader = Styled.div`
  width: 1280px;
  display: flex;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
  height: 50px;
`;

export const WrapperLogo = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`;

export const WrapperBtns = Styled.div`
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const Nav = Styled.nav`
  display: flex;
  align-items: center;
  gap: 32px;
`;
