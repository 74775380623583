import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { useAPITag } from "../../services/api";
import ImageIcon from "../Icons/ImageIcon";

const getColor = (props) => {
  console.log(props.isDragAccept);
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  justify-content: center;
`;

const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

function StyledDropzone(props) {
  const [paths, setPaths] = useState(
    props.defaultFile ? [props.defaultFile] : []
  );

  const { http } = useAPITag();

  const onDrop = useCallback(
    (acceptedFiles) => {
      props.setValue([]);
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        const bodyFormData = new FormData();
        bodyFormData.append("files", file);
        bodyFormData.append("companyId", "ajudapet");
        bodyFormData.append("filename", file.name);
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = (evt) => {
          http.post("files", bodyFormData).then((e) => {
            props.value.length = 0;
            props.value.push(e.data.url);
            props.setValue([e.data.url]);
          });
        };

        reader.readAsDataURL(file);
      });
    },
    [setPaths, props, http]
  );
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [".jpeg", ".png", ".jpg"] },
      onDrop: onDrop,
    });

  useEffect(() => {
    console.log("paths => ", props.defaultFile);

    setPaths(props.defaultFile ? [props.defaultFile] : []);
  }, [props.defaultFile]);

  return (
    <div className="container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {paths.length === 0 ? (
          <ImageIcon width={51} height={41} />
        ) : (
          paths.map((path) => <ImageContainer key={path} src={path} />)
        )}
      </Container>
    </div>
  );
}

export default StyledDropzone;
