import axios from "axios"
import { _baseUrl } from "../utils/constants"


export const registerDonation = (donationData, userId) => {
    const {
        donee,
        value,
        confirmed,
    } = donationData

    if (userId) {
        const newDonation = {
            donnor: userId,
            donee: donee ? donee : null,
            value,
            confirmed,
        }

        return axios.post(`${_baseUrl}/donation/newdonation`, newDonation)
            .then(res => {
                console.log('res: ', res)
                return res
            })
            .catch(err => {
                console.log('err: ', err)
                return err
            })
    } else {
        return 'Sessão não foi iniciada'
    }
}
