import LogoImage from "../IconsDonate/LogoImage";
import LogoText from "../IconsDonate/LogoText";
import * as S from "./styles";
import { Divider } from "antd";

const HeaderDonate = () => (
  <S.Wrapper>
    <S.WrapperHeader>
      <S.WrapperLogo>
        <LogoImage />
        <LogoText />
      </S.WrapperLogo>

      <S.WrapperBtns>
        <Divider type="vertical" style={{ height: 50 }} />
        <S.SubmitButton
          style={{
            width: "100%",
            backgroundColor: "#f67e48",
          }}
          type="primary"
          htmlType="submit"
        >
          Login
        </S.SubmitButton>
        <a
          href="/register-user"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <S.SubmitButton
            style={{
              width: "100%",
              border: "1px solid #f67e48",
              color: "#f67e48",
            }}
            type="secondary"
          >
            Cadastrar
          </S.SubmitButton>
        </a>
      </S.WrapperBtns>
    </S.WrapperHeader>
  </S.Wrapper>
);

export default HeaderDonate;
