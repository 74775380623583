import styled from "styled-components";

export const StyledDatePickerWrapper = styled.div`
  .custom-datepicker {
    display: block;
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 2px solid #E6E8EC;
    &:focus {
      outline: none;
      border-color: #F67E48;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 2; 
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--keyboard-selected {
    background-color: #F67E48 !important;
    color: white !important;
  }

  .react-datepicker__day:hover {
    background-color: #faa07d !important;
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid #E6E8EC;
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;

  &:focus-within {
    border-color: #F67E48;
  }

  input {
    width: 100%;
    border: none;
    &:focus {
      outline: none;
    }
  }

  .calendar-icon {
    position: absolute;
    right: 10px;
    color: #B1B5C4;
  }
`;
