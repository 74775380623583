import { Col, Row, Grid, Input } from "antd";
import * as S from "./styles";
import DesktopHeaderImage from "../../assets/desktop_image.png";
import BgCollar from "../../assets/bg_collar.png";
import BgGestor from "../../assets/bg_gestor.png";

import BgSmart from "../../assets/bg_smart.png";
import IconPaw from "../../assets/paw_icon.png";
import IconHelp from "../../assets/help_icon.png";
import IconPicture from "../../assets/picture_icon.png";
import IconCalendar from "../../assets/calendar_icon.png";
import LogoFooter from "../../assets/logo_footer.png";
import LocationIcon from "../../assets/location_icon.png";
import ResizeIcon from "../../assets/resize_icon.png";
import DownloadIcon from "../../assets/download_icon.png";
import EditIcon from "../../assets/edit_icon.png";
import MoneyIcon from "../../assets/money_icon.png";
import {
  FacebookOutlined,
  InstagramOutlined,
  QrcodeOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { openModal } from "../../app/store/authSlice";
import { HomeAPI } from "./HomeAPI";
import { useNavigate } from "react-router-dom";
import {
  IconDownload,
  IconEdit,
  IconLocation,
  IconMoney,
  IconResize,
} from "../../components/Icons";
import Modal from "../../components/ModalVideo";
import { useState } from "react";
const { Search } = Input;

const { useBreakpoint } = Grid;
export const HomePage = () => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { findCode } = HomeAPI();
  const navigate = useNavigate();
  const openModalAuth = () => {
    dispatch(openModal());
  };
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        videoId="TI_XFymmH_8"
      />
      {screens.sm && (
        <Col>
          <Row justify={"center"}>
            <S.DesktopHeader src={DesktopHeaderImage}></S.DesktopHeader>
          </Row>
          <Row justify={"center"}>
            <S.KnowButton style={{ display: "none" }}>
              <QrcodeOutlined />
              <span style={{ marginLeft: ".5rem" }}>Conhecer</span>
            </S.KnowButton>
          </Row>

          <Row
            style={{ marginTop: "4rem", marginBottom: "4rem" }}
            justify={"center"}
          >
            <S.CollarContainer>
              <Col span={16}>
                <S.CollarTitle>COLEIRA</S.CollarTitle>
                <S.CollarInfo>
                  Achou um Pet com nossa identificação?
                </S.CollarInfo>

                <S.InputInfo>
                  Digite no campo abaixo o número do ID de identificação que
                  está na coleira
                </S.InputInfo>

                <S.SearchInput
                  placeholder="ID de identificação"
                  allowClear
                  name="code"
                  onSearch={findCode}
                  style={{}}
                />
              </Col>
              <Col span={8}>
                <S.DesktopHeader src={BgCollar}></S.DesktopHeader>
              </Col>
            </S.CollarContainer>
          </Row>

          <Row
            style={{ marginTop: "40px", marginBottom: "4rem" }}
            justify={"center"}
          >
            <S.CollarContainer>
              <Col span={12}>
                <S.DesktopHeader
                  className="youtube-player"
                  src={BgSmart}
                  onClick={() => setModalOpen(true)}
                />
              </Col>

              <Col span={12}>
                <S.CollarTitle>A COLEIRA</S.CollarTitle>
                <S.CollarInfo>Identificação Inteligente</S.CollarInfo>

                <S.InputInfo>
                  Coleiras e Tags inteligentes integradas a um sistema completo
                  de Gestão Pet.
                </S.InputInfo>
                <Row>
                  <Col span={12}>
                    <Row>
                      <img className="img-icon" src={MoneyIcon} />
                      <S.InfoSmartLabel>Sem mensalidades</S.InfoSmartLabel>
                    </Row>
                    <Row>
                      <img className="img-icon" src={ResizeIcon} />
                      <S.InfoSmartLabel>
                        Vincule a identificação ao GestorPet
                      </S.InfoSmartLabel>
                      <br></br>
                    </Row>

                    <Row>
                      <img className="img-icon" src={DownloadIcon} />
                      <S.InfoSmartLabel>
                        Sem necessidade de instalação do aplicativo
                      </S.InfoSmartLabel>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <img className="img-icon" src={EditIcon} />
                      <S.InfoSmartLabel>
                        Altere os dados quando quiser
                      </S.InfoSmartLabel>
                    </Row>
                    <Row>
                      <img className="img-icon" src={LocationIcon} />
                      <S.InfoSmartLabel>
                        Receba a localização aproximada quando acessado o Perfil
                        Pet
                      </S.InfoSmartLabel>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <S.KnowButton
                      style={{
                        width: "100%",

                        marginBottom: "1rem",
                      }}
                      onClick={() => {
                        window.location.href = "https://loja.ajuda.pet";
                      }}
                    >
                      Comprar
                    </S.KnowButton>
                  </Col>
                </Row>
              </Col>
            </S.CollarContainer>
          </Row>

          {/* <Row
            style={{ marginTop: "40px", marginBottom: "4rem" }}
            justify={"center"}
          >
            <S.CollarContainer>
              <Col span={12}>
                <S.CollarTitle>TECNOLOGIA</S.CollarTitle>
                <S.CollarInfo>Gestor Pet</S.CollarInfo>

                <S.InputInfo>
                  Monitore seu pet e contribua com a causa animal
                </S.InputInfo>
                <Row>
                  <Col span={24}>
                    <Row>
                      <img className="img-icon" src={IconPicture} />
                      <S.InfoSmartLabel>
                        Adicione fotos e crie um perfil completo de seus Pets.
                      </S.InfoSmartLabel>
                    </Row>
                    <Row>
                      <img className="img-icon" src={IconPaw} />
                      <S.InfoSmartLabel>
                        Insira dados de saúde consultas exames, mantendo o
                        histórico do pet.
                      </S.InfoSmartLabel>
                      <br></br>
                    </Row>
                    <Row>
                      <img className="img-icon" src={IconCalendar} />
                      <S.InfoSmartLabel>
                        Receba lembretes de vacinas, medicamento etc, direto no
                        calendário do seu celular.
                      </S.InfoSmartLabel>
                    </Row>
                    <Row>
                      <img className="img-icon" src={IconHelp} />
                      <S.InfoSmartLabel>
                        Você não precisa ter uma identificação para usar o
                        Gestor
                      </S.InfoSmartLabel>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <S.KnowButton
                      onClick={openModalAuth}
                      style={{ width: "100%" }}
                    >
                      Gerir meu Pet
                    </S.KnowButton>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <S.DesktopHeader src={BgGestor}></S.DesktopHeader>
              </Col>
            </S.CollarContainer>

            <Row>
              <S.FooterContainer span={24}>
                <img src={LogoFooter} alt="logo footer"></img>
              </S.FooterContainer>
              <S.FooterContainer span={8}>
                <S.FooterTitle>INSTITUCIONAL</S.FooterTitle>
                <S.FooterDescription>Como comprar</S.FooterDescription>
                <S.FooterDescription>Segurança</S.FooterDescription>
                <S.FooterDescription>Envios</S.FooterDescription>
                <S.FooterDescription>Pagamento</S.FooterDescription>
                <S.FooterDescription>Contato</S.FooterDescription>
              </S.FooterContainer>
              <S.FooterContainer span={8}>
                <S.FooterTitle>Atendimento</S.FooterTitle>
                <S.FooterDescription>(31) 98888-4400</S.FooterDescription>
                <S.FooterDescription>contato@ajudapet.org</S.FooterDescription>
                <S.FooterDescription>
                  Seg a Sexta das 09h as 19h
                </S.FooterDescription>
              </S.FooterContainer>
              <S.FooterContainer span={8}>
                <S.FooterTitle>Redes sociais</S.FooterTitle>
                <Row style={{ width: "100px" }} justify={"space-between"}>
                  <S.StyledIconLink
                    href="https://twitter.com/ajudapetoficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterOutlined />
                  </S.StyledIconLink>
                  <S.StyledIconLink
                    href="https://www.facebook.com/ajudapetoficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookOutlined />
                  </S.StyledIconLink>
                  <S.StyledIconLink
                    href="https://www.instagram.com/ajudapetoficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramOutlined />
                  </S.StyledIconLink>
                </Row>
              </S.FooterContainer>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "18px",
                }}
              >
                <S.Copyright>
                  Copyright © 2023 ajudapet. Todos os direitos reservados
                </S.Copyright>
              </div>
            </Row>
          </Row> */}
        </Col>
      )}
      {screens.xs && (
        <Row>
          <Col>
            {" "}
            <Col>
              <Row justify={"center"}>
                <S.DesktopHeader src={DesktopHeaderImage}></S.DesktopHeader>
              </Row>
              <Row justify={"center"}>
                <S.KnowButton style={{ display: "none" }}>
                  <QrcodeOutlined />
                  <span style={{ marginLeft: ".5rem" }}>Conhecer</span>
                </S.KnowButton>
              </Row>

              <Row
                style={{ marginTop: "4rem", marginBottom: "4rem" }}
                justify={"center"}
              >
                <S.CollarContainer>
                  <Col span={24}>
                    <S.CollarTitle>COLEIRA</S.CollarTitle>
                    <S.CollarInfo>
                      Achou um Pet com nossa identificação?
                    </S.CollarInfo>

                    <S.InputInfo>
                      Digite no campo abaixo o número do ID de identificação que
                      está na coleira
                    </S.InputInfo>

                    <S.SearchInput
                      placeholder="ID de identificação"
                      allowClear
                      name="code"
                      onSearch={findCode}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Col>
                </S.CollarContainer>
              </Row>

              <Row
                style={{ marginTop: "40px", marginBottom: "4rem" }}
                justify={"center"}
              >
                <S.CollarContainer>
                  <Col span={24}>
                    <S.DesktopHeader
                      className="youtube-player"
                      src={BgSmart}
                      onClick={() => setModalOpen(true)}
                    ></S.DesktopHeader>
                  </Col>
                </S.CollarContainer>
                <Col span={24}>
                  <S.CollarTitle>A COLEIRA</S.CollarTitle>
                  <S.CollarInfo>Identificação Inteligente</S.CollarInfo>

                  <S.InputInfo>
                    Coleiras e Tags inteligentes integradas a um sistema
                    completo de Gestão Pet.
                  </S.InputInfo>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <img className="img-icon" src={MoneyIcon} />
                        <S.InfoSmartLabel>Sem mensalidades</S.InfoSmartLabel>
                      </Row>
                      <Row>
                        <img className="img-icon" src={ResizeIcon} />
                        <S.InfoSmartLabel>
                          Vincule a identificação ao GestorPet
                        </S.InfoSmartLabel>
                        <br></br>
                      </Row>
                      <Row>
                        <img className="img-icon" src={DownloadIcon} />
                        <S.InfoSmartLabel>
                          Sem necessidade de instalação de aplicativo
                        </S.InfoSmartLabel>
                        <br></br>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <img className="img-icon" src={EditIcon} />
                        <S.InfoSmartLabel>
                          Altere os dados quando quiser
                        </S.InfoSmartLabel>
                      </Row>
                      <Row>
                        <img className="img-icon" src={LocationIcon} />
                        <S.InfoSmartLabel>
                          Receba a localização aproximada quando <br />
                          acessado o Perfil Pet
                        </S.InfoSmartLabel>
                      </Row>
                    </Col>
                    <S.KnowButton
                      style={{
                        width: "100%",

                        marginBottom: "1rem",
                      }}
                      onClick={() => {
                        window.location.href = "https://loja.ajuda.pet";
                      }}
                    >
                      Comprar
                    </S.KnowButton>

                    {/* <S.KnowButton
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "#ff6e30",
                        borderColor: "#ff6e30",
                      }}
                      onClick={() => {
                        navigate("/reader");
                      }}
                    >
                      Ativar minha identificação
                    </S.KnowButton> */}
                  </Row>
                </Col>
              </Row>

              <Row
                style={{ marginTop: "40px", marginBottom: "4rem" }}
                justify={"center"}
              >
                <Col span={24}>
                  <S.DesktopHeader src={BgGestor}></S.DesktopHeader>
                </Col>
                <S.CollarContainer>
                  <Col span={24}>
                    <S.CollarTitle>TECNOLOGIA</S.CollarTitle>
                    <S.CollarInfo>Gestor Pet</S.CollarInfo>

                    <S.InputInfo>
                      Monitore seu pet e contribua com a causa animal{" "}
                    </S.InputInfo>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <img className="img-icon" src={IconPicture} />
                          <S.InfoSmartLabel>
                            Adicione fotos e crie um perfil completo de seus
                            Pets.
                          </S.InfoSmartLabel>
                        </Row>
                        <Row>
                          <img className="img-icon" src={IconPaw} />
                          <S.InfoSmartLabel>
                            Insira dados de saúde consultas exames, mantendo o
                            histórico do pet.
                          </S.InfoSmartLabel>
                          <br></br>
                        </Row>
                        <Row>
                          <img className="img-icon" src={IconCalendar} />
                          <S.InfoSmartLabel>
                            Receba lembretes de vacinas, medicamento etc, direto
                            no calendário do seu celular.
                          </S.InfoSmartLabel>
                        </Row>
                        <Row>
                          <img className="img-icon" src={IconHelp} />
                          <S.InfoSmartLabel>
                            Você não precisa ter uma identificação para usar o
                            Gestor
                          </S.InfoSmartLabel>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <S.KnowButton
                          onClick={openModalAuth}
                          style={{
                            width: "100%",
                          }}
                        >
                          Gerir meu Pet
                        </S.KnowButton>
                      </Col>
                    </Row>
                  </Col>
                </S.CollarContainer>
              </Row>
            </Col>
          </Col>
          {/* <S.FooterContainer span={24}>
            <img src={LogoFooter} alt="logo footer"></img>
            <S.FooterTitle>INSTITUCIONAL</S.FooterTitle>
            <S.FooterDescription>Como comprar</S.FooterDescription>
            <S.FooterDescription>Segurança</S.FooterDescription>
            <S.FooterDescription>Envios</S.FooterDescription>
            <S.FooterDescription>Pagamento</S.FooterDescription>
            <S.FooterDescription>Contato</S.FooterDescription>
            <S.FooterTitle>Atendimento</S.FooterTitle>
            <S.FooterDescription>(31) 98888-4400</S.FooterDescription>
            <S.FooterDescription>contato@ajudapet.org</S.FooterDescription>
            <S.FooterDescription>
              Seg a Sexta das 09h as 19h
            </S.FooterDescription>
            <S.FooterTitle>Redes sociais</S.FooterTitle>
            <Row style={{ width: "100px" }} justify={"space-between"}>
              <TwitterOutlined></TwitterOutlined>
              <FacebookOutlined></FacebookOutlined>
              <InstagramOutlined></InstagramOutlined>
            </Row>
          </S.FooterContainer> */}
        </Row>
      )}
    </>
  );
};
