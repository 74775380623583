import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";
import Swal from "sweetalert2";

const ConfigAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const saveConfig = (configs) => {
    try {
      configs.primaryColor = configs.primaryColor.hex;
      configs.secondaryColor = configs.secondaryColor.hex;
      configs.companyId = authState.user._id;
      const response = http.post("configs/", configs);

      Swal.fire({
        title: "Configurações salvas com sucesso!",
        icon: "success",
      });
    } catch (error) {}
  };

  const getConfig = async () => {
    try {
      const response = await http.get("configs/" + authState.user._id);
      return response.data;
    } catch (error) {}
  };

  return {
    saveConfig,
    getConfig,
  };
};

export default ConfigAPI;
