import React, { useEffect } from "react";
import * as S from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { selectRegisterstate } from "../RegisterPetSlice";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";

const Sucess = (props) => {
  const registerPetState = useSelector(selectRegisterstate);
  const { http } = useAPITag();
  const navigate = useNavigate();
  
  const changeQrStatus = (code) => async () => {
    try {
      const statusQr = await http.patch(`/tags/change-status-admin/${code}`, {
        statusQr: "ok",
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    changeQrStatus(registerPetState.code)();
  })


  return (
    <RegisterPetLayout>
      <S.WrapperRegisterCellphone>
        <S.WrapperDataUser>
          <S.WrapperSucessDescription>
            <S.SucessWrapperTitle>
              <S.SucessTitle>Coleira ativada com sucesso!</S.SucessTitle>
              <S.SucessDescription>
                Os dados do pet foram vinculados ao seu número de celular 
e ele já está identificado e seguro
              </S.SucessDescription>
            </S.SucessWrapperTitle>

            <S.WrapperSucessButton>
              <S.SubmitButton
                onClick={(e) => {
                  navigate("/profile/" + registerPetState.code);
                }}
              >
                Ir para o perfil
              </S.SubmitButton>
            </S.WrapperSucessButton>
          </S.WrapperSucessDescription>
        </S.WrapperDataUser>
      </S.WrapperRegisterCellphone>
    </RegisterPetLayout>
  );
};

export default Sucess;
