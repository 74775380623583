import { useSelector } from "react-redux";
import RegisterHeader from "../../RegisterHeader";
import * as S from "./styles";
import { selectConfig } from "../../../features/register-pet/RegisterPetSlice";
import ArrowLeftIcon from "../../Icons/ArrowLeft";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import Header from "../../Header";
import { ModalAuth } from "../../modalAuth";

const RegisterPetLayout = (props) => {
  const config = useSelector(selectConfig);
  const { isMobile } = useMobileDetect();

  return (
    <S.Wrapper theme={config}>
      <Header></Header>
      <ModalAuth></ModalAuth>
      <S.Content>
        <S.Container maxHeight={props.maxHeight}>
          <S.MainHeader>
            {props.showBackButton && isMobile && (
              <S.IconBackContainer onClick={props.onClickBackButton}>
                <ArrowLeftIcon titleId="voltar" />
              </S.IconBackContainer>
            )}

            {isMobile && (
              <S.StepBarWrapper>
                <S.StepBar
                  theme={config}
                  size={props.size}
                  step={props.step}
                ></S.StepBar>
              </S.StepBarWrapper>
            )}
          </S.MainHeader>
          {props.hasHeader && (
            <RegisterHeader
              title={props.title}
              description={props.description}
            ></RegisterHeader>
          )}
          {props.children}
        </S.Container>
      </S.Content>
    </S.Wrapper>
  );
};

export default RegisterPetLayout;
