import { Auth } from "./loginAPI";
import * as S from "./styles";
import { Button, Checkbox, Form, Input } from "antd";
import Logo from "../../../assets/Logo_Laranja.svg";

export function Login() {
  const onFinish = Auth();

  return (
    <S.Wrapper>
      <S.Content>
        <img style={{ marginBottom: "20px" }} width={250} src={Logo}></img>

        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ width: "100%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { required: true, message: "Informe o E-mail", type: "email" },
            ]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            name="password"
            label="Senha"
            rules={[{ required: true, message: "Informe a Senha" }]}
          >
            <S.StylePassInput style={{ color: "black !important" }} />
          </Form.Item>

          <Form.Item>
            <S.SubmitButton
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              Entrar
            </S.SubmitButton>
          </Form.Item>
        </Form>

        <a href="/registro" style={{ textDecoration: "none", width: "100%" }}>
          <S.CancelButton
            style={{
              width: "100%",
              border: "1px solid #f67e48",
              color: "#f67e48",
            }}
            type="secondary"
          >
            Cadastrar
          </S.CancelButton>
        </a>
      </S.Content>
    </S.Wrapper>
  );
}
