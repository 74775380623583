import { useAPI } from "../../services/api";

export const useSignUp = () => {
  const api = useAPI();

  const run = async (data) => {
    return await api.post(`admin/auth/register`, data);
  };

  return run;
};
