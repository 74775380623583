import { Button, Divider, Form, Input, InputNumber, Select } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { useGetAddress } from "../../../data/usecases/use-get-address";
import { MaskedInput } from "antd-mask-input";
import Swal from "sweetalert2";
import UsersAPI from "./UsersAPI";
import { useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

const FormUsers = (props) => {
  const { getUser, updateUser, createUser } = UsersAPI();
  const getAddress = useGetAddress();
  const [form] = useForm();
  const { id } = useParams();
  const authState = useSelector(selectAuthState);

  useEffect(() => {
    if (props.edit) {
      const loadCompany = async () => {
        const data = await getUser(id);
        form.setFieldsValue(data);
      };

      loadCompany();
    }
  }, []);

  const [address, setAddress] = useState({
    bairro: "",
    cidade: "",
    logradouro: "",
    cep: "",
    estado: "",
  });

  const handleFinish = async (data) => {
    data.companyId = authState.user._id;
    data.phone = data.phone.replace(/\D/g, "");

    if (props.edit) {
      updateUser(id)(data);
      return;
    }

    createUser(data);
  };

  return (
    <Dashboard selected="10">
      <S.Wrapper>
        <Form form={form} labelCol={{ span: 24 }} onFinish={handleFinish}>
          <Form.Item
            name="phone"
            label="Telefone"
            rules={[{ required: true, message: "Informe o telefone" }]}
          >
            <MaskedInput mask={"(00) 0000-00000"} />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nome"
            rules={[{ required: true, message: "Informe o nome" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Sobrenome"
            rules={[{ required: true, message: "Informe o sobrenome" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              { required: true, message: "Informe o email", type: "email" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item initialValue={"..............."} name="password">
            <Input type="hidden" />
          </Form.Item>

          <Button htmlType="submit">
            {props.edit ? "Editar" : "Criar"} Empresa
          </Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};

export default FormUsers;
