import React from "react"

const BurgerIcon = ({
  title = 'BurgerIcon',
  titleId = 'burger-icon',
  color = '#23262F',
  ...props
}) => (
  <svg
    width={22}
    height={12}
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M1.66659 0.666626C0.930206 0.666626 0.333252 1.26358 0.333252 1.99996C0.333252 2.73634 0.930206 3.33329 1.66659 3.33329H20.3333C21.0696 3.33329 21.6666 2.73634 21.6666 1.99996C21.6666 1.26358 21.0696 0.666626 20.3333 0.666626H1.66659Z"
      fill="#23262F"
    />
    <path
      d="M1.66659 8.66663C0.930206 8.66663 0.333252 9.26358 0.333252 9.99996C0.333252 10.7363 0.930206 11.3333 1.66659 11.3333H20.3333C21.0696 11.3333 21.6666 10.7363 21.6666 9.99996C21.6666 9.26358 21.0696 8.66663 20.3333 8.66663H1.66659Z"
      fill="#23262F"
    />
  </svg>
)

export default BurgerIcon