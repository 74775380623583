import { Login } from "../features/admin/login/login";
import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from "react-router-dom";
import { Register } from "../features/admin/register/register";
import { useSelector } from "react-redux";
import { selectAuthState } from "../features/admin/login/loginSlice";
import { Collars } from "../features/admin/collars/collars";
import { ChangePass } from "../features/admin/change-password";
import ProfilePet from "../features/profile-pet/ProfilePet";
import { AuthPhone } from "../features/register-pet/auth-phone";
import { AuthPhonePreRegister } from "../features/register-pet/auth-phone/AuthPhonePreRegister";

import { ConfirmCode } from "../features/register-pet/confirm-code";
import { RegisterPet } from "../features/register-pet/register-pet";
import Sucess from "../features/register-pet/success";
import { RegisterUser } from "../features/register-pet/register-user";
import { CollarSizes } from "../features/admin/collar-size";
import CreateCollarSize from "../features/admin/collar-size/create";
import { CollarType } from "../features/admin/collar-type";
import CreateCollarType from "../features/admin/collar-type/create";
import { Tags } from "../features/admin/tags";
import { Pets } from "../features/admin/pets";
import Config from "../features/admin/configs";
import { MyTags } from "../features/admin/tags/me";
import { MyPets } from "../features/admin/pets/me";
import { DashPage } from "../features/admin/dashboard";
import Home from "../features/home";
import ConfigUser from "../features/ConfigUser";
import { MyShippments } from "../features/admin/shippments/me";
import { Companies } from "../features/admin/companies";
import FormCompany from "../features/admin/companies/create";
import { CompaniesCollars } from "../features/admin/companies-collars/collars";
import CreatePet from "../features/admin/pets/create";
import { Users } from "../features/admin/users";
import FormUsers from "../features/admin/users/create";
import { HomePage } from "../features/home/home";
import { GestorPet } from "../features/gestor-pet";
import { ConfirmCodeEmail } from "../features/register-pet/confirm-code-email";
import { QRReader } from "../features/qr-reader";
import WhatsappConfig from "../features/admin/whatsapp-config";
import CreatePlate from "../features/admin/plates";
import { SelectPet } from "../features/register-pet/select-pet";
import Doar from "../donate/pages/Doar";
import Scheduling from "../features/scheduling/pages/Scheduling";
import ValueDonation from "../donate/pages/Doar/ValueDonation";
import SelectPayment from "../donate/pages/Doar/SelectPayment";
import BillinDetails from "../donate/pages/Doar/BillinDetails";
import CreditCard from "../donate/pages/Doar/CreditCard";
import PixPayment from "../donate/pages/Doar/PixPayment";
import TankYou from "../donate/pages/Doar/ThankYou";
import { CollarAssets } from "../features/admin/collar-assets";
import { CreataCollarAssets } from "../features/admin/collar-assets/create";
import { CreateGabarit } from "../features/admin/gabarit";
import Legal from "../features/legal";
import Privacy from "../features/privacy";
import { Petshops } from "../features/admin/petshops";
import PetShop from "../features/admin/petshops/create";
import { RegisterUserSimulated } from "../features/simulator/register-user";
import ProfilePetSimulated from "../features/simulator/profile-pet/ProfilePet";
import { Leads } from "../features/admin/leads";
import { ResetPassword } from "../features/register-pet/reset-password";
import  UserConfig  from "../features/ConfigUser/UserSettings";
import PreRegister from "../features/register-pet/pre-register";
import { FinalRegister } from "../features/register-pet/auth-phone/FinalRegister";
import SendLocationProfile from "../features/profile-pet/sendLocationProfile";
import ChangeStatus from "../features/admin/change-status";
import SchedulingPage from "../features/scheduling/pages/Scheduling/SchedulingPage";
import CustomLinkCreate from "../donate/pages/Doar/CustomLinkCreate";


const ProtectedRoutes = (props) => {
  const authState = useSelector(selectAuthState);
  const location = useLocation();
  if (authState.user == null) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Outlet />;
};

const SuperAdminRoutes = (props) => {
  const authState = useSelector(selectAuthState);
  const location = useLocation();
  if (authState.user.superadmin == null) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Outlet />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/legal" element={<Legal />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="registro" element={<Register />} />
      <Route path="/:id" element={<AuthPhone />} />
      <Route path="/finalRegister/:id" element={<FinalRegister />} />

      <Route path="preRegister/profile/:id" element={<PreRegister />} />
      <Route path="finishRegister/:id" element={<AuthPhonePreRegister />} />



      <Route path="/pet-sim/:id" element={<RegisterUserSimulated />} />
      <Route path="/pet-sim/success/:id" element={<ProfilePetSimulated />} />

      <Route path="confirm-code/:id" element={<ConfirmCode />} />
      <Route path="confirm-email" element={<ConfirmCodeEmail />} />
      <Route path="confirm-recover" element={<ConfirmCodeEmail recover />} />

      <Route path="request-otp" element={<ResetPassword recover />} />
      
      <Route path="register-pet" element={<RegisterPet />} />
      <Route path="select-pet" element={<SelectPet />} />
      <Route path="register-user" element={<RegisterUser />} />
      <Route path="reader" element={<QRReader />} />
      <Route path="reader-new" element={<QRReader add />} />
      <Route path="/pet-sim/:id" element={<RegisterUserSimulated />} />
      <Route path="/pet-sim/success/:id" element={<ProfilePetSimulated />} />
      <Route path="profile/:id" element={<ProfilePet />} />
      <Route path="profile/location/:id" element={<SendLocationProfile />} />
      <Route path="/gestor-pet/:id" element={<GestorPet />}></Route>

      <Route path="/medicamentos" element={<Scheduling />}>
        <Route index element={<SchedulingPage />} />
      </Route>

      <Route path="success" element={<Sucess />} />
      <Route path="" element={<Home />}></Route>
      <Route path="/config-user" element={<Home />}></Route>
      <Route path="/user-settings" element={<UserConfig />}></Route>
      <Route path="/" element={<Doar />}>
        <Route path="/doar/:username" element={<ValueDonation />}></Route>
        <Route path="/selecionar-pagamento" element={<SelectPayment />}></Route>
        <Route path="/detalhes-pagamento" element={<BillinDetails />}></Route>
        <Route path="/pagamento-cartao" element={<CreditCard />}></Route>
        <Route path="/pagamento-pix" element={<PixPayment />}></Route>
        <Route path="/obrigado" element={<TankYou />}></Route>
        <Route path="/create-customlink" element={<CustomLinkCreate />}/>
      </Route>
      <Route path="admin" element={<ProtectedRoutes />}>
        <Route path="" element={<DashPage />}></Route>
        <Route path="collars" element={<Collars />} />
        <Route path="collars/gabarit" element={<CreateGabarit />} />

        <Route path="config" element={<Config />} />
        <Route path="shippments/me" element={<MyShippments />} />

        <Route path="tags/me" element={<MyTags />} />
        <Route path="pets/me" element={<MyPets />} />
        <Route path="tags/qr" element={<ChangeStatus />} />
        <Route path="pets/edit/:id" element={<CreatePet edit />} />

        <Route path="pets/create" element={<CreatePet />} />
        <Route path="users" element={<Users />} />
        <Route path="users/create" element={<FormUsers />} />
        <Route path="users/:id" element={<FormUsers edit />} />
        <Route path="plates" element={<CreatePlate />} />

        <Route path="change-passowrd" element={<ChangePass />} />
        <Route path="" element={<SuperAdminRoutes />}>
          <Route path="companies-collars" element={<CompaniesCollars />} />
          <Route path="companies" element={<Companies />} />
          <Route path="petshops" element={<Petshops />} />
          <Route path="leads" element={<Leads />} />
          <Route path="petshops/:id" element={<PetShop edit />} />
          <Route path="leads" element={<Leads></Leads>}></Route>
          <Route path="companies/create" element={<FormCompany />} />
          <Route path="companies/:id" element={<FormCompany edit />} />
          <Route path="collar-sizes" element={<CollarSizes />} />
          <Route path="tags" element={<Tags />} />
          <Route path="whatsapp" element={<WhatsappConfig />} />
          <Route path="pets" element={<Pets />} />

          <Route path="collar-sizes/create" element={<CreateCollarSize />} />
          <Route
            path="collar-sizes/edit/:id"
            element={<CreateCollarSize edit />}
          />
          <Route path="collar-types" element={<CollarType />} />
          <Route path="collar-types/create" element={<CreateCollarType />} />
          <Route
            path="collar-types/edit/:id"
            element={<CreateCollarType edit />}
          />
          <Route path="collar-assets/create" element={<CreataCollarAssets />} />
          <Route
            path="collar-assets/edit/:id"
            element={<CreataCollarAssets edit />}
          />
          <Route path="collar-assets" element={<CollarAssets />} />
        </Route>
      </Route>
    </>,
  ),
);

export { router };
