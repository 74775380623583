import Button from '../Button'
import * as S from './styles'

const SocialMediaItem = ({icon, title, profile, isConected}) => {
  return (
    <S.Wrapper>
      <S.WrapperLeft>
        {icon}
        <S.WrapperDetails>
          <S.Title>{title}</S.Title>
          <S.Describe>{profile}</S.Describe>
        </S.WrapperDetails>
      </S.WrapperLeft>
      <Button color='minimal'>{isConected ? 'Desconectar' : 'Conectar'}</Button>
    </S.Wrapper>
  )
}

export default SocialMediaItem