import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
  /* height: ${({ maxHeight }) => (maxHeight ? "auto" : "100vh")}; */
  overflow-x: hidden;
  background-color: white;
`;

export const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  overflow-x: hidden;

  @media screen and (min-width: 480px) {
    align-items: center;
    background-color: ${({ theme }) => theme.primaryColor};
  }

  @media screen and (max-width: 767px) {
    min-height: unset;
  }

  .ant-input:focus-within {
    outline: none !important;
    border: 2px solid ${({ theme }) => theme.primaryColor} !important;
  }

  .ant-input-password:focus-within {
    outline: none !important;
    border: 2px solid ${({ theme }) => theme.primaryColor} !important;
    .ant-input:focus-within {
      outline: none !important;
      border: none !important;
    }
  }

  .ant-btn {
    background-color: ${({ theme }) => theme.primaryColor};
  }

  .ant-btn:hover {
    background-color: ${({ theme }) => theme.primaryColor}DD;
  }

  .whatsapp {
    color: ${({ theme }) => theme.primaryColor};
    background-color: white;
    border-color: ${({ theme }) => theme.primaryColor};
  }

  .whatsapp:hover {
    background-color: ${({ theme }) => theme.primaryColor}DD;
    color: white;
  }

  .ant-btn:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: none;
  }

  .ant-select-selector:hover {
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
  .ant-picker-focused:hover {
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;  // Alteração aqui
  background-color: white;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
  @media screen and (min-width: 320px) {
    width: 320px;
    padding-top: 1rem;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  @media screen and (min-width: 480px) {
    width: 440px;
    padding-top: 1rem;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  @media screen and (min-width: 1023px) {
    padding-top: 1rem;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: unset;
    min-height: unset;
  }
`;

export const MainHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StepBar = styled.div`
  width: ${(props) => (props.step / props.size) * 100}%;
  border-radius: 25px;
  height: 2px;
  background-color: ${({ theme }) => theme.primaryColor};
`;

export const StepBarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const IconBackContainer = styled.button`
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: unset;
  border: unset;
  position: absolute;
  left: 24px;
  top: 0px;
`;