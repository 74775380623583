import CurrencyInput from "react-currency-input-field";
import styled from "styled-components";
import Styled from "styled-components";

import ButtonDonate from "../../../../components/ButtonDonate";

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonSubmit = styled(ButtonDonate)`
  margin-bottom: 20px;
`;

export const SectionTitle = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;
`;

export const SectionDonation = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    margin-top: 40px;
`;

export const WrapperOptionsValues = Styled.div`
  display: flex;
  margin-top: 52px;
  max-width: 288px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
export const WrapperInput = Styled.div`
  display: flex;
  flex-direction: row;
  max-width: 142px;
`;

export const InputStyled = Styled(CurrencyInput)`
  border: none;
  font-size: 40px;
  font-weight: 200;
  color: #B1B5C3;
  width: 100%;
  outline: 0;
  text-align: center;

  ::placeholder {
    color: #B1B5C3;
    font-size: 56px;
  }
`;

export const WrapperButton = Styled.div`
  width: 100%;
  margin-top: 80px;
  text-align: center;
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #b1b5c3;

  @media (max-width: 480px) {
    display: none;
  }
`;
