import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch } from "react-redux";

const CollarSizeAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteCollarSize = (id) => {
    try {
      const response = http.delete("collar-size/" + id);

      navigate("/admin/collar-sizes");
    } catch (error) {}
  };

  const createCollarSize = (e) => {
    try {
      const response = http.post("collar-size", e);

      navigate("/admin/collar-sizes");
    } catch (error) {}
  };

  const updateCollarSize = (id) => (e) => {
    try {
      const response = http.patch("collar-size/" + id, e);

      navigate("/admin/collar-sizes");
    } catch (error) {}
  };

  const getCollarSizes = async () => {
    try {
      const response = await http.get("/collar-size");
      return response.data;
    } catch (error) {}
  };

  const findCollarSize = async (id) => {
    try {
      const response = await http.get("/collar-size/" + id);

      return response.data;
    } catch (error) {}
  };

  return {
    deleteCollarSize,
    createCollarSize,
    findCollarSize,
    updateCollarSize,
    getCollarSizes,
  };
};

export default CollarSizeAPI;
