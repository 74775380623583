import React from "react";
import { Spin } from "antd";

export function LoaderAdmin() {
  return (
    <div className="fixed w-8/12 h-screen bg-white flex justify-start items-center z-50">
      <Spin className="w-full " size="large" />
    </div>
  );
}
export default function Loader() {
  return (
    <div className="fixed w-full h-screen bg-white flex justify-center items-center z-50">
      <Spin size="large" />
    </div>
  );
}
