import Title from "../../../../components/Title";
import SubTitle from "../../../../components/Subtitle";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { ENUM_PAYMENT } from "../../../../context/DonationContext";
import { ContainerInput, Input, Label } from "../styles";

import * as S from "./styles";
import { useEffect, useState } from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import { requestCreditCard } from "../../../services/payment-client";
import Swal from "sweetalert2";
import { CardPayment, initMercadoPago } from "@mercadopago/sdk-react";
import { Spin } from "antd";
import { DoarAPI } from "../doarAPI";
import { useSelector } from "react-redux";
import {
  getDonationValue,
  getSelectedPaymentMethod,
} from "../../../donate.slice";

const CreditCard = ({
  onDonation,
  onStep,
  step,
  changePayment,
  onBillinDetails,
}) => {
  const typePayment = useSelector(getSelectedPaymentMethod);
  const [loading, setLoading] = useState(true);
  const { changeDonationValue, goToStep } = DoarAPI();
  const donationValue = useSelector(getDonationValue);
  const { control, register, watch, handleSubmit, getValues } = useForm();
  const onSelect = (type) => {
    changePayment(type);
  };
  let mp;

  useEffect(() => {
    const loadMP = async () => {
      initMercadoPago("APP_USR-3e996c15-a37c-4132-9922-115091bd1d11");
    };

    loadMP();
  }, []);

  return (
    <>
      <S.SectionTitle>
        <Title></Title>
        <S.WrapperValueDonation>
          <S.SectionInfo>
            <S.SubTitleSection>Valor a ser doado</S.SubTitleSection>
            <S.WrapperDataInfos>
              <S.DonationTag>{donationValue}</S.DonationTag>
              <S.LinkChange
                onClick={() => changeDonationValue("/pagamento-cartao")}
              >
                Alterar
              </S.LinkChange>
            </S.WrapperDataInfos>
          </S.SectionInfo>
          <S.SectionInfo>
            <S.SubTitleSection>Forma de pagamento</S.SubTitleSection>
            <S.WrapperDataInfos>
              <S.DonationTag>{ENUM_PAYMENT[typePayment]}</S.DonationTag>
              <S.LinkChange onClick={() => goToStep("/selecionar-pagamento")}>
                Alterar
              </S.LinkChange>
            </S.WrapperDataInfos>
          </S.SectionInfo>
        </S.WrapperValueDonation>
      </S.SectionTitle>
      <S.SectionDetails>
        {loading && (
          <div
            style={{
              marginTop: "3rem",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />{" "}
          </div>
        )}

        <CardPayment
          locale="pt-BR"
          initialization={{
            amount: donationValue,
          }}
          customization={{
            visual: {
              style: {
                theme: "bootstrap", // | 'dark' | 'bootstrap' | 'flat'
                baseColor: "#F67E48",
              },
            },
            paymentMethods: {
              maxInstallments: 1,
            },
          }}
          onReady={() => {
            setLoading(false);
          }}
          onSubmit={async (param) => {
            const result = await requestCreditCard(param);

            if (result) {
              goToStep("/obrigado");
            }
          }}
        />
      </S.SectionDetails>
    </>
  );
};

export default CreditCard;
