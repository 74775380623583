import { useSelector } from "react-redux";
import { useAPIPDF, useAPIPet, useAPITag } from "../../services/api";
import { selectAuthState } from "../../features/admin/login/loginSlice";
import * as S from "./styles";

export const APIFiles = () => {
  const { http } = useAPITag();
  const apiPDF = useAPIPet();
  const authState = useSelector(selectAuthState);

  const getFiles = async () => {
    const response = await http.get("files/" + authState.user._id);

    return response.data;
  };
  const uploadFile = async (data) => {
    data.fileKey = `${authState.user._id}/` + data.name;
    const response = await apiPDF.http.post("upload", data);

    const createFileResponse = await http.post("files", {
      companyId: authState.user._id,
      name: data.name,
      url: response.data.link,
    });

    return createFileResponse.data;
  };

  return {
    getFiles,
    uploadFile,
  };
};
