import { MaskedInput } from "antd-mask-input";
import { SignUp } from "./registerAPI";
import * as S from "./styles";
import { Divider, Form } from "antd";
import { useGetAddress } from "../../../data/usecases/use-get-address";
import { useState } from "react";
import Swal from "sweetalert2";
import Logo from "../../../assets/Logo_Laranja.svg";

export const Register = () => {
  const getAddress = useGetAddress();
  const [form] = Form.useForm();

  const onFinish = SignUp();

  const onFinishFailed = (errorInfo) => {
    Swal.fire({
      title: "Preencha todos os campos",
      icon: "error",
    });
  };

  const [address, setAddress] = useState({
    bairro: "",
    cidade: "",
    logradouro: "",
    cep: "",
    estado: "",
  });

  const onCepChange = async (e) => {
    if (e.unmaskedValue.length >= 8) {
      const response = await getAddress(e.unmaskedValue);
      console.log(response.data);
      console.log(form.getFieldsValue());
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...response.data,
        cep: e.maskedValue,
      });
    }
  };

  return (
    <S.Wrapper>
      <S.Content>
        <img width={200} src={Logo}></img>

        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          labelAlign="top"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ width: "100%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="CNPJ da Empresa"
            name="cnpj"
            rules={[{ required: true, message: "Informe o CNPJ da Empresa" }]}
          >
            <MaskedInput mask={"00.000.000.0000-00"} />
          </Form.Item>

          <Form.Item
            label="Nome Fantasia"
            name="nome_fantasia"
            rules={[
              {
                required: true,
                message: "Preencha o Nome Fantasia da empresa",
              },
            ]}
          >
            <S.StyledInput />
          </Form.Item>
          <Form.Item
            label="Razão Social"
            name="razao_social"
            rules={[
              { required: true, message: "Preencha a Razão social da empresa" },
            ]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Informe o email", type: "email" },
            ]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            label="Telefone para contato"
            name="telefone"
            rules={[
              { required: true, message: "Informe o telefone da empresa" },
            ]}
          >
            <MaskedInput mask={"(00) 00000-0000"} />
          </Form.Item>

          <Divider style={{ backgroundColor: "white" }}></Divider>

          <Form.Item
            label="CEP"
            name="cep"
            rules={[{ required: true, message: "Informe o CEP" }]}
          >
            <MaskedInput onChange={onCepChange} mask={"00.000-000"} />
          </Form.Item>

          <Form.Item
            label="Rua"
            name="logradouro"
            initialValue={address.logradouro}
            rules={[{ required: true, message: "Informe a Rua" }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            label="Bairro"
            name="bairro"
            initialValue={address.bairro}
            rules={[{ required: true, message: "Informe o Bairro" }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            label="Cidade"
            name="cidade"
            initialValue={address.cidade}
            rules={[{ required: true, message: "Informe a cidade" }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            label="Estado"
            name="estado"
            rules={[{ required: true, message: "Informe o estado" }]}
          >
            <S.StyledInput value={address.estado} />
          </Form.Item>

          <Form.Item
            label="Número"
            name="numero"
            rules={[{ required: true, message: "Informe o número" }]}
          >
            <S.StyledInput value={address.estado} />
          </Form.Item>

          <Form.Item label="Complemento" name="complemento" rules={[]}>
            <S.StyledInput value={address.estado} />
          </Form.Item>
          <Form.Item>
            <S.SubmitButton
              style={{ width: "100%", marginTop: "1.3rem" }}
              type="primary"
              htmlType="submit"
            >
              Entrar
            </S.SubmitButton>
          </Form.Item>
        </Form>
      </S.Content>
    </S.Wrapper>
  );
};
