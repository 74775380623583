import { Button, Form, Input, InputNumber, Select } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import CreatePlateAPI from "./platesAPI";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { UploadInput } from "../../../components/uploadInput";

const CreatePlate = (props) => {
  const { generatePlate, saveConfig, loadPlatesConfigs } = CreatePlateAPI();
  const [form] = useForm();
  const { id } = useParams();
  const [configs, setConfigs] = useState([]);

  useEffect(() => {
    const initState = async () => {
      setConfigs(await loadPlatesConfigs());
    };

    initState();
  }, []);
  return (
    <Dashboard selected="70">
      <S.Wrapper>
        <Form
          initialValues={{
            title: "Teste",
            customWidth: 27,
            qrSlimRange: 0,
            qrcodeSize: 6.185,
            qrCodeVerticalAlign: 4.408,
            qrCodeHorizontalAlign: 18.293,
            codeFontSize: 4,
            codeKerning: 0,
            codeLetterThickening: 0,
            codeFont: "RobotoMono-Regular",
            codeHorizontalCompress: 0,
            codeVerticalCompress: 0,
            codeHorizontalAlign: 3,
            codeVerticalAlign: 9,
            genQrs: true,
            horizontalGap: 2,
            verticalGap: 2,
            pageMarginLeft: 27.5,
            pageMarginTop: 10,
            pageMarginRight: 10,
            pageMarginBottom: 10,
            pageWidth: 430,
            pageHeight: 290,
            customImageUrl:
              "https://ajudapet-imgs-prod.s3.amazonaws.com/63fc935eca42707ab0d343f4/Osso%20Toh%20P.pdf",
            customHeight: 15,
            repetitions: 1,
          }}
          form={form}
          labelCol={{ span: 24 }}
          onFinish={generatePlate}
        >
          <Select
            placeholder={"Selecionar configuração"}
            style={{ width: "100%" }}
            options={configs.map((e) => ({ label: e.title, value: e._id }))}
            onChange={(e) => {
              form.setFieldsValue(configs.find((config) => config._id === e));
            }}
          />
          <Form.Item label="Titulo" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Cor da fonte do Código" name="codeFontCMYKColor">
            <Input />
          </Form.Item>
          <Form.Item name="customQuantity" label="Quantidade de Placas">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="customWidth" label="Largura da Arte">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="customHeight" label="Altura da Arte da Arte">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="qrSlimRange" label="Espessura do QRCode">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="qrcodeSize" label="Tamanho do QRCode">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="qrCodeVerticalAlign"
            label="Distância entre o topo da arte e o inicio do qr Code (em milímetros)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="qrCodeHorizontalAlign"
            label=" Distância entre da esquerda da arte e o inicio do qr Code (em milímetros)"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="codeFontSize" label="Tamanho da fonte">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="codeKerning"
            label="Diatância entre as letras do código"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="codeLetterThickening"
            label="Fator de engrossamento da letra do código"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label={"Fonte"} name={`codeFont`}>
            <Select
              options={[
                {
                  value: "RobotoMono-ExtraLight",
                  label: "ExtraLight",
                },
                {
                  value: "RobotoMono-Light",
                  label: "Light",
                },
                {
                  value: "RobotoMono-Regular",
                  label: "Regular",
                },
                {
                  value: "RobotoMono-Medium",
                  label: "Medium",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="codeHorizontalCompress"
            label=" Tamanho a ser retirado da largura total do texto código "
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="codeVerticalCompress"
            label="Tamanho a ser retirado da altura total do texto código"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="codeHorizontalAlign"
            label="Distância entre a esquerda da arte e o inicio do código da arte"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="codeVerticalAlign"
            label=" Distância entre o topo da arte e o inicio do código da arte"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label={"Gerar QRCodes?"} name={`genQrs`}>
            <Select
              options={[
                {
                  value: true,
                  label: "Sim",
                },
                {
                  value: false,
                  label: "Não",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="horizontalGap"
            label="Distancia entre uma coluna e outra"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="verticalGap"
            label="Distancia entre uma linha e outra"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="pageMarginLeft" label="Margem da página a Esquerda">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="pageMarginTop" label="Margem da página ao Topo">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="pageMarginRight" label="Margem da página a Direita">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="pageMarginBottom" label="Margem da página a baixo">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="pageWidth" label="Largura da Pagina">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="pageHeight" label="Altura da coleira">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <UploadInput
            label="Arte do PDF"
            onChange={(e) => {
              form.setFieldValue("customImageUrl", e[0].url);
            }}
          ></UploadInput>
          <Form.Item name="repetitions" label="Nº repetições">
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="customImageUrl">
            <Input type="hidden" style={{ width: "100%" }} />
          </Form.Item>
          <Button htmlType="submit">{"Criar"} PDF Placa</Button>{" "}
          <Button
            onClick={async () => {
              await saveConfig(form.getFieldsValue());
              setConfigs(await loadPlatesConfigs());
            }}
          >
            Salvar Configuração
          </Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};

export default CreatePlate;

/** {
            title: "Teste",
            customWidth: 27,
            qrSlimRange: 0,
            qrcodeSize: 6.185,
            qrCodeVerticalAlign: 4.408,
            qrCodeHorizontalAlign: 18.293,
            codeFontSize: 4,
            codeKerning: 0,
            codeLetterThickening: 0,
            codeFont: "RobotoMono-Regular",
            codeHorizontalCompress: 0,
            codeVerticalCompress: 0,
            codeHorizontalAlign: 3,
            codeVerticalAlign: 9,
            genQrs: true,
            horizontalGap: 2,
            verticalGap: 2,
            pageMarginLeft: 27.5,
            pageMarginTop: 10,
            pageMarginRight: 10,
            pageMarginBottom: 10,
            pageWidth: 430,
            pageHeight: 290,
            customImageUrl:
              "https://ajudapet-imgs-prod.s3.amazonaws.com/63fc935eca42707ab0d343f4/Osso%20Toh%20P.pdf",
            customHeight: 15,
          } */
