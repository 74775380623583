import { useAPITag } from "../../services/api";

export const useGetAddress = () => {
  const { http } = useAPITag();

  const run = async (zipCode) => {
    const response = await http.get(`zipCode/${zipCode}`);
    return response;
  };

  return run;
};
