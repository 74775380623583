import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as S from './styles';
import { ptBR } from 'date-fns/locale';
import { Controller } from 'react-hook-form';
import { CalendarOutlined } from '@ant-design/icons';

registerLocale('pt-BR', ptBR);

const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
  <S.StyledInputWrapper ref={ref} onClick={onClick}>
    <input value={value} readOnly placeholder={placeholder} />
    <CalendarOutlined className="calendar-icon" />
  </S.StyledInputWrapper>
));

const CustomDatePicker = ({ control, name }) => {
  return (
    <S.StyledDatePickerWrapper>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, name, ref } }) => (
          <ReactDatePicker
            onChange={onChange}
            selected={value ? new Date(value) : null}
            dateFormat="dd/MM/yyyy"
            className="custom-datepicker"
            locale="pt-BR"
            customInput={<CustomInput placeholder="Selecione uma data"/>}
          />
        )}
      />
    </S.StyledDatePickerWrapper>
  );
};

export default CustomDatePicker;
