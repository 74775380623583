import { Button, Dropdown, QRCode, Row, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DownloadOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import ShippmentAPI from "./ShippmentAPI";

const MySwal = withReactContent(Swal);

export const MyShippments = () => {
  const [shippments, setShippments] = useState([]);
  const { getShippments, download } = ShippmentAPI();
  const navigate = useNavigate();
  useEffect(() => {
    loadShippments();
  }, []);

  const columns = [
    {
      title: "Titulo",
      render: (row) => `${row.title}`,
    },
    {
      title: "Data de Criação",
      render: (row) => `${new Date(row.createdAt).toLocaleString("pt-BR")}`,
    },

    {
      title: "Download",
      render: (row, index, column, i) => {
        return (
          <Button
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (row.downloadCount) {
                Swal.fire({
                  title: `Você já realizou download desse arquivo ${
                    row.downloadCount
                  } ${
                    row.downloadCount > 1 ? "vezes" : "vez"
                  }, deseja mesmo baixar esse arquivo novamente?`,
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: "Baixar",
                  denyButtonText: `Não Baixar`,
                }).then(async (result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    download(row._id);
                  } else if (result.isDenied) {
                  }
                });
                return;
              }

              download(row._id);
            }}
          >
            <DownloadOutlined style={{ fontSize: "25px" }} />
          </Button>
        );
      },
    },
  ];

  const loadShippments = async () => {
    const response = await getShippments();
    setShippments(response);
  };

  return (
    <Dashboard selected="9">
      <S.Wrapper>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={shippments}
        />
      </S.Wrapper>
    </Dashboard>
  );
};
