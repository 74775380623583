import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

const LeadApi = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const getLeads = async () => {
    try {
      const response = await http.get("/lead");
      return response.data;
    } catch (error) {}
  };

  return {
    getLeads,
  };
};

export default LeadApi;
