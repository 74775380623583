import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getAdminId } from "../login/loginSlice";

const GabaritAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminId = useSelector(getAdminId);

  const getColourGroups = async () => {
    try {
      const response = await http.get("collar-preset/color-groups/" + adminId);

      return response.data;
    } catch (error) {}
  };

  const getCollarSizes = async () => {
    try {
      const response = await http.get("/collar-size");
      return response.data;
    } catch (error) {}
  };

  const getCollarTypes = async () => {
    try {
      const response = await http.get("/collar-types");

      const removeDuplicatesByTitle = (arr) => {
        const titleSet = new Set();
        const uniqueArray = [];

        for (const item of arr) {
          if (!titleSet.has(item.title)) {
            titleSet.add(item.title);
            uniqueArray.push(item);
          }
        }

        return uniqueArray;
      };
      return removeDuplicatesByTitle(response.data);
    } catch (error) {}
  };

  const getRequiredAssets = async () => {
    try {
      const response = await http.get("/collar-assets");

      return response.data;
    } catch (error) {}
  };
  const getGabaritData = async () => {
    const data = await Promise.all([
      getCollarTypes(),
      getCollarSizes(),
      getColourGroups(),
      getRequiredAssets(),
    ]);
    return {
      types: data[0],
      sizes: data[1],
      colors: data[2],
      requiredAssets: data[3],
    };
  };

  const createGabarit = async (data) => {
    try {
      const response = await http.post("services/gabarit", data);
      return response.data;
    } catch (error) {}
  };

  return {
    getGabaritData,
    createGabarit,
  };
};

export default GabaritAPI;
