import React, { useEffect } from "react";
import * as S from "./styles";

const Modal = ({ isOpen, onClose, videoId }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <S.ModalBackdrop onClick={onClose}>
      <S.ModalContent onClick={(e) => e.stopPropagation()}>
        <S.CloseButton onClick={onClose}>&times;</S.CloseButton>
        <S.YoutubeFrame
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          allowFullScreen
        ></S.YoutubeFrame>
      </S.ModalContent>
    </S.ModalBackdrop>
  );
};

export default Modal;
