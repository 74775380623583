import Swal from "sweetalert2";
import { useSignUp } from "../../../data/usecases/use-signup";
import { useAPI } from "../../../services/api";
import { redirect, useNavigate } from "react-router-dom";

export const SignUp = (data) => {
  const register = useSignUp();
  const navigate = useNavigate();
  const handle = async (data) => {
    try {
      data.cnpj = data.cnpj.replace(/\D/g, "");
      data.telefone = data.telefone.replace(/\D/g, "");
      data.cor_primaria = "#00000";
      data.cor_secundaria = "#00000";
      data.complemento = "";
      data.rua = data.logradouro;
      delete data.logradouro;
      data.logo = "";

      await register(data);
      Swal.fire({
        title: "Você receberá um email com os dados de acesso",
        icon: "success",
      });

      navigate("/");
    } catch (e) {
      Swal.fire({ title: "Email já cadastrado!", icon: "error" });
    }
  };

  return handle;
};
