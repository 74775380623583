import Title from "../../../../components/Title";
import SubTitle from "../../../../components/Subtitle";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { ENUM_PAYMENT } from "../../../../context/DonationContext";
import { ContainerInput, Input, Label } from "../styles";
import * as S from "./styles";
import { DoarAPI } from "../doarAPI";
import {
  getCustomLink,
  getDonationValue,
  getSelectedPaymentMethod,
} from "../../../donate.slice";
import { useSelector } from "react-redux";
import { createDonnor } from "../../../services/donnor-client";
import { getDoneeByCustomLink } from "../../../services/donee-client";
import { registerDonation } from "../../../services/donation-client";

const BillinDetails = ({
  onDonation,
  onStep,
  step,
  billinDetails,
  onBillinDetails,
}) => {
  const donationValue = useSelector(getDonationValue);

  const typePayment = useSelector(getSelectedPaymentMethod);
  const customLink = useSelector(getCustomLink);
  const { register, handleSubmit } = useForm();
  const { changeDonationValue, changePayer, goToStep } = DoarAPI();
  // const onSelect = (type) => {};

  const onSubmit = async (data) => {
    changePayer(data);

    const doneeByEntityId = await getDoneeByCustomLink(customLink);

    const bodyDonnor = {
      value: String(donationValue),
      name: data.name,
      surName: data.lastname,
      email: data.email,
      cpf: data.cpf,
      entityIdDonee: doneeByEntityId.data?.entityId,
      typePayment: ENUM_PAYMENT[typePayment],
    }

    const responseDonnor = await createDonnor(bodyDonnor);

    const bodyDonation = {
      donnor: responseDonnor?._id,
      donee: doneeByEntityId.data?._id,
      value: String(donationValue),
      confirmed: false,
    }

    await registerDonation(bodyDonation, responseDonnor._id);
    goToStep("/pagamento-pix");
  };

  return (
    <>
      <S.SectionTitle>
        <Title>Detalhes do faturamento</Title>
        <S.WrapperValueDonation>
          <S.SectionInfo>
            <S.SubTitleSection>Valor a ser doado</S.SubTitleSection>
            <S.WrapperDataInfos>
              <S.DonationTag>{donationValue}</S.DonationTag>
              <S.LinkChange
                onClick={() => changeDonationValue("/detalhes-pagamento")}
              >
                Alterar
              </S.LinkChange>
            </S.WrapperDataInfos>
          </S.SectionInfo>
          <S.SectionInfo>
            <S.SubTitleSection>Forma de pagamento</S.SubTitleSection>
            <S.WrapperDataInfos>
              <S.DonationTag>{ENUM_PAYMENT[typePayment]}</S.DonationTag>
              <S.LinkChange onClick={() => goToStep("/selecionar-pagamento")}>
                Alterar
              </S.LinkChange>
            </S.WrapperDataInfos>
          </S.SectionInfo>
        </S.WrapperValueDonation>
      </S.SectionTitle>
      <S.SectionDetails>
        <SubTitle>Detalhes de faturamento</SubTitle>
        <S.Form>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>Nome</Label>
              <Input
                name="name"
                placeholder="Insira seu nome"
                control
                {...register("name")}
              />
            </ContainerInput>
          </S.WrapperInputs>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>Sobrenome</Label>
              <Input
                name="lastname"
                placeholder="Insira seu sobrenome"
                control
                {...register("lastname")}
              />
            </ContainerInput>
          </S.WrapperInputs>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>Email</Label>
              <Input
                name="email"
                placeholder="Insira seu email"
                control
                {...register("email")}
              />
            </ContainerInput>
          </S.WrapperInputs>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>CPF</Label>
              <Input
                name="cpf"
                placeholder="insira seu CPF"
                as={InputMask}
                mask="999.999.999-99"
                control
                {...register("cpf")}
              />
            </ContainerInput>
          </S.WrapperInputs>
          <S.WrapperButton>
            <S.SubmitButton fullWidth onClick={handleSubmit(onSubmit)}>
              Avançar
            </S.SubmitButton>
            <S.Description>
              AjudaPet Soluções para a Causa Animal© Copyright - Todos os
              direitos reservados.2021 CNPJ: 31.038.058/0001-58
            </S.Description>
          </S.WrapperButton>
        </S.Form>
      </S.SectionDetails>
    </>
  );
};

export default BillinDetails;
