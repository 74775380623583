import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch } from "react-redux";

const CollarAssetApi = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteCollarAsset = (id) => {
    try {
      const response = http.delete("collar-assets/" + id);

      navigate("/admin/collar-assets");
    } catch (error) {}
  };

  const createCollarAsset = (e) => {
    try {
      const response = http.post("collar-assets", e);

      navigate("/admin/collar-assets");
    } catch (error) {}
  };

  const updateCollarAsset = (id) => (e) => {
    try {
      const response = http.patch("collar-assets/" + id, e);

      navigate("/admin/collar-assets");
    } catch (error) {}
  };

  const getCollarAssets = async () => {
    try {
      const response = await http.get("/collar-assets");
      return response.data;
    } catch (error) {}
  };

  const findCollarAsset = async (id) => {
    try {
      const response = await http.get("/collar-assets/" + id);

      return response.data;
    } catch (error) {}
  };

  return {
    deleteCollarAsset,
    createCollarAsset,
    findCollarAsset,
    updateCollarAsset,
    getCollarAssets,
  };
};

export default CollarAssetApi;
