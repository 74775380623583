import React, { useState } from "react";

import * as S from "./styles";
import FormConfigUser from "../../components/FormConfigUser";
import { steps, stepsSelect } from "./steps";
import { Select, Button, Col } from "antd";
import Header from "../../components/Header";
import ArrowLeftIcon from "../../components/Icons/ArrowLeft";

const UserConfig = () => {
  const [menuItem, setMenuItem] = useState("perfil");

  const handleChange = (event) => {
    setMenuItem(event.value);
  };

  const Backpage = () => {
    window.history.back();
  };
  
  return (
    <S.Wrapper>
      <Header />
      <div className="lg:mt-24 lg:w-[1000px] lg:mx-auto lg:bg-white rounded-tl-xl rounded-bl-xl">
      <div className="w-full lg:flex p-4 hidden">
        <Button onClick={Backpage} className="w-12 bg-orange-400 text-white text-3xl flex items-center justify-center">
          <ArrowLeftIcon />
        </Button>

      </div>
        <S.WrapperForm>
          <S.Aside>
              <S.Header className="-mt-4">Configurações</S.Header>
              <S.WrapperSelect>
                <Select
                  style={{ borderRadius: 30, width: "327px", color: "black" }}
                  className="-mt-2"
                  options={stepsSelect}
                  labelInValue
                  defaultValue="perfil"
                  onChange={(event) => handleChange(event)}
                />
              </S.WrapperSelect>
            <S.Content>
              {steps.map((item, index) => {
                return (
                  <S.WraperMenu
                    key={index}
                    active={item.item === menuItem}
                    onClick={() => setMenuItem(item.item)}
                  >
                    {item.icon}
                    <S.TextMenu>{item.title}</S.TextMenu>
                  </S.WraperMenu>
                );
              })}
            </S.Content>
          </S.Aside>
          <FormConfigUser step={menuItem} />
        </S.WrapperForm>
      </div>
    </S.Wrapper>
  );
};

export default UserConfig;