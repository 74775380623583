import { Button, Dropdown, QRCode, Row, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import CompaniesAPI from "./CompaniesAPI";

const MySwal = withReactContent(Swal);

export const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const { getAllCompanies, deleteCompany } = CompaniesAPI();
  const navigate = useNavigate();
  useEffect(() => {
    const loadCompany = async () => {
      const response = await getAllCompanies();

      setCompanies(response);
    };

    loadCompany();
  }, []);

  const columns = [
    {
      title: "Nome",
      render: (row) => `${row.razao_social}`,
    },

    {
      title: "email",
      render: (row) => `${row.email}`,
    },
    {
      title: "Data de criação",
      render: (row) => `${new Date(row.createdAt).toLocaleDateString("pt-BR")}`,
    },

    {
      title: "Ações",
      render: (row, index, column, i) => {
        let items = [
          {
            key: "actions_1_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  navigate("/admin/companies/" + row._id);
                }}
              >
                Editar
              </Button>
            ),
          },
          {
            key: "actions_2_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  Swal.fire({
                    title: "Deseja mesmo deletar essa empresa?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Deletar",
                    denyButtonText: `Não Deletar`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      deleteCompany(row._id);
                      companies.splice(index, 1);
                      setCompanies([...companies]);
                      Swal.fire("Empresa deletada com sucesso!", "", "success");
                    } else if (result.isDenied) {
                    }
                  });
                }}
              >
                Deletar
              </Button>
            ),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: [...items],
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Dashboard selected="10">
      <S.Wrapper>
        <Row>
          <Button
            onClick={() => {
              navigate("/admin/companies/create");
            }}
          >
            Criar nova empresa
          </Button>
        </Row>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={companies}
        />
      </S.Wrapper>
    </Dashboard>
  );
};
