import { useForm } from "react-hook-form";
import Box from "../../../components/Box";
import Title from "../../../components/Title";
import * as S from "./styles";
import { ContainerInput, Input, TextArea, Label } from "../styles";
import { MailOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { useState } from "react";
import CustomDatePicker from "../../../components/InputDatePicker/InputDatePicker";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomTimePicker from "../../../components/CustomTimePicker";

const NOTIFICATION_METHODS = {
  EMAIL: "EMAIL",
  WHATSAPP: "WHATSAPP",
};

const SchedulingPage = () => {
  const { register, setValue, watch, control } = useForm();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [selectedNotificationMethods, setSelectedNotificationMethods] =
    useState([]);

  const toggleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
    if (isSwitchOn) {
      setValue("reminderFrequency", "");
    }
  };

  const onSelectNotificationMethod = (method) => {
    setSelectedNotificationMethods((prevMethods) => {
      if (prevMethods.includes(method)) {
        return prevMethods.filter((m) => m !== method);
      } else {
        return [...prevMethods, method];
      }
    });
  };

  const frequency = watch("reminderFrequency");

  const reminderOptions = [
    { value: 'hourly', label: 'De horas em horas' },
    { value: 'daily', label: 'Diariamente' },
    { value: 'monthly', label: 'Mensalmente' },
    { value: 'annually', label: 'Anualmente' },
    { value: 'specificDate', label: 'Data específica' },
  ];

  return (
    <Box>
      <S.SectionTitle>
        <Title>Medicamentos</Title>
      </S.SectionTitle>

      <S.SectionDetails>
        <S.Form>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>Nome</Label>
              <Input
                name="name"
                placeholder="Escreva qual medicamento está dando!"
                control
                {...register("name")}
              />
            </ContainerInput>
          </S.WrapperInputs>
          <S.WrapperInputs>
            <ContainerInput>
              <Label>OBSERVAÇÕES</Label>
              <TextArea
                name="observations"
                placeholder="Escreva aqui tudo que você achar importante para em outro momento se lembrar!"
                control
                {...register("observations")}
              />
            </ContainerInput>
          </S.WrapperInputs>

          <S.WrapperInputs>
            <S.ContainerDatePicker>
              <Label>Início do tratamento</Label>
              <CustomDatePicker control={control} name="treatmentStart" />
            </S.ContainerDatePicker>
          </S.WrapperInputs>

          <S.SwitchLabelContainer>
            <Label>Criar Lembrete</Label>
            <S.SwitchContainer>
              <S.Switch>
                <S.Checkbox
                  type="checkbox"
                  checked={isSwitchOn}
                  onChange={toggleSwitch}
                />
                <S.Slider />
              </S.Switch>
            </S.SwitchContainer>
          </S.SwitchLabelContainer>

          {isSwitchOn && (
            <>
              <S.SectionTypePayment>
                <Label>Onde quer receber os lembretes?</Label>
                <S.CardTypePayment
                  onClick={() =>
                    onSelectNotificationMethod(NOTIFICATION_METHODS.WHATSAPP)
                  }
                  styled={
                    selectedNotificationMethods.includes(
                      NOTIFICATION_METHODS.WHATSAPP
                    )
                      ? "selected"
                      : undefined
                  }
                >
                  <S.IconTextContainer
                    styled={
                      selectedNotificationMethods.includes(
                        NOTIFICATION_METHODS.WHATSAPP
                      )
                        ? "selected"
                        : undefined
                    }
                  >
                    <WhatsAppOutlined style={{ fontSize: "25px" }} />
                    <p>Whatsapp</p>
                  </S.IconTextContainer>
                  <S.RadialIndicator
                    styled={
                      selectedNotificationMethods.includes(
                        NOTIFICATION_METHODS.WHATSAPP
                      )
                        ? "selected"
                        : undefined
                    }
                  />
                </S.CardTypePayment>
                <S.CardTypePayment
                  onClick={() =>
                    onSelectNotificationMethod(NOTIFICATION_METHODS.EMAIL)
                  }
                  styled={
                    selectedNotificationMethods.includes(
                      NOTIFICATION_METHODS.EMAIL
                    )
                      ? "selected"
                      : undefined
                  }
                >
                  <S.IconTextContainer
                    styled={
                      selectedNotificationMethods.includes(
                        NOTIFICATION_METHODS.EMAIL
                      )
                        ? "selected"
                        : undefined
                    }
                  >
                    <MailOutlined style={{ fontSize: "25px" }} />
                    <p>E-mail</p>
                  </S.IconTextContainer>
                  <S.RadialIndicator
                    styled={
                      selectedNotificationMethods.includes(
                        NOTIFICATION_METHODS.EMAIL
                      )
                        ? "selected"
                        : undefined
                    }
                  />
                </S.CardTypePayment>
              </S.SectionTypePayment>

              <S.WrapperInputs>
                <S.SelectContainer>
                  <Label>Quando quer receber os lembretes?</Label>
                  <CustomSelect
                    control={control}
                    name="reminderFrequency"
                    options={reminderOptions}
                  />
                </S.SelectContainer>
              </S.WrapperInputs>
            </>
          )}

          {isSwitchOn && frequency === "hourly" && (
            <>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>De quantas em quantas horas?</Label>
                  <Input
                    type="number"
                    {...register("hoursInterval")}
                    placeholder="Ex: 4 horas"
                  />
                </ContainerInput>
              </S.WrapperInputs>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Durante quantos dias?</Label>
                  <Input
                    type="number"
                    {...register("daysDuration")}
                    placeholder="Ex: 4 dias"
                  />
                </ContainerInput>
              </S.WrapperInputs>
            </>
          )}

          {isSwitchOn && frequency === "daily" && (
            <>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Durante quantos dias?</Label>
                  <Input
                    type="number"
                    {...register("daysDurationDaily")}
                    placeholder="Ex: 4 dias"
                  />
                </ContainerInput>
              </S.WrapperInputs>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Que horas quer ser lembrado?</Label>
                  <CustomTimePicker 
                    control={control}
                    name="reminderTimeDaily"
                    placeholder="Ex: 12:00"
                  />
                </ContainerInput>
              </S.WrapperInputs>
            </>
          )}

          {isSwitchOn && frequency === "monthly" && (
            <>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Durante quantos meses?</Label>
                  <Input
                    type="number"
                    {...register("monthsDuration")}
                    placeholder="Ex: 4 meses"
                  />
                </ContainerInput>
              </S.WrapperInputs>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Em Que dia do mês quer ser lembrado?</Label>
                  <Input
                    type="number"
                    {...register("dayOfMonth")}
                    placeholder="Ex: dia 25"
                  />
                </ContainerInput>
              </S.WrapperInputs>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Que horas quer ser lembrado?</Label>
                  <CustomTimePicker 
                    control={control}
                    name="reminderTimeMonthly"
                    placeholder="Ex: 12:00"
                  />
                </ContainerInput>
              </S.WrapperInputs>
            </>
          )}

          {isSwitchOn && frequency === "annually" && (
            <>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Durante quantos anos?</Label>
                  <Input
                    type="number"
                    {...register("yearsDuration")}
                    placeholder="Ex: 4 anos"
                  />
                </ContainerInput>
              </S.WrapperInputs>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Em Que data quer ser lembrado?</Label>
                  <Input
                    type="number"
                    {...register("reminderDateAnnually")}
                    placeholder="Ex: 25 de abril"
                  />
                </ContainerInput>
              </S.WrapperInputs>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Que horas quer ser lembrado?</Label>
                  <CustomTimePicker 
                    control={control}
                    name="reminderTimeAnnually"
                    placeholder="Ex: 12:00"
                  />
                </ContainerInput>
              </S.WrapperInputs>
            </>
          )}

          {isSwitchOn && frequency === "specificDate" && (
            <>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Data</Label>
                  <CustomDatePicker control={control} name="specificDate" />
                </ContainerInput>
              </S.WrapperInputs>
              <S.WrapperInputs>
                <ContainerInput>
                  <Label>Que horas quer ser lembrado?</Label>
                  <CustomTimePicker 
                    control={control}
                    name="reminderTimeSpecificDate"
                    placeholder="Ex: 12:00"
                  />
                </ContainerInput>
              </S.WrapperInputs>
            </>
          )}

          <S.WrapperButton>
            <S.SubmitButton
              fullWidth
              onClick={() => console.log("handleSubmit(onSubmit)")}
            >
              SALVAR
            </S.SubmitButton>
            <S.SubmitButton
              style={{
                width: "100%",
                border: "2px solid #E6E8EC",
                backgroundColor: "transparent",
                color: "#141416",
                marginBottom: "1rem",
              }}
              fullWidth
              onClick={() => console.log("goBack")}
              type="secondary"
            >
              Cancelar
            </S.SubmitButton>
          </S.WrapperButton>
        </S.Form>
      </S.SectionDetails>
    </Box>
  );
};

export default SchedulingPage;
