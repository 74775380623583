import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";

function downloadURI(uri, name) {
  var link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute("download", name);
  link.setAttribute("target", "_blank");
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();

  window.location.reload();
}

const ShippmentAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);
  const getAllShippments = async () => {
    try {
      const response = await http.get("/shippment");
      return response.data;
    } catch (error) {}
  };

  const getShippments = async () => {
    try {
      const response = await http.get("/shippment/" + authState.user._id);
      return response.data;
    } catch (error) {}
  };

  const download = async (id) => {
    try {
      const response = await http.get("/shippment/download/" + id);
      downloadURI(response.data.downloadUrl, response.data.title + ".pdf");
    } catch (error) {}
  };

  return {
    getShippments,
    getAllShippments,
    download,
  };
};

export default ShippmentAPI;
