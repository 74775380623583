import { MaskedInput } from "antd-mask-input";
import * as S from "./styles";
import { Form } from "antd";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import InputNew from "../../../components/InputNew";
import { GeolocationApi } from "../../../components/geolocation/geolocationAPI";
import { set } from "react-hook-form";
import SimulatorAPI from "../simulator.api";

export const RegisterUserSimulated = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { translate } = GeolocationApi();
  const { loadSimulation, registerLead } = SimulatorAPI();
  const [inputDisabled, setInputDisabled] = useState(true);
  const [address, setAddress] = useState({});

  useEffect(() => {
    const loadSim = async () => {
      loadSimulation(id);
    };

    loadSim();
  }, []);
  const onFinishFailed = () => {
    Swal.fire({
      title: "Preencha todos os campos",
      icon: "error",
    });
  };

  useEffect(() => {
    const loadLocation = async () => {
      const randomLat = -3.71722 + Math.random() * (4.67711 + 3.71722);
      const randomLng = -73.72758 + Math.random() * (34.7931 + 73.72758);
      const address = await translate(randomLat, randomLng);
      setAddress(address);
      console.log(address);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const address = await translate(
            position.coords.latitude,
            position.coords.longitude
          );
          setAddress(address);
        },
        async (error) => {
          if (
            error.code === error.PERMISSION_DENIED ||
            error.code === error.POSITION_UNAVAILABLE
          ) {
            // User refused geolocation or position is unavailable, set random lat and lng for Brazil
            const randomLat = -3.71722 + Math.random() * (4.67711 + 3.71722);
            const randomLng = -73.72758 + Math.random() * (34.7931 + 73.72758);
            const address = await translate(randomLat, randomLng);
            setAddress(address);
          } else {
            console.log(error);
          }
        }
      );
    };

    loadLocation();
  }, []);

  const onChange = (e) => {
    if (e.unmaskedValue.length > 10) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  };

  return (
    <RegisterPetLayout
      hasHeader
      description="Insira suas informações para reconhecermos você como tutor(a) do pet."
      title="Preencha seus dados"
      size={5}
      step={3}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ width: "80%" }}
        initialValues={{ remember: true }}
        onFinish={(values) => {
          registerLead(id)({ ...values, address });
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="nome"
          label="Nome"
          rules={[{ required: true, message: "Informe o seu nome" }]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          name="sobrenome"
          label="Sobrenome"
          rules={[{ required: true, message: "Informe o seu sobrenome" }]}
        >
          <S.StyledInput />
        </Form.Item>
        <InputNew
          name="telefone"
          label="Telefone celular"
          mask="(00) 0000-00000"
        />
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: "Informe o seu email", type: "email" },
          ]}
        >
          <S.StyledInput />
        </Form.Item>
      </Form>

      <S.SubmitButton
        onClick={() => form.submit()}
        type="primary"
        htmlType="submit"
      >
        Avançar
      </S.SubmitButton>
    </RegisterPetLayout>
  );
};
