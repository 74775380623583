import { useEffect, useState } from "react";
import Title from "../../../../components/Title";
import Description from "../../../../components/Description";
import { useNavigate } from "react-router-dom";
import { requestPix } from "../../../services/payment-client";
import * as S from "./styles";
import { useSelector } from "react-redux";
import { getDonationPayer, getDonationValue } from "../../../donate.slice";

const PixPayment = ({
  onStep,
  step,
  changePayment,
  typePayment,
  onBillinDetails,
}) => {
  const navigate = useNavigate();
  const [pix, setPix] = useState({});
  const billinDetails = useSelector(getDonationPayer);
  const donationValue = useSelector(getDonationValue);
  const onSelect = (type) => {
    changePayment(type);
  };
  // const [copied, setCopied] = useState(false)

  // const onCopy = async () => {
  //   await navigator.clipboard.writeText(pix.transaction_data.qr_code)
  //   setCopied(true)
  // }

  const onPix = async () => {
    const payload = {
      docNumber: billinDetails.cpf,
      docType: "CPF",
      payerEmail: billinDetails.email,
      payerFirstName: billinDetails.name,
      payerLastName: billinDetails.lastName,
      transactionAmount: donationValue,
    };

    try {
      const response = await requestPix(payload);
      console.log(response);
      setPix(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onPix();
  }, []);

  function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return prompt("Copy to clipboard: Ctrl+C, Enter", text);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  return (
    <>
      <S.WrapperTitle>
        <Title>Pague com PIX</Title>
        <Description>
          Falta pouco! Escaneie o código QR pelo seu app de pagamentos ou
          Internet Banking
        </Description>
      </S.WrapperTitle>
      <S.SectionValue>
        <S.TitleValue>Valor a ser doado</S.TitleValue>
        <S.Value>{`R$${donationValue}`}</S.Value>
      </S.SectionValue>
      <S.WrapperQrCode>
        <S.BoxQrCodeImg>
          {!pix.response && <p>carregando..</p>}
          {pix.response && (
            <S.QrCodeImg
              src={`data:image/jpeg;base64,${pix.response.point_of_interaction.transaction_data.qr_code_base64}`}
            />
          )}
        </S.BoxQrCodeImg>
        <Description>Código válido por 24 horas</Description>
      </S.WrapperQrCode>
      <S.WrapperCopy>
        <Description>
          Se preferir, você pode pagá-lo copiando e colando o código abaixo:
        </Description>
        <S.BoxCopy>
          <S.InputNone
            readOnly
            value={
              pix.response
                ? pix.response.point_of_interaction.transaction_data.qr_code
                : ""
            }
          />
          <S.Copy
            onClick={() => {
              copyToClipboard(
                pix.response.point_of_interaction.transaction_data.qr_code
              );
            }}
          >
            Copiar
          </S.Copy>
        </S.BoxCopy>
        <S.WrapperLink>
          <S.Link onClick={() => navigate("/")}>Ir para o site AjudaPet</S.Link>
          <S.Description>
            AjudaPet Soluções para a Causa Animal© Copyright - Todos os direitos
            reservados.2021 CNPJ: 31.038.058/0001-58
          </S.Description>
        </S.WrapperLink>
      </S.WrapperCopy>
    </>
  );
};

export default PixPayment;
