import * as S from './styles'

const Avatar = ({ imageUrl = undefined }) => {

  const imageDefault = imageUrl !== undefined ? imageUrl : 'https://img.freepik.com/vetores-gratis/avatar-de-personagem-de-empresario-isolado_24877-60111.jpg?w=2000'
  return (
    <S.AvatarImage src={imageDefault} alt='avatar-user' />
  )
}

export default Avatar
