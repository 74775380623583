import styled from "styled-components";

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 90%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  background: white;
  border: 2px solid #ff6e30;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.2em;
  cursor: pointer;
  color: #ff6e30;
  line-height: 0.8;

  @media (max-width: 480px) {
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
  }
`;

export const YoutubeFrame = styled.iframe`
  width: 100%;
  height: 415px;
  border: 0;

  @media (max-width: 480px) {
    height: 235px;
  }
`;
