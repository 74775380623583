import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDonationFrequency,
  getPreviuosStep,
  selectPaymentMethod,
  setCustomLinkValue,
  setDonationFrequency,
  setDonationValue,
  setPayer,
  setPreviousStep,
} from "../../donate.slice";

export const DoarAPI = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const donationFrequency = useSelector(getDonationFrequency);
  const previousDonationStep = useSelector(getPreviuosStep);
  const changeDonationValue = (previousStep) => {
    dispatch(setPreviousStep(previousStep));
    navigate("/doar");
  };

  const setCustomLink = (customLink) => {
    dispatch(setCustomLinkValue(customLink));
  }

  const changePayer = (payer) => {
    dispatch(setPayer(payer));
  };

  const changeDonationFrequency = (frequency) => {
    dispatch(setDonationFrequency(frequency));
  };

  const setDonation = (value) => {
    value = value.replace(",", ".");
    dispatch(setDonationValue(Number(value)));
    navigate(previousDonationStep);
  };

  const changePayment = (type) => {
    dispatch(selectPaymentMethod(type));
  };

  const goToStep = (step) => {
    navigate(step);
  };

  return {
    setDonation,
    changePayment,
    changeDonationValue,
    goToStep,
    changePayer,
    changeDonationFrequency,
    donationFrequency,
    setCustomLink,
  };
};
