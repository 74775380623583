import Styled from "styled-components";
import * as DescriptionStyles from "../../../../components/Description/styles";

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const WrapperQrCode = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;

  ${DescriptionStyles.Description} {
    font-weight: 400;
  }
`;

export const WrapperKeyPix = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const SectionValue = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const TitleValue = Styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #23262F;
`;

export const Value = Styled.span`
  font-size: 18px;
  line-height: 24px;
  color: #6F767E;
`;

export const WrapperTitle = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;

  @media (max-width: 480px) {
    margin-top: 40px;

    }
`;

export const BoxQrCodeImg = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 148px;
  background: gray;
  margin-top: 13px;

  p {
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const QrCodeImg = Styled.img`
  max-width: 148px;
  width: 100%;
  height: 100%;
`;

export const WrapperCopy = Styled.div`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  height: 48px;
  margin-top: 20px;
  
  gap: 20px;
`;

export const BoxCopy = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 2px solid #E6E8EC;
  width: 100%;
  height: 100%;

  border-radius: 12px;
  padding: 12px 16px;

`;
export const Copy = Styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #F67E48;
  cursor: pointer;
  text-transform: capitalize;
`;

export const InputNone = Styled.input`
  border: none;
  color: #B1B5C4;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  font-weight: 500;
`;

export const WrapperLink = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Description = Styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #b1b5c3;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const Link = Styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: #F67E48;
  cursor: pointer;
`;
