import styled from "styled-components";

export const StyledWrapper = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 12px 16px;
    border: 2px solid #e6e8ec;
    border-radius: 8px;
    background-color: white;
    font-size: 16px;
    color: #333;
    box-shadow: none;

    &:focus {
      border-color: #f67e48;
      outline: none;
    }
  }

  .react-datepicker__time-list-item--selected {
    background-color: #f67e48 !important;
    color: white !important;
  }

  .react-datepicker__time-list-item:hover {
    background-color: #faa07d !important;
    color: white !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    background-color: white;
  }

  .react-datepicker__time-list-item {
    height: auto;
    padding: 8px 10px;
  }
`;
