import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  margin-left: 1rem;
  color: #23262f;
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  margin-left: 1rem;
  margin-top: 0;
  align-items: center;
  color: #777e91;
`;
