import { useForm } from "react-hook-form";
import BoxDonate from "../../../../components/BoxDonate";
import Title from "../../../../components/Title";
import SubTitle from "../../../../components/Subtitle";
import Description from "../../../../components/Description";
import ButtonValue from "../../../../components/ButtonValue";
import { formatMoney } from "../../../utils/formatMoney";

import * as S from "./styles";
import { DoarAPI } from "../doarAPI";
import { useSelector } from "react-redux";
import { getDonationValue } from "../../../donate.slice";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const DONATION_OPTIONS = [1, 10, 25, 50, 100, 500];

const ValueDonation = () => {
  const { register, setValue, watch } = useForm();
  const donationValue = useSelector(getDonationValue);
  const { setDonation, setCustomLink } = DoarAPI();
  const { username } = useParams();

  useEffect(() => {
    setValue("customLink", username)
  }, []);
  
  const onSubmit = () => {
    setDonation(watch("money"));
    setCustomLink(watch("customLink"));
  };

  return (
    <BoxDonate>
      <S.SectionTitle>
        <Title>Informe um valor</Title>
        <Description>
          Aqui você pode informar um valor que se sentir confortável, para
          ajudar uma causa animal da sua escolha
        </Description>
      </S.SectionTitle>
      <S.SectionDonation>
        <SubTitle>Digite o valor que deseja doar.</SubTitle>
        <S.WrapperInput>
          <S.InputStyled
            decimalsLimit={2}
            onValueChange={(value) => setValue("money", value)}
            placeholder="0,00"
            prefix="R$"
            decimalSeparator=","
            value={donationValue}
            groupSeparator="."
            {...register("money")}
          />
        </S.WrapperInput>
      </S.SectionDonation>

      <S.WrapperOptionsValues>
        {DONATION_OPTIONS.map((option, index) => (
          <ButtonValue
            key={index}
            onClick={() => setValue("money", formatMoney(option))}
          >
            {option}
          </ButtonValue>
        ))}
      </S.WrapperOptionsValues>
      <S.WrapperButton>
        <S.ButtonSubmit fullWidth onClick={() => onSubmit()}>
          Avançar
        </S.ButtonSubmit>
        <S.Description>
          AjudaPet Soluções para a Causa Animal© Copyright - Todos os direitos
          reservados.2021 CNPJ: 31.038.058/0001-58
        </S.Description>
      </S.WrapperButton>
    </BoxDonate>
  );
};

export default ValueDonation;
