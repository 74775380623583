import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAPI, useAPIPet, useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  authUser,
  saveState,
  selectRegisterstate,
  setCode,
  setPhone,
} from "../RegisterPetSlice";
import { authCustomer, setUser } from "../../../app/store/authSlice";

const RegisterUserAPI = () => {
  const { http } = useAPITag();
  const registerState = useSelector(selectRegisterstate);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const CreateUser = async (data) => {
    try {
      if (!data.terms) {
        Swal.fire({ title: "Por favor, aceite os termos", icon: "error" });

        return;
      }
      const response = await http.post("/user", {
        ...data,
        companyId: registerState.config.companyId,
        telefone: registerState.phone,
      });

      if (!registerState.phone) {
        dispatch(authCustomer({ user: response.data, token: response.data }));

        navigate("/config-user");
      } else {
        dispatch(authCustomer({ user: response.data, token: response.data }));
        dispatch(saveState());

        navigate("/register-pet");
      }
    } catch (error) {
      const { isConfirmed: value } = await Swal.fire({
        title: "E-mail já registrado",
        text: "Deseja usar esse E-mail? se sim, enviaremos um código de confirmação para o mesmo!",
        showConfirmButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCancelButton: true,
      });

      if (value) {
        navigate("/confirm-email");
      }
    }
  };

  return { CreateUser };
};

export default RegisterUserAPI;
