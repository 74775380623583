import React, { forwardRef } from 'react'
import * as S from './styles'

const Button = (
  {
    children,
    icon,
    fullWidth = false,
    minimal = false,
    isLoading = false,
    color = 'primary',
    ...props
  },
  ref
) => (
  <S.Wrapper>
    <S.Button
      fullWidth={fullWidth}
      minimal={minimal}
      isLoading={isLoading}
      color={color}
      ref={ref}
      {...props}
    >
      <S.WrapperText>
        {!!children && <span>{children}</span>}
      </S.WrapperText>
    </S.Button>
  </S.Wrapper>
)

export default forwardRef(Button)
