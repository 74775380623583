import { Button, Dropdown, QRCode, Row, Statistic, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import DashboardAPI from "./DashboardAPI";

const MySwal = withReactContent(Swal);

export const DashPage = () => {
  const { loadDashData } = DashboardAPI();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const loadData = async () => {
      setData(await loadDashData());
    };
    loadData();
  }, []);

  return (
    <Dashboard selected="0">
      <S.Wrapper>
        <S.StyledCard hoverable>
          <Statistic title="Coleiras ativas" value={data.tagsActive} />
        </S.StyledCard>
        <S.StyledCard hoverable>
          <Statistic title="Coleiras Geradas" value={data.tags} />
        </S.StyledCard>
        <S.StyledCard hoverable>
          <Statistic title="Usuários ativos" value={data.users} />
        </S.StyledCard>
        <S.StyledCard hoverable>
          <Statistic title="Pets cadastrados" value={data.pets} />
        </S.StyledCard>
      </S.Wrapper>
    </Dashboard>
  );
};
