import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { Col } from "antd";

export const Form = styled.form``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
  border-radius: 12px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 25px;
  border-radius: 12px;
`;

export const Box = styled.div`
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 12px;
  background-color: #fff;
`;

export const WrapperA4 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 248px;
  height: 350px;
  background-color: red;
  padding: 5px;
`;

export const CollarsDog = styled.div`
  width: 24px;
  height: 100px;
  background-color: blue;
`;

export const Error = styled.p`
  font-size: 12px;
  color: #d93f21;
  margin-bottom: -30px;
`;

export const Link = styled(RouterLink)`
  max-width: 448px;
  font-size: 14px;
  line-height: 20px;
  color: #6d6e70;
`;
export const LinkBlue = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.aquaBlue};
    font-weight: 400;
  `}
`;

export const UploadRapportWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  label {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  }

  -webkit-box-shadow: 0px 0px 9px 1px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 0px 9px 1px rgba(214, 214, 214, 1);
  box-shadow: 0px 0px 9px 1px rgba(214, 214, 214, 1);
  padding: 1rem;
  border-radius: 15px;
`;
