import * as S from './styles'

const ButtonValue = ({children, ...props}) => {
  const valueFormating = children.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  return (
    <S.Card {...props}>
      {valueFormating}
    </S.Card>
  )
}

export default ButtonValue;