import Swal from "sweetalert2";
import { redirect, useNavigate } from "react-router-dom";
import useChangePassword from "../../../data/usecases/change-password-usecase";

export const ChangePassword = (data) => {
  const changePassword = useChangePassword();
  const navigate = useNavigate();
  const handle = async (data) => {
    try {
      await changePassword(data);
    } catch (e) {
      console.log(e);
      Swal.fire({ title: "Erro no servidor", icon: "error" });
    }
  };

  return handle;
};
