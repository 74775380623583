import styled from "styled-components";

export const Upload = styled.label`
  input[type="file"] {
    display: none;
  }

  margin-right: 1rem;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: block;
  width: 200px;
  .success {
    background: green !important;
    color: white !important;
  }
`;
