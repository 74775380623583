import { Button, Col, Input, InputNumber, Select } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 95%;
  background-color: white;
  padding: 0 2rem 0 2rem;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin-bottom: unset;
  }
`;

export const StyledInput = styled(Input)`
  height: 38px;
  border-radius: 8px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  width: "100%";

  border-radius: 8px;
`;

export const StyleInputNumber = styled(InputNumber)`
  min-height: 38px !important;
  width: "100%";
  border-radius: 8px;
  .ant-input-number-input-wrap {
    display: flex;
    align-items: center;
    min-height: 38px !important;
  }
`;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    min-height: 38px !important;
  }
`;

export const SubmitButton = styled(Button)`
  border-radius: 8px;
  height: 50px;
  width: 344px;
  margin-bottom: 15px;
  margin-top: 25px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;
export const PetWrapper = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  background: rgb(245, 245, 245);
  padding: 0.5rem;
  border-radius: 15px;
`;

export const PetTitleWrapper = styled.div`
  margin-top: 2rem;
`;

export const PetAvatar = styled.img`
  display: flex;
  width: 130px;
  height: 130px;
  object-fit: cover;
  @media screen and (max-width: 475px) {
    width: 80px;
    height: 80px;
  }
`;

export const PetTitle = styled.span`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  @media screen and (max-width: 475px) {
    font-size: 12px;
  }
  /* identical to box height, or 200% */

  /* Neutrals/2 */

  color: #23262f;
`;
