import styled from "styled-components";

import media from "styled-media-query";
import { Link } from "react-router-dom";

export const StyledHeadContainer = styled.header`
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 15px 1px rgba(200, 200, 200, 0.5);

  margin-top: 0;

  margin-bottom: ${(props) => (props.isMobile ? "5px" : "15px")};
  height: ${(props) => (props.isMobile ? "52px" : "82px")};
`;

export const StyledGestorPetHead = styled.header`
  padding: 0 32px 0 32px;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? "15px" : "0")};

  margin-bottom: ${(props) => (props.isMobile ? "5px" : "15px")};
  height: ${(props) => (props.isMobile ? "52px" : "82px")};
`;

export const StyledHeadLogo = styled.img`
  max-width: 260px;
`;
export const StyledHeadButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  height: 100%;
  background: transparent;
  border: none;
  color: #777e90;
  font-size: 14px;
  white-space: nowrap;
  text-transform: capitalize;
  text-decoration: none;

  &:hover:not(:has(> svg)) {
    box-shadow: inset 0 -2px 0 #3c3f48;
    color: #3c3f48;
    opacity: 75%;
  }
`;

export const WrapperLogo = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 18px;
  margin-left: 2rem;
`;

export const WrapperSectionMenu = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 40px;
  z-index: 40;

  ${media.lessThan("medium")`
      display: none;
      width: 100%;
    `}
`;

export const StyledElementWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  gap: 32px;

  ${media.lessThan("medium")`
      display: none;
    `}
`;

export const MenuHamburguer = styled.div`
  display: none;

  ${media.lessThan("medium")`
    display: flex;
    cursor: pointer;
    margin-right: 1rem;
  `}
`;

export const StyledHorizoalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 40px;
  justify-content: center;

  ${media.lessThan("medium")`
      width: 100%;
      justify-content: center;
    `}
`;

export const StyledSpacer = styled.div`
  display: flex;
  height: 100%;
  max-width: 10px;
`;

export const WrapperProfile = styled.div`
  display: flex;
  width: 150px;
`;

export const WrapperMenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const MenuItem = styled.span`
  font-size: 18px;
  color: #777e90;
  text-transform: capitalize;
`;
