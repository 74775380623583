import { useDispatch, useSelector } from "react-redux";
import { useAPITag } from "../../services/api";
import { getUser, setUser } from "../../app/store/authSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../InputNew/styles";

export const ConfigUserAPI = () => {
  const { http } = useAPITag();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateUser = async (data) => {
    try {
      data.telefone = user.phone;
      data.username = user.username;
      data.email = user.email;

      if (data.telefone) {
        data.telefone = data.telefone.replace(/\D/g, "");
      }
      await http.patch("user/" + user._id, data);
      await getUserData();

      Swal.fire({
        title: "Dados atualizados com sucesso!",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Nome de usuário já está sendo usado!",
        icon: "error",
      });
    }
  };

  const getUserData = async () => {
    try {
      const response = await http.get("user/" + user._id);
      dispatch(setUser(response.data));
    } catch (error) {}
  };

  const updatePassword =
    (currentPassword, password, newPassword) => async () => {
      try {
        if (password === newPassword) {
          const response = await http.post("user/update-pass", {
            currentPassword,
            password,
            newPassword,
            id: user._id,
          });

          Swal.fire({
            title: "Senha alterada com sucesso!",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Senha e confimação da senha precisam ser iguais!",
            icon: "error",
          });
        }
      } catch (error) {
        Swal.fire({
          title: `${error.response.data.message}`,
          icon: "error",
        });
      }
    };

  const resetPassword = async (data) => {
    const { confirmPassword, newPassword } = data;

    if (confirmPassword !== newPassword) {
      Swal.fire({
        title: "Senha e confirmação da senha precisam ser iguais!",
        icon: "error",
      });
      return;
    }

    try {
      await http.post("password-recovery/reset-password", data);

      Swal.fire({
        title: "Senha atualizada com sucesso!",
        icon: "success",
      });

      navigate("/");
    } catch (error) {
      console.log(error.response.data);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Erro ao atualizar a senha!",
          icon: "error",
        });
      }
    }
  };

  return {updateUser, getUserData, updatePassword, resetPassword };
};
