import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as S from "./styles";

const MenuItem = ({ icon, label, links, href }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSubMenu = (href) => {
    navigate(href);
  };

  return (
    <S.Wrapper>
      <S.WrapperTitle>
        {label !== "" && (
          <S.Title
            onClick={() => {
              href && handleSubMenu(href);
            }}
            activeCursor={!!href}
            active={href === pathname}
          >
            <S.IconWrapper>{icon}</S.IconWrapper> <span>{label}</span>
          </S.Title>
        )}
      </S.WrapperTitle>
      <S.WrapperLinks activePadding={links ? true : false}>
        {links?.map(({ label, href, icon, subtitle }) => (
          <S.Links
            onClick={() => {
              handleSubMenu(href);
            }}
            key={href}
            active={pathname === href || pathname.includes(href)}
          >
            {icon && icon}
            <S.WrapperLabel>
              <S.Label>{label}</S.Label>
              <S.Subtitle>{subtitle}</S.Subtitle>
            </S.WrapperLabel>
          </S.Links>
        ))}
      </S.WrapperLinks>
    </S.Wrapper>
  );
};

export default MenuItem;
