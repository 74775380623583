import { Avatar, Button, Col, Input, InputNumber, Layout, Select } from "antd";
import styled from "styled-components";

export const StyledLayout = styled(Layout)`
  height: auto;
  flex: auto;
  flex-direction: column;
  display: flex;
  background-color: white;
`;

export const GestorPetWrapper = styled(Col)`
  padding: 3rem;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const BreedCrumbFinal = styled.span`
  font-style: normal;
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Neutrals/2 */

  color: #b1b5c3;
`;

export const BreedCrumbBegin = styled.span`
  font-style: normal;
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Neutrals/2 */

  color: #777e90;
`;

export const BackButton = styled(Button)`
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 80px;
  width: 120px;
  height: 50px;
`;

export const BackButtonTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Neutrals/2 */

  color: #23262f;
`;

export const InfoTitle = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height, or 175% */

  text-transform: uppercase;
  font-feature-settings: "ss03" on;

  color: #777e90;
`;

export const InfoPet = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 150% */

  font-feature-settings: "ss03" on;

  color: #23262f;
`;

export const PetTitleWrapper = styled(Col)`
  margin-left: 1rem;
  @media screen and (max-width: 480px) {
    margin-left: 0;
    span {
      text-align: left;
    }
  }
`;

export const HealthStateWrapper = styled(Col)`
  width: 123px;
  height: 46px;
  margin-top: 2rem;
  margin-left: 0.5rem;
  @media screen and (max-width: 480px) {
    margin-top: 0;
    margin-left: 2rem;
  }
`;

export const HealthTitle = styled.span`
  margin-left: 1rem;
  color: var(--main-colors-dark-1, #192126);
  font-feature-settings: "ss01" on;
  font-family: Readex Pro;
  font-size: 10.444px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15.667px */
`;
export const HealthDescribe = styled.span`
  margin-left: 1rem;

  color: var(--greyscale-grey-400, #9ea3ae);
  font-feature-settings: "ss01" on;
  font-family: Readex Pro;
  font-size: 7.833px;
  font-style: normal;
  font-weight: 500;
`;

export const PetTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* identical to box height, or 127% */
  color: #353945;
  
`;

export const PetEditTitle = styled.span`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  cursor: pointer;
  letter-spacing: 0.1px;

  color: #747a80;
  @media screen and (max-width: 480px) {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PetAvatar = styled(Avatar)`
  border: 3px #f67e48 solid !important;
  @media screen and (max-width: 480px) {
    margin-top: -1rem;
  }
`;

export const StyledInput = styled(Input)`
  height: 38px;
  border-radius: 8px;
`;

export const StyledTextArea = styled(Input.TextArea)`
  height: 50px;
  border-radius: 8px;
  resize: none;
`;

export const StylePassInput = styled(Input.Password)`
  height: 8px;
  width: "100%";
  border-radius: 8px;
`;

export const StyleInputNumber = styled(InputNumber)`
  min-height: 38px !important;
  width: 100%;
  border-radius: 8px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    min-height: 38px !important;
    border-radius: 8px;
  }
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
  height: 50px;
  width: 344px;
  margin-bottom: 15px;
  margin-top: 25px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;
