import { Button, Divider, Form, Input, InputNumber, Select, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { useGetAddress } from "../../../data/usecases/use-get-address";
import { MaskedInput } from "antd-mask-input";
import Swal from "sweetalert2";
import PetShopsAPI from "./PetShopsAPI";

const PetShop = (props) => {
  const { getPetshop } = PetShopsAPI();
  const [petshop, setPetshop] = useState({ tags: [] });
  const getAddress = useGetAddress();
  const [form] = useForm();
  const { id } = useParams();

  useEffect(() => {
    if (props.edit) {
      const loadPetshop = async () => {
        const data = await getPetshop(id);
        setPetshop(data);
      };

      loadPetshop();
    }
  }, []);

  const columns = [
    {
      title: "Código",
      render: (row) => `${row.code}`,
    },

    {
      title: "Dominio",
      render: (row) => `${row.path}`,
    },
  ];

  return (
    <Dashboard selected="56">
      <S.Wrapper>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={petshop.tags}
        />
      </S.Wrapper>
    </Dashboard>
  );
};

export default PetShop;
