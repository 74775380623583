import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: '100%',
    padding: 6,
    border: '2px solid #E6E8EC',
    borderRadius: '8px',
    backgroundColor: 'white',
    color: '#333',
    boxShadow: 'none',
    '&:hover': { borderColor: '#F67E48' },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? '#F67E48' : isFocused ? '#faa07d' : undefined,
    color: isSelected || isFocused ? 'white' : '#333',
    ':active': {
      ...styles[':active'],
      backgroundColor: '#F67E48',
    },
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#333',
  }),
};


const CustomReactSelect = ({ control, name, options }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          {...field}
          value={options.find(option => option.value === field.value)}
          options={options}
          styles={customStyles}
          onChange={(val) => field.onChange(val ? val.value : '')}
          placeholder="Selecione uma opção"
          noOptionsMessage={() => "Nenhuma opção foi encontrada."} 
        />
      )}
    />
  );
};


export default CustomReactSelect;
