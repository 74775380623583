import { Button, Divider, Form, Input, InputNumber, Select } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import CompaniesAPI from "./CompaniesAPI";
import { useGetAddress } from "../../../data/usecases/use-get-address";
import { MaskedInput } from "antd-mask-input";
import Swal from "sweetalert2";

const FormCompany = (props) => {
  const { getCompany, updateCompany, createCompany } = CompaniesAPI();
  const getAddress = useGetAddress();
  const [form] = useForm();
  const { id } = useParams();

  useEffect(() => {
    if (props.edit) {
      const loadCompany = async () => {
        const data = await getCompany(id);
        form.setFieldsValue({ ...data, ...data.endereco });
        console.log({ ...data, ...data.endereco });
      };

      loadCompany();
    }
  }, []);

  const [address, setAddress] = useState({
    bairro: "",
    cidade: "",
    logradouro: "",
    cep: "",
    estado: "",
  });

  const onCepChange = async (e) => {
    if (e.unmaskedValue.length >= 8) {
      const response = await getAddress(e.unmaskedValue);
      console.log(response.data);
      console.log(form.getFieldsValue());
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...response.data,
        cep: e.maskedValue,
      });
    }
  };

  const handleFinish = async (data) => {
    data.cnpj = data.cnpj.replace(/\D/g, "");
    data.telefone = data.telefone.replace(/\D/g, "");
    data.endereco = {};

    data.endereco.rua = data.rua;
    data.endereco.cep = data.cep;
    data.endereco.estado = data.estado;
    data.endereco.cidade = data.cidade;
    data.endereco.bairro = data.bairro;
    data.endereco.numero = data.numero;
    if (props.edit) {
      if (data.password.length > 0) {
        const result = await Swal.fire({
          title: "Deseja mesmo alterar a senha desta empresa?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Alterar",
          denyButtonText: `Não alterar`,
        });

        if (result.isDenied) {
          data.password = "";
        }
      }

      updateCompany(id)(data);

      return;
    }

    data.cnpj = data.cnpj.replace(/\D/g, "");
    data.telefone = data.telefone.replace(/\D/g, "");
    data.cor_primaria = "#00000";
    data.cor_secundaria = "#00000";
    data.complemento = "";
    data.rua = data.logradouro;
    data.logo = "";
    createCompany(data);
  };

  return (
    <Dashboard selected="10">
      <S.Wrapper>
        <Form form={form} labelCol={{ span: 24 }} onFinish={handleFinish}>
          <Form.Item
            label="CNPJ da Empresa"
            name="cnpj"
            rules={[{ required: true, message: "Informe o CNPJ da Empresa" }]}
          >
            <MaskedInput mask={"00.000.000.0000-00"} />
          </Form.Item>

          <Form.Item
            label="Nome Fantasia"
            name="nome_fantasia"
            rules={[
              {
                required: true,
                message: "Preencha o Nome Fantasia da empresa",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Razão Social"
            name="razao_social"
            rules={[
              { required: true, message: "Preencha a Razão social da empresa" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item initialValue={""} label="Senha" name="password">
            <Input type="password" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Informe o email", type: "email" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Telefone para contato"
            name="telefone"
            rules={[
              { required: true, message: "Informe o telefone da empresa" },
            ]}
          >
            <MaskedInput mask={"(00) 00000-0000"} />
          </Form.Item>

          <Divider style={{ backgroundColor: "white" }}></Divider>

          <Form.Item
            label="CEP"
            name="cep"
            rules={[{ required: true, message: "Informe o CEP" }]}
          >
            <MaskedInput onChange={onCepChange} mask={"00.000-000"} />
          </Form.Item>

          <Form.Item
            label="Rua"
            name="rua"
            rules={[{ required: true, message: "Informe a Rua" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Bairro"
            name="bairro"
            initialValue={address.bairro}
            rules={[{ required: true, message: "Informe o Bairro" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Cidade"
            name="cidade"
            initialValue={address.cidade}
            rules={[{ required: true, message: "Informe a cidade" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Estado"
            name="estado"
            rules={[{ required: true, message: "Informe o estado" }]}
          >
            <Input value={address.estado} />
          </Form.Item>

          <Form.Item
            label="Número"
            name="numero"
            rules={[{ required: true, message: "Informe o número" }]}
          >
            <Input value={address.estado} />
          </Form.Item>

          <Form.Item label="Complemento" name="complemento" rules={[]}>
            <Input value={address.estado} />
          </Form.Item>

          <Button htmlType="submit">
            {props.edit ? "Editar" : "Criar"} Empresa
          </Button>
        </Form>
      </S.Wrapper>
    </Dashboard>
  );
};

export default FormCompany;
