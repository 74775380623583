import { Button, Col, DatePicker, Form, Modal, Row } from "antd";
import * as S from "./styles";
import Swal from "sweetalert2";
import { GestorPetAPI } from "./gestorPetAPI";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";

export const Consults = (props) => {
  const { updatePet } = GestorPetAPI();
  const [newConsultModal, setNewCosultModal] = useState(false);
  const user = useSelector(getUser);

  const [form] = useForm();
  const createHistoric = async () => {
    setNewCosultModal(true);
  };
  const isOwner = () => {
    if (user == null) return false;

    return props.pet.tutor?._id === user._id;
  };
  const onFinish = async (e) => {
    if (!props.pet.medicalAppointment) {
      props.pet.medicalAppointment = [];
    }

    props.pet.medicalAppointment.push(e);

    await updatePet(props.pet._id, {
      consultas: props.pet.medicalAppointment,
    });

    setNewCosultModal(false);
    form.setFieldsValue({});
  };

  return (
    <Col span={24}>
      <Modal
        onCancel={() => {
          setNewCosultModal(false);
          form.setFieldsValue({});
        }}
        onOk={form.submit}
        open={newConsultModal}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          labelAlign="top"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ width: "80%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="local"
            label="Local / Clínica"
            rules={[{ required: true, message: "Informe o Local / Clínica" }]}
          >
            <S.StyledInput />
          </Form.Item>
          <Form.Item
            name="doctor"
            label="Médico Veterinário"
            rules={[{ required: true, message: "Informe o Médico" }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item
            name="descricao"
            label="Diagnóstico"
            rules={[{ required: true, message: "Informe o diagnóstico" }]}
          >
            <S.StyledInput />
          </Form.Item>

          <Form.Item initialValue={false} name="return" label="Retorno ?">
            <S.StyledSelect
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item
            initialValue={false}
            name="prescription"
            label="Prescrição?"
          >
            <S.StyledSelect
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="data"
            label="Data da consulta"
            rules={[{ required: true, message: "Informe a data da consulta" }]}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              placeholder="Selecione a Data da consulta"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      {isOwner() && (
        <Row justify={"end"}>
          <Button onClick={createHistoric}>Nova consulta</Button>
        </Row>
      )}
      <Col className="w-full">
        {props.pet.medicalAppointment &&
          props.pet.medicalAppointment.map((e) => {
            console.log(e);
            return e.local ? (
              <Row style={{ marginTop: "3rem" }}>
                <Col span={4}>
                  <S.InfoPet>{e.local}</S.InfoPet>
                </Col>
                <InfoPet
                  title="Médico Veterinário"
                  info={e.doctor ? e.doctor : e.local}
                />
                <InfoPet title="Diagnóstico" info={e.descricao} />
                <InfoPet title="Retorno" info={e.return ? "Sim" : "Não"} />
                <InfoPet
                  title="Prescrição"
                  info={e.prescription ? "Sim" : "Não"}
                />
                <InfoPet
                  title="Data"
                  info={new Date(e.data).toLocaleDateString("pt-BR")}
                />
              </Row>
            ) : (
              ""
            );
          })}
      </Col>
    </Col>
  );
};

const InfoPet = (props) => {
  return (
    <Col span={4}>
      <Row>
        <Col span={24}>
          <Col>
            <S.InfoTitle>{props.title}</S.InfoTitle>
          </Col>
          <S.InfoPet>{props.info}</S.InfoPet>
        </Col>
      </Row>
    </Col>
  );
};
