import Styled from 'styled-components'

export const Card = Styled.button` 
  display: flex;
  justify-content: center;
  width: 87px;
  height: 40px;
  border: 2px solid #F67E48;
  color: #F67E48;
  border-radius: 12px;
  align-items: center;
  background-color: #fff;

  cursor: pointer;
`
