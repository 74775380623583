import Styled from "styled-components";

export const Title = Styled.h2`
  font-family: "Readex pro";
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
  color: #23262F;

    @media (max-width: 480px) {
    font-size: 18px;

    }
`;
