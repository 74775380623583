import { GitlabFilled } from "@ant-design/icons";
import AddressIcon from "../../components/Icons/AddressIcon";
import LockedIcon from "../../components/Icons/LockedIcon";
import PersonIcon from "../../components/Icons/PersonIcon";

export const steps = [
  // {
  //   title: "Meus Pets",
  //   icon: <GitlabFilled />,
  //   item: "pets",
  // },
  {
    title: "Editar perfil",
    icon: <PersonIcon />,
    item: "perfil",
  },
  {
    title: "Endereços",
    icon: <AddressIcon />,
    item: "endereco",
  },
  {
    title: "Conta e segurança",
    icon: <LockedIcon />,
    item: "security",
  },
];

export const stepsSelect = [
  {
    label: "Editar perfil",
    icon: <PersonIcon />,
    value: "perfil",
  },
  {
    label: "Endereços",
    icon: <AddressIcon />,
    value: "endereco",
  },
  {
    label: "Conta e segurança",
    icon: <LockedIcon />,
    value: "security",
  },
];
