import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../login/loginSlice";
import Swal from "sweetalert2";

const WhatsappConfigAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const getConfig = async () => {
    try {
      const response = await http.get("qr-code");
      return response.data;
    } catch (error) {}
  };

  return {
    getConfig,
  };
};

export default WhatsappConfigAPI;
