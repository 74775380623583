export const formatMoney = (value) => {
  if (value) {
    const formatingValue = value.toLocaleString('pt-br', {minimumFractionDigits: 2})
    return formatingValue;
  }
  if (!value) {
    value = 0
    const formatingValue = value.toLocaleString('pt-br', {minimumFractionDigits: 2})
    return formatingValue
  }
}