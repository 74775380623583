import { Button, Form, Input, InputNumber, QRCode, Result, Select } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import WhatsappConfigAPI from "./whatsappConfigAPI";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { Colorpicker } from "antd-colorpicker";
import { UploadInput } from "../../../components/uploadInput";

const WhatsappConfig = (props) => {
  const { getConfig } = WhatsappConfigAPI();
  const [config, setConfig] = useState({});
  const [form] = useForm();

  useEffect(() => {
    const loadConfig = async () => {
      console.log("loading qr-code");
      const data = await getConfig();
      setConfig(data);
    };

    loadConfig();

    window.setInterval(loadConfig, 10000);
  }, []);

  return (
    <Dashboard selected="20">
      <S.Wrapper>
        {config.status === 1 && <QRCode value={config.qr}></QRCode>}
        {config.status === 4 && (
          <Result status="success" title="Conectado ao Whatsapp" extra={[]} />
        )}
      </S.Wrapper>
    </Dashboard>
  );
};

export default WhatsappConfig;
