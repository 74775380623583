import axios from "axios";
import { useSelector } from "react-redux";
import { getPDFUrl, selectAuthState } from "../features/admin/login/loginSlice";

const API_URL = "https://api.ajuda.pet";

export const useAPI = () => {
  const auth = useSelector(selectAuthState);

  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  });

  return http;
};

export function useAPIPDF() {
  const auth = useSelector(selectAuthState);
  const pdfUrl = useSelector(getPDFUrl);

  const http = axios.create({
    baseURL: pdfUrl,
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  });

  return { http };
}

export function useAPIPet() {
  const auth = useSelector(selectAuthState);

  const http = axios.create({
    baseURL: `https://pdf.ajuda.pet`,
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  });

  return { http };
}

export function useAPITag() {
  const auth = useSelector(selectAuthState);

  const http = axios.create({
    baseURL: `https://tag.ajuda.pet/`,
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  });

  return { http };
}
