import AuthPhoneApiPre from "./AuthPhoneApiPre";
import * as S from "./styles";
import { Form, Input, Space, Spin } from "antd";
import Swal from "sweetalert2";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import { useSelector } from "react-redux";
import { useAPITag } from "../../../services/api";
import { getWhatsappStatus, selectRegisterstate } from "../RegisterPetSlice";
import { WhatsAppOutlined } from "@ant-design/icons";

export const AuthPhonePreRegister = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { http } = useAPITag();
  const { ValidateTag, AuthPhone, ValidatePreTag, AuthPhonePre } = AuthPhoneApiPre();
  const [inputDisabled, setInputDisabled] = useState(false);
  const registerState = useSelector(selectRegisterstate);
  const phoneRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const wppStatus = useSelector(getWhatsappStatus);
  const [userPhone, setUserPhone] = useState("");

  const getTag = async (id) => {
    try {
      const response = await http.get("tags/" + id);
      setUserPhone(response.data.tag.userPhone);
    } catch (error) {
      throw error;
    }
  }
  useEffect(() => {
    setSearchParams([]);
    getTag(id);
    ValidatePreTag(id);
  }, []);

  const onFinishFailed = () => {
    Swal.fire({
      title: "Preencha todos os campos",
      icon: "error",
    });
  };

  const onChange = (e) => {
    if (e.unmaskedValue.length > 5) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  };

  const onEditAreaCode = (e) => {
    const target = e.srcElement || e.target;

    const maxLength = parseInt(target.attributes["maxlength"].value, 10);

    const currentLength = target.value.length;

    if (currentLength >= maxLength) {
      phoneRef?.current?.focus();
    }
  };

  return !registerState.config.logo ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large"> </Spin>
    </div>
  ) : (
    <RegisterPetLayout
      hasHeader
      description="Insira o número do seu celular e receba o código de ativação da coleira."
      title="Cadastrar nova coleira"
      size={5}
      step={1}
    >
      <Form
        form={form}
        name="basic"
        layout="horizontal"
        colon={false}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 25,
          marginTop: "1rem",
        }}
        initialValues={{ remember: true }}
        onFinish={AuthPhonePre}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Space>
          <Form.Item
            name="ddd"
            rules={[{ required: true, message: "Informe o DDD" }]}
          >
            <S.StyledAreaInput
              maxLength={2}
              type="tel"
              onKeyUp={onEditAreaCode}
            />
          </Form.Item>
          <Form.Item
            name="telefone"
            rules={[{ required: true, message: "Informe o telefone" }]}
          >
            <S.StyledInput
              ref={phoneRef}
              onChange={onChange}
              mask={"00000-0000"}
              type="tel"
            />
          </Form.Item>

          <Form.Item initialValue={"sms"} name="grantType">
            <Input type="hidden" />
          </Form.Item>
        </Space>
      </Form>
      {wppStatus && (
        <S.SubmitWhatsappButton
          onClick={() => {
            form.setFieldValue("grantType", "whatsapp");
            form.submit();
          }}
          disabled={inputDisabled}
          type="primary"
          htmlType="submit"
        >
          <WhatsAppOutlined></WhatsAppOutlined>
          Receber por Whatsapp
        </S.SubmitWhatsappButton>
      )}
      <S.SubmitButton
        className={"whatsapp"}
        onClick={() => form.submit()}
        disabled={inputDisabled}
        type="primary"
        htmlType="submit"
      >
        Receber por SMS
      </S.SubmitButton>
    </RegisterPetLayout>
  );
};
