import React from "react";
import * as S from "./styles";

const InputNew = ({
  label,
  mask,
  icon,
  iconPosition,
  register,
  name,
  errors,
  initialValue,
  onChange,
  disabled,
}) => {
  const error = errors && errors[name];
  return (
    <S.Wrapper initialValue={initialValue} name={name} label={label}>
      {mask ? (
        <S.InputFieldMasked
          disabled={disabled}
          onChange={onChange}
          mask={mask || ""}
        />
      ) : (
        <S.InputField disabled={disabled} />
      )}
    </S.Wrapper>
  );
};

export default InputNew;
