import React from "react"

const LogoImage = ({
  title,
  titleId,
  ...props
}) => (
  <svg
    width={36}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M35.1477 0H0.852356C0.381886 0 0 0.381807 0 0.852278V26.9659C0 31.9534 4.04329 35.9983 9.03075 36H9.03407H25.7138H35.1477C35.6182 36 35.9999 35.6181 35.9999 35.1477V0.852278C35.9999 0.381807 35.6182 0 35.1477 0Z" fill="black"/>
    <path d="M34.6356 12.46V34.6589H9.27539V12.46H9.30257C11.6191 16.786 16.4157 19.758 21.9555 19.758C27.4953 19.758 32.2918 16.786 34.6083 12.46H34.6356Z" fill="#F67E48"/>
    <path d="M1.36328 26.9658V23.0556C1.36328 22.7811 1.47241 22.5186 1.66844 22.3277C4.26272 19.8204 5.94336 15.7959 5.94336 11.2584C5.94336 7.37549 4.71276 3.86752 2.73037 1.36523H30.5844C30.5946 3.49934 32.3996 5.22945 34.636 5.25163V7.76923C34.636 9.35613 33.6985 11.2601 33.3184 11.9692C33.3082 11.9948 33.2928 12.0204 33.2758 12.0459C33.2451 12.1056 33.211 12.1652 33.1769 12.2232C33.1719 12.23 33.1685 12.2385 33.1633 12.2453C30.9184 16.0516 26.6706 18.3936 21.9559 18.3936C17.0655 18.3936 12.6781 15.8743 10.5049 11.8158C10.2679 11.3726 9.80595 11.0965 9.3031 11.0965H9.27577C8.5224 11.0965 7.91215 11.7067 7.91215 12.4601V34.5544C4.20821 34.0124 1.36328 30.8198 1.36328 26.9658Z" fill="white"/>
    <path d="M10.3754 4.87651C10.3754 5.48333 9.88282 5.97767 9.276 5.97767C8.66748 5.97767 8.1748 5.48333 8.1748 4.87651C8.1748 4.26799 8.66748 3.77539 9.276 3.77539C9.88282 3.77539 10.3754 4.26799 10.3754 4.87651Z" fill="black"/>
    <path d="M18.2381 25.0245C18.2381 25.6323 17.7454 26.1251 17.1375 26.1251C16.5299 26.1251 16.0371 25.6323 16.0371 25.0245C16.0371 24.4166 16.5299 23.9238 17.1375 23.9238C17.7454 23.9238 18.2381 24.4166 18.2381 25.0245Z" fill="black"/>
    <path d="M29.9502 25.0245C29.9502 25.6323 29.4574 26.1251 28.8496 26.1251C28.2419 26.1251 27.7491 25.6323 27.7491 25.0245C27.7491 24.4166 28.2419 23.9238 28.8496 23.9238C29.4574 23.9238 29.9502 24.4166 29.9502 25.0245Z" fill="black"/>
  </svg>
)

export default LogoImage
