import styled, { css } from "styled-components";

const profileModfiers = {
  logoutH3: () => css`
    & h3 {
      color: #e33b2a;
    }
  `,
  logoutBtn: () =>
    css`
      padding-left: 40px;
    `,
};

export const StyledProfileContainer = styled.div`
  ${({ maxWidth, maxHeight }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    width: 100%;
    background-color: transparent;
  `}
`;

export const StyledProfileUserInfos = styled.div`
  ${() => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
  `}

  &:focus + ul {
    opacity: 1;
    animation-name: none;
  }

  &:focus svg:has(> g) {
    transform: rotate(180deg);
    filter: invert(50%) sepia(51%) saturate(2500%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
  }
`;

export const StyledProfileArrowIcon = styled.svg`
  ${() => css`
    height: 30%;
    aspect-ratio: 1 / 1;
    transition: 0.2s ease;
    padding: 5px;
  `}
`;

export const StyledProfileList = styled.ul`
  ${() => css`
    position: absolute;
    margin-top: 2px;
    width: 250px;
    padding: 0;
    list-style: none;
    opacity: 0;
    top: 100%;
    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0px 29px 64px -48px rgba(31, 47, 70, 0.3);
    border-radius: 30px;
    background: #ffffff;

    @keyframes HideList {
      from {
        transform: scaleY(1);
      }
      to {
        transform: scaleY(0);
      }
    }

    & hr {
      margin: 0px 10%;
      height: 1px;
      background-color: #f4f5f6;
      border: none;
    }
  `}
`;

export const StyledProfileOption = styled.button`
  ${({ logout }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 20px;
    gap: 10px;
    background-color: transparent;
    border-radius: 30px;
    border: none;
    width: 100%;
    height: 80px;

    &:hover,
    &:focus {
      background-color: #fde5da;
    }

    & svg {
      margin-right: 10px;
    }

    & span {
      justify-content: center;
      text-align: center;
    }

    ${!logout && "&:hover svg path { fill: #F67E48; }"}
    ${logout && profileModfiers.logoutBtn()}
  `}
`;

export const StyledProfileTextWrapper = styled.span`
  ${({ logout }) => css`
    display: flex;
    align-items: right;
    background-color: transparent;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    color: #777e90;

    & h3 {
      color: #353945;
      margin: 0;
    }

    ${logout && profileModfiers.logoutH3()}
  `}
`;

export const StyledProfileInfosConatiner = styled.span`
  ${() => css`
    display: flex;
    align-items: center;
    background-color: transparent;
    flex-direction: row;
    height: 100%;
    width: 100%;
    gap: 10px;
  `}
`;

export const StyledProfilePic = styled.img`
  ${() => css`
    width: 50px;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  `}
`;

export const StyledProfilesTextWrapper = styled.span`
  ${() => css`
    display: flex;
    align-items: left;
    text-align: left;
    background-color: transparent;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    color: #777e90;

    & h3 {
      color: #353945;
      font-size: 12px;
    }
  `}
`;
