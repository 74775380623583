import React from "react"

const LogoText = ({
  title,
  titleId,
  ...props
}) => (
  <svg
    width={102}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.50869 11.4273V12.9446C3.50869 14.6991 4.97865 15.5998 6.42484 15.5998C8.0132 15.5998 9.26977 14.6041 9.26977 12.9446V11.4273C9.26977 9.76771 8.0132 8.77194 6.42484 8.77194C4.97865 8.77194 3.50869 9.67286 3.50869 11.4273ZM0 12.589V11.4747H0.0237038C0.189592 7.58656 2.84491 5.61886 5.45284 5.40543C6.87519 5.28687 8.44003 5.69001 9.67279 6.63831C10.0758 6.11672 10.6923 5.76118 11.4035 5.76118H11.9488C12.3992 5.76118 12.8023 6.16405 12.8023 6.63831V18.2313C12.8023 18.6345 12.4703 18.9664 12.0673 18.9664H11.6168C11.1664 18.9664 10.6211 18.2078 10.0284 17.449C8.86677 18.5159 7.37304 18.9901 5.95057 18.9901C5.78459 18.9901 5.61872 18.9901 5.45284 18.9664C2.77378 18.753 0.0472997 16.6904 0 12.589Z" fill="#F67E48"/>
    <path d="M19.1342 2.25229C19.1342 3.50866 18.0912 4.50454 16.8346 4.50454C15.6017 4.50454 14.5586 3.50866 14.5586 2.25229C14.5586 0.995708 15.6017 0 16.8346 0C18.0912 0 19.1342 0.995708 19.1342 2.25229ZM19.6781 6.496V18.1129C19.6781 22.0721 16.3353 22.8782 13.9171 22.9968C13.5139 23.0204 13.1821 22.7359 13.1584 22.3565L12.9449 20.3178C12.8976 19.891 13.2295 19.5354 13.6563 19.5117C14.6994 19.5117 16.1694 19.2034 16.1694 16.7852V8.93792H14.7468C14.3675 8.93792 14.0355 8.60605 14.0355 8.22676V6.496C14.0355 6.1167 14.3675 5.78483 14.7468 5.78483H18.9432C19.3461 5.78483 19.6781 6.09308 19.6781 6.496Z" fill="#F67E48"/>
    <path d="M32.9571 6.49614V12.3283C32.9571 16.904 29.9699 18.9902 26.8641 18.9902C23.8057 18.9902 20.8898 16.9514 20.8898 12.4943V6.49614C20.8898 6.09323 21.2216 5.76123 21.6009 5.76123H23.6873C24.0903 5.76123 24.3985 6.09323 24.3985 6.49614V12.85C24.3985 14.6042 25.5128 15.5052 26.9589 15.5052C28.334 15.5052 29.4482 14.6754 29.4482 12.85V6.49614C29.4482 6.09323 29.7565 5.76123 30.1595 5.76123H32.2459C32.6488 5.76123 32.9571 6.09323 32.9571 6.49614Z" fill="#F67E48"/>
    <path d="M37.6777 11.4984V12.8259C37.6777 14.5804 39.1477 15.4813 40.5938 15.4813C42.1823 15.4813 43.4388 14.4855 43.4388 12.8259V11.4984C43.4388 9.83882 42.1823 8.84306 40.5938 8.84306C39.1477 8.84306 37.6777 9.76769 37.6777 11.4984ZM34.1691 12.5652V11.451H34.1927C34.3587 7.56285 37.014 5.59511 39.6219 5.38168C40.9021 5.28683 42.2771 5.57131 43.4388 6.33002V0.711306C43.4388 0.308273 43.7707 0 44.1739 0H46.2364C46.6394 0 46.9714 0.308273 46.9714 0.711306V18.2313C46.9714 18.6344 46.6394 18.9663 46.2364 18.9663H45.7859C45.3354 18.9663 44.7902 18.184 44.1975 17.4254C43.0358 18.4923 41.5421 18.9663 40.1196 18.9663C37.0614 18.9663 34.2164 16.6667 34.1691 12.5652Z" fill="#F67E48"/>
    <path d="M51.6922 11.4273V12.9446C51.6922 14.6991 53.1622 15.5998 54.6083 15.5998C56.1967 15.5998 57.4533 14.6041 57.4533 12.9446V11.4273C57.4533 9.76771 56.1967 8.77194 54.6083 8.77194C53.1622 8.77194 51.6922 9.67286 51.6922 11.4273ZM48.1835 12.589V11.4747H48.2072C48.3731 7.58656 51.0284 5.61886 53.6363 5.40543C55.0587 5.28687 56.6235 5.69001 57.8563 6.63831C58.2593 6.11672 58.8758 5.76118 59.5869 5.76118H60.1324C60.5827 5.76118 60.9858 6.16405 60.9858 6.63831V18.2313C60.9858 18.6345 60.6538 18.9664 60.2508 18.9664H59.8004C59.3499 18.9664 58.8046 18.2078 58.212 17.449C57.0503 18.5159 55.5566 18.9901 54.1341 18.9901C53.9681 18.9901 53.8023 18.9901 53.6363 18.9664C50.9573 18.753 48.2308 16.6904 48.1835 12.589Z" fill="#F67E48"/>
    <path d="M72.6799 12.85V11.5223C72.6799 9.76792 71.2099 8.8669 69.7639 8.8669C68.4124 8.8669 66.9188 9.69678 66.9188 11.5223V12.85C66.9188 14.6753 68.4124 15.5052 69.7639 15.5052C71.2099 15.5052 72.6799 14.6043 72.6799 12.85ZM76.1886 11.783H76.2125V12.8973H76.1886C76.0226 16.7854 73.3674 18.7532 70.7359 18.9665C70.5699 18.9902 70.404 18.9902 70.238 18.9902C69.1236 18.9902 67.9383 18.6821 66.9188 18.0183V22.2619C66.9188 22.6649 66.6106 22.9732 66.2076 22.9732H64.1213C63.742 22.9732 63.4102 22.6649 63.4102 22.2619V6.47253C63.4102 6.09321 63.742 5.76132 64.1213 5.76132H64.5955C65.0459 5.76132 65.5675 6.14053 66.1839 6.92286C67.4878 5.73753 69.1947 5.28706 70.7359 5.40552C73.4385 5.61905 76.1413 7.68155 76.1886 11.783Z" fill="black"/>
    <path d="M80.8377 10.5738H86.1246C85.9586 9.31716 84.6784 8.77183 83.493 8.77183C82.3077 8.74812 81.0984 9.24603 80.8377 10.5738ZM88.9458 18.0655C87.9974 18.4922 86.575 18.9426 84.2042 18.9426C80.1028 18.9426 77.4238 16.5243 77.4238 12.4466V11.6405C77.4238 7.15969 80.5295 5.35801 83.5403 5.3342C86.3617 5.31049 89.2777 7.23079 89.2777 10.8344V10.953V11.0005V11.2376V11.2849V11.6879C89.2777 12.6838 88.4717 13.4897 87.4995 13.4897H80.9325C81.4067 14.8886 82.9714 15.5524 84.4413 15.5524C85.9822 15.5524 87.0729 15.2204 87.7367 14.9123C88.1398 14.7463 88.5902 14.9597 88.7088 15.3626L89.3015 17.1882C89.42 17.5438 89.2777 17.8995 88.9458 18.0655Z" fill="black"/>
    <path d="M92.8798 14.5567V8.91431H91.1965C90.8173 8.91431 90.4854 8.60604 90.4854 8.203V6.496C90.4854 6.09308 90.8173 5.76108 91.1965 5.76108H92.8798V0.711162C92.8798 0.308159 93.1881 0 93.5911 0H95.6774C96.0567 0 96.3887 0.308159 96.3887 0.711162V5.76108H99.2573C99.6367 5.76108 99.9687 6.09308 99.9687 6.496V8.203C99.9687 8.60604 99.6367 8.91431 99.2573 8.91431H96.3887V13.5373C96.3887 14.2248 96.6257 15.4814 97.8585 15.4814C98.4514 15.4814 98.9017 15.3154 99.21 15.1258C99.3284 15.0546 99.447 15.0309 99.5892 15.0309C99.8263 15.0309 100.063 15.1494 100.206 15.3628L101.225 16.9749C101.415 17.2831 101.367 17.7099 101.059 17.9233C100.135 18.6109 98.9965 18.9901 97.8585 18.9901C94.5157 18.9901 92.8798 16.6193 92.8798 14.5567Z" fill="black"/>
  </svg>
)

export default LogoText
