import Container from "../../components/Container";
import Box from "../../components/Box";
import SchedulingPage from "./SchedulingPage";
import * as S from "./styles";
import { ThemeProvider } from "styled-components";
import { Outlet } from "react-router-dom";
import Header from "../../../../components/Header";


const theme = {
  colors: {
    primary: "#F67E48",
    secondary: "#FCFCFD",
    terciary: "#B1B5C3",
    background: "#f5f5f5",
    status: {
      destructive: "#E13600",
      warning: "#FFB800",
      success: "#049601",
    },
  },
};

const Doar = () => {
  return (
    <Container>
      <Header />
      <S.Wrapper>
        <Box>
          <SchedulingPage />
        </Box>
      </S.Wrapper>
    </Container>
  );
};

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Header />
        <S.Wrapper>
          <Box>
            <Outlet />
          </Box>
        </S.Wrapper>
      </Container>
    </ThemeProvider>
  );
}
