import { createSlice } from "@reduxjs/toolkit";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const authCustomerSlice = createSlice({
  name: "authCustomer",
  initialState: {
    modalAuth: false,
    user:
      localStorage.getItem("@customer") !== null &&
      isJsonString(localStorage.getItem("@customer"))
        ? JSON.parse(localStorage.getItem("@customer"))
        : null,
    token: localStorage.getItem("@customerToken"),
    authPhoneStep: 1,
    isRecoveringPass: false,
    currentPet: null,
  },
  reducers: {
    authCustomer: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      localStorage.setItem("@customer", JSON.stringify(action.payload.user));
      localStorage.setItem("@customerToken", action.payload.token);
      state.modalAuth = false;
    },
    logout: (state, action) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("@customer");
      localStorage.removeItem("@customerToken");
    },
    openModal: (state) => {
      state.modalAuth = true;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCurrentPet: (state, action) => {
      state.currentPet = action.payload;
    },
    closeModal: (state) => {
      state.modalAuth = false;
      state.isRecoveringPass = false;
    },
    setRecovering: (state, action) => {
      state.isRecoveringPass = action.payload;
    },
    setAuthPhoneStep: (state, action) => {
      state.authPhoneStep = action.payload;
    },
  },
});

export const selectAuthState = (state) => state.authCustomer;
export const getAuthToken = (state) => state.authCustomer.token;
export const getUser = (state) => state.authCustomer.user;
export const isRecoveringPassword = (state) =>
  state.authCustomer.isRecoveringPass;
export const isLoggedIn = (state) => (state.authCustomer.user ? true : false);
export const isModalOpen = (state) => state.authCustomer.modalAuth;
export const getPet = (state) => state.authCustomer.currentPet;
export const getAuthPhoneStep = (state) => state.authCustomer.authPhoneStep;

export const {
  authCustomer,
  logout,
  openModal,
  closeModal,
  setUser,
  setRecovering,
  setCurrentPet,
} = authCustomerSlice.actions;

export default authCustomerSlice.reducer;
