import * as S from "./styles";
import Button from "../Button";
import { Col, Progress, notification } from "antd";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { useAPITag } from "../../services/api";
import { ConfigUserAPI } from "../FormConfigUser/ConfigUserAPI";
import { useMemo } from "react";

const Banner = ({ link, text, ...props }) => {
  const user = useSelector(getUser);
  const { http } = useAPITag();
  const { updateUser } = ConfigUserAPI();
  const api = useMemo(() => notification.useNotification(), []);  // Use useMemo para memorizar a instância

  const getTag = () => {
    function makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    }

    return makeid(16);
  };

  const NOTIFY_ID = getTag();

  const onChangeFiles = async (e) => {
    const files = Array.from(e.target.files);
  
    const newFiles = [];
  
    for (let i = 0; i < files.length; i++) {
      newFiles.push(
        await uploadFilePromise(files[i], i + 1, files.length, files[i].name, 0)
      );
    }
  };
  

  const uploadFilePromise = (file, index, totalFiles, name) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.append("files", file);
      bodyFormData.append("companyId", user._id);
      bodyFormData.append("filename", file.name);
      http
        .post("/files", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            openNotification(totalFiles, index, name, percentage);
          },
        })
        .then((e) => {
          const newFile = e.data;
          updateUser({ capa: e.data.url });
          resolve(newFile);
        });
    });
  };

  const openNotification = (
    totalFiles,
    filesCount,
    currentFileName,
    currentFileProgress
  ) => {
    api.open({
      key: NOTIFY_ID,
      message: "Arquivos sendo enviados - " + `${filesCount} / ${totalFiles}`,
      description: (
        <Col>
          <p>Total</p>
          <Progress percent={Math.floor((filesCount / totalFiles) * 100)} />
        </Col>
      ),
    });
  };

  return (
    <S.Banner>
      <S.Imagem img src={link} alt="imagem de capa" />
      <S.Overlay />
      <S.Upload>
        <input type="file" {...props} onChange={onChangeFiles} />
        {text}
      </S.Upload>
    </S.Banner>
  );
};

export default Banner;