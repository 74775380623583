import { Button, Dropdown, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import CollarSizeAPI from "./collarSizeApi";
import { useNavigate } from "react-router-dom";

export const CollarSizes = () => {
  const [collars, setCollars] = useState([]);
  const { getCollarSizes, deleteCollarSize } = CollarSizeAPI();
  const navigate = useNavigate();
  useEffect(() => {
    getCollars();
  }, []);

  const columns = [
    {
      title: "Nome",
      render: (row) => row.title,
    },
    {
      title: "Ações",
      render: (row, index, column, i) => {
        const items = [
          {
            key: "actions_2_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  Swal.fire({
                    title: "Deseja mesmo deletar esse tamanho de Coleira?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Deletar",
                    denyButtonText: `Não Deletar`,
                  }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      await deleteCollarSize(row._id);
                      await getCollars();
                      Swal.fire("Coleira deletada com sucesso!", "", "success");
                    } else if (result.isDenied) {
                    }
                  });
                }}
              >
                Deletar
              </Button>
            ),
          },
          {
            key: "actions_3_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  navigate("/admin/collar-sizes/edit/" + row._id);
                }}
              >
                Editar
              </Button>
            ),
          },
        ];
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const getCollars = async () => {
    const response = await getCollarSizes();
    setCollars(response);
  };

  return (
    <Dashboard selected="3">
      <S.Wrapper>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={collars}
        />
        <Button
          color="primary"
          onClick={() => {
            navigate("/admin/collar-sizes/create");
          }}
        >
          Criar novo tamanho de Coleira
        </Button>
      </S.Wrapper>
    </Dashboard>
  );
};
