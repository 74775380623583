import { Button, Dropdown, QRCode, Row, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import LeadApi from "./LeadsAPI";

export const Leads = () => {
  const [leads, setLeads] = useState([]);
  const { getLeads } = LeadApi();
  const navigate = useNavigate();
  useEffect(() => {
    const loadLeads = async () => {
      const response = await getLeads();

      setLeads(response);
    };

    loadLeads();
  }, []);

  const columns = [
    {
      title: "Nome",
      render: (row) => `${row.nome}`,
    },

    {
      title: "Email",
      render: (row) => `${row.email}`,
    },
    {
      title: "Telefone",
      render: (row) => `${row.telefone}`,
    },
    {
      title: "Endereço",
      render: (row) => `${row.address?.formatted_address}`,
    },
  ];

  return (
    <Dashboard selected="leads">
      <S.Wrapper>
        <Row></Row>
        <Table style={{ width: "80vw" }} columns={columns} dataSource={leads} />
      </S.Wrapper>
    </Dashboard>
  );
};
