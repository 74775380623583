import * as S from './styles'

const BannerDonate = (image) => {
  return (

    <S.Wrapper>
      <S.WrapperBanner image={`images/banner.png`}></S.WrapperBanner>

    </S.Wrapper>
  )
}

export default BannerDonate;