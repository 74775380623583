import { createSlice } from "@reduxjs/toolkit";
import ThemeData from "../../app/theme";

export const modalFileSlice = createSlice({
  name: "fileModal",
  initialState: {
    modalOpen: false,
  },
  reducers: {
    setModalFile: (state, action) => {
      state.modalOpen = action.payload;
    },
  },
});

export const getModalIsOpen = (state) => state.fileModal.modalOpen;

export const { setModalFile } = modalFileSlice.actions;

export default modalFileSlice.reducer;
