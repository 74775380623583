import Styled, { css } from "styled-components";
import ButtonDonate from "../../../../components/ButtonDonate";
import { Checkbox } from "antd";

const typePaymentModifiers = {
  selected: (color) => css`
    border: 2px solid #f67e48;
    background-color: #fef5f0;

    p {
      color: #192126;
    }
  `,
};

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SectionTitle = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
      @media (max-width: 480px) {
    margin-top: 40px;

    }
`;

export const SubTitleSection = Styled.p`
  color: #777E90;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const SectionInfo = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperInput = Styled.div`
  display: flex;
  flex-direction: row;
  max-width: 142px;
`;

export const WrapperButton = Styled.div`
  width: 100%;
  margin-top: 30px;
  text-align: center;
`;

export const SubmitButton = Styled(ButtonDonate)`
  margin-bottom: 16px;
`;

export const SectionFrequencyPaymentTitle = Styled.div`
  margin: 12px 0;
`;

export const SectionFrequencyPayment = Styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;

export const FrequencyTitle = Styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: rgb(35, 38, 47);
`;

export const FrequencyDescription = Styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: rgb(111, 118, 126);
`;

export const WrapperValueDonation = Styled.div`
    width: 400px;
    height: 88px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-evenly;
    background: rgb(244, 245, 246);
    padding: 21px 4px;
    border-radius: 12px;
`;

export const IconTextContainer = Styled.div`
  display: flex;
  align-items: center;
  gap: 36px;

  .anticon {
    color: ${(props) => (props.styled === "selected" ? "#F67E48" : "inherit")};
  }
`;

export const IconTextContainer2 = Styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  span {
    font-size: smaller;
    color: gray;
  }
`;

export const StyledCheckbox = Styled(Checkbox)`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
`;

export const RadialIndicator = Styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;

  background-color: ${(props) =>
    props.styled === "selected" ? "#F67E48" : "transparent"};
`;

export const WrapperMoney = Styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const DonationTag = Styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #23262F;
`;

export const LinkChange = Styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: #F67E48;
  cursor: pointer;
`;

export const SectionTypePayment = Styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: center;
    gap: 8px;
    width: 100%;
`;

export const CardTypePayment = Styled.div`
  ${({ theme, styled }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 24px;
    border-radius: 12px;
    width: 100%;
    height: 72px;
    color: #d7d7d7;
    border: 2px solid #d7d7d7;
    cursor: pointer;

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #d7d7d7;
    }

    ${styled && typePaymentModifiers[styled]}
  `}
`;

export const CardFrequencyPayment = Styled.div`
  ${({ theme, styled }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 24px;
    border-radius: 12px;
    width: 100%;
    height: 72px;
    color: #d7d7d7;
    border: 2px solid #d7d7d7;
    cursor: pointer;

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #d7d7d7;
    }

    ${styled && typePaymentModifiers[styled]}
  `}
`;

export const Description = Styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #b1b5c3;

  @media (max-width: 480px) {
    display: none;
  }
`;
