import React from "react"

const BellIcon = ({
  title = 'BellIcon',
  titleId = 'bell-icon',
  color = '#777E90',
  ...props
}) => (
  <svg
    height={'30%'}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18 15.5233C18 16.0113 17.6043 16.407 17.1163 16.407H0.88372C0.395655 16.407 0 16.0113 0 15.5233C0 15.0352 0.395656 14.6395 0.883721 14.6395H0.9V8.48088C0.9 4.07288 4.527 0.5 9 0.5C13.473 0.5 17.1 4.07288 17.1 8.48088V14.6395H17.1163C17.6043 14.6395 18 15.0352 18 15.5233ZM2.7 14.6395H15.3V8.48088C15.3 5.0494 12.4794 2.26744 9 2.26744C5.5206 2.26744 2.7 5.0494 2.7 8.48088V14.6395ZM6.97604 18.2558C6.73121 17.7608 7.19772 17.2907 7.75 17.2907H10.25C10.8023 17.2907 11.2688 17.7608 11.024 18.2558C10.9155 18.4751 10.7699 18.6773 10.591 18.8529C10.169 19.2672 9.59674 19.5 9 19.5C8.40326 19.5 7.83097 19.2672 7.40901 18.8529C7.23013 18.6773 7.08449 18.4751 6.97604 18.2558Z" fill={color} />
  </svg>
)

export default BellIcon