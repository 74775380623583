import Swal from "sweetalert2";
import api from "./api/api";

export const requestPix = async (payload) => {
  try {
    const response = await api.post("donation", { ...payload });
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const requestCreditCard = async (payload) => {
  try {
    const response = await api.post("donation/credit-card", { ...payload });
    return true;
  } catch (error) {
    Swal.fire({ icon: "error", title: "Houve falha no pagamento" });
    return false;
  }
};
