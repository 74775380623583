import React, { useState } from "react";
import "./index.css"; // You'll create this CSS file for styling
import {
  ArrowDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import { Button } from "antd";

const ExpandableComponent = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={`expandable-container ${expanded ? "expanded" : ""}`}>
        <div className="content">{children}</div>
      </div>
      <div className="expand-icon" onClick={toggleExpansion}>
        <Button
          style={{
            backgroundColor: "white !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {expanded ? "Retrair" : "Expandir"}
          {expanded ? (
            <ChevronUpIcon></ChevronUpIcon>
          ) : (
            <ChevronDownIcon></ChevronDownIcon>
          )}
        </Button>
      </div>
    </>
  );
};

export default ExpandableComponent;
