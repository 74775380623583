import Styled from "styled-components";
import ButtonDonate from "../../../../components/ButtonDonate";

export const WrapperImg = Styled.div``;

export const Img = Styled.img`
@media (max-width: 480px) {
  margin-top: 40px;

  }`;

export const WrapperThanks = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-top: 60px;

  
`;

export const WrapperButton = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 68px;
`;

export const SubmitButton = Styled(ButtonDonate)`
  width: 100%;
  margin-bottom: 20px;
`;

export const Description = Styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #b1b5c3;

  @media (max-width: 480px) {
    display: none;
  }
`;
