import Styled from "styled-components";

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

export const ContainerInput = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-height: 80px;
  gap: 12px;
`;

export const Input = Styled.input`
  border: 2px solid #E6E8EC;
  padding: 12px 16px;
  border-radius: 8px;

  &::placeholder {
    color: #B1B5C4;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    padding: 12px 16px;
  }

  &:focus {
    border: 2px solid #F67E48;
  }
`;

export const Label = Styled.label`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #353945;
`;
