import { useState } from "react";
import QrCodeReader, { QRCode } from "react-qrcode-reader";
import * as S from "./styles";
import { GestorPetAPI } from "../gestor-pet/gestorPetAPI";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPet } from "../../app/store/authSlice";

export const QRReader = ({ add }) => {
  const [val, setVal] = useState("");
  const { updatePet } = GestorPetAPI();
  const pet = useSelector(getPet);
  const navigate = useNavigate();
  const onRead = (value) => {
    if (add) {
      pet.qrCodes.push(value.split("6e.ae/")[1]);
      updatePet(pet._id, pet);
      navigate("/gestor-pet/" + pet._id);
      return;
    }

    window.location.href = value;
  };

  return (
    <S.Container>
      <QrCodeReader
        delay={800}
        width={window.innerWidth}
        height={window.innerHeight}
        action={onRead}
        videoConstraints={{ facingMode: "environment" }}
      />
    </S.Container>
  );
};
