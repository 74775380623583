import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAPI, useAPITag } from "../../services/api";
import { auth } from "../../features/admin/login/loginSlice";

const useLogin = () => {
  const api = useAPI();
  const { http } = useAPITag();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const run = async (data) => {
    try {
      const response = await http.post("admin/auth", data);

      dispatch(
        auth({
          token: response.data,
          user: response.data,
        })
      );

      navigate("/admin");
    } catch (error) {
      Swal.fire({
        title: "Usuário ou senha incorretos",
        icon: "error",
      });

      return;
      if (error.response.status === 400) {
        Swal.fire({
          title: "Usuário ou senha incorretos",
          icon: "error",
        });
        return;
      }
    }
  };
  return run;
};

export default useLogin;
