import { MaskedInput } from "antd-mask-input";
import ConfirmCodeAPI from "./ConfirmCodeAPI";
import * as S from "./styles";
import { Form } from "antd";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import PinField from "react-pin-field";
import { useSelector } from "react-redux";
import { selectRegisterstate } from "../RegisterPetSlice";

export const ConfirmCodeEmail = ({ recover }) => {
  const registerState = useSelector(selectRegisterstate);
  const { CheckCode } = ConfirmCodeAPI();
  const [inputDisabled, setInputDisabled] = useState(true);
  const [code, setCode] = useState(true);

  const handleCheck = () => {
    CheckCode(code, recover ? true : false);
  };

  console.log(registerState);
  return (
    <RegisterPetLayout
      hasHeader
      description={
        <span>Insira o código de 6 dígitos que enviamos para o seu email</span>
      }
      title="Confirme o código"
      size={5}
      step={5}
    >
      <S.InputWrapper>
        <PinField
          pattern="[0-9]*"
          inputmode="numeric"
          onChange={(e) => {
            if (e.length > 5) {
              setInputDisabled(false);
              setCode(e);
              return;
            }
            setInputDisabled(true);
          }}
          length={6}
        />
      </S.InputWrapper>
      <S.SubmitButton
        onClick={handleCheck}
        disabled={inputDisabled}
        type="primary"
        htmlType="submit"
        style={{ backgoundColor: "#f67e48" }}
      >
        Confirmar Código
      </S.SubmitButton>
    </RegisterPetLayout>
  );
};
