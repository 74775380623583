import React from "react";
import { Controller } from "react-hook-form";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyledWrapper } from "./styles";
import { ClockCircleOutlined } from '@ant-design/icons';
import ptBR from 'date-fns/locale/pt-BR'; // Importando o locale desejado

registerLocale('pt-BR', ptBR);

const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
  <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
    <input 
      style={{
        width: '100%',
        padding: '12px 16px',
        border: '2px solid #E6E8EC',
        borderRadius: '8px',
        fontSize: '16px',
        color: '#333',
        '&:focus': {
          borderColor: '#F67E48',
        },
      }}
      onClick={onClick} 
      value={value} 
      ref={ref} 
      placeholder={placeholder}
      readOnly
    />
    <ClockCircleOutlined style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#B1B5C4' }} />
  </div>
));

const CustomTimePicker = ({ control, name, placeholder }) => {
  return (
    <StyledWrapper>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, onBlur, ref } }) => (
          <ReactDatePicker
            onChange={onChange}
            selected={value}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Hora"
            dateFormat="HH:mm"
            placeholderText={placeholder}
            className="custom-timepicker"
            ref={ref}
            locale="pt-BR"
            customInput={<CustomInput />}
          />
        )}
      />
    </StyledWrapper>
  );
};

export default CustomTimePicker;
