import Styled from "styled-components";

export const Layout = Styled.main`
  display:flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  padding: none;
  margin: none;
`;
