import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as S from "./styles";
import { Badge, Card, Col, Row, Spin, Tag } from "antd";
import { useSelector } from "react-redux";
import {
  selectConfig,
  selectRegisterstate,
} from "../../register-pet/RegisterPetSlice";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  WhatsAppOutlined,
} from "@ant-design/icons";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";

import { WhatsappButton } from "../../../components/WhatsappButton";
import { selectSimPet, selectSimState } from "../simulator.slice";
import { ModalAuth } from "../../../components/modalAuth";
import { petFeatures, petFeaturesProfile } from "../../register-pet/register-pet/RegisterPetMock";

const ProfilePetSimulated = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [scanned, setScanned] = useState(searchParams.get("scanned"));
  const registerState = useSelector(selectRegisterstate);
  const {name, phone} = useSelector(selectSimState)
  const pet = useSelector(selectSimPet)
  const config = useSelector(selectConfig);
  useEffect(() => {
    setSearchParams([]);
  }, []);

  const vacinas = [
    { value: "vacinaFivFelv", label: "Vacina FivFelv" },
    { value: "vacinaAntirrabica", label: "Vacina Antirrábica" },
    { value: "vacinaLeishmaniose", label: "Vacina Leishmaniose" },
    { value: "vacinaV3V4", label: "Vacina V3 V4" },
    { value: "vacinaV8", label: "Vacina V8" },
    { value: "vermifugado", label: "Vermifugado" },
  ];



  const calculateAge = (dateBirth) => {
    var dob = new Date(dateBirth);
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = Math.abs(year - 1970);

    return age;
  };

  return !registerState.config.logo ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large"></Spin>
    </div>
  ) : (
    <RegisterPetLayout
      hasHomeHeader
      style={{ marginTop: "0.5rem", marginBottom: "50px" }}
      maxHeight
      size={5}
      step={5}
    >
      <WhatsappButton></WhatsappButton>
      <ModalAuth></ModalAuth>
      <S.TitleWrapper>
        <S.PetName>{pet.nome}</S.PetName>

        <Tag color={pet.gender == "macho" ? "blue" : "magenta"}>
          {pet.gender == "macho" ? "Macho" : "Fêmea"}
        </Tag>
      </S.TitleWrapper>

    
        <S.TitleWrapper>
          <S.TutorName>{name}</S.TutorName>
        </S.TitleWrapper>
      
      <S.Avatar url={pet.avatar}></S.Avatar>
      <S.WhatsappButton
        theme={config}
        onClick={() => {
          window.open(`https://wa.me/+55${phone.replace(/\D/g, "")}`);
        }}
      >
        Whatsapp
      </S.WhatsappButton>
      <S.CallButton
        theme={config}
        onClick={() => {
          window.open(`tel:+55${phone.replace(/\D/g, "")}`);
        }}
      >
        Ligar
      </S.CallButton>
      <S.AboutWrapper style={{ paddingLeft: "1.2rem" }}>
        <S.TitleWrapper>
          <S.AboutPet>Sobre o Pet</S.AboutPet>
        </S.TitleWrapper>
        <Row gutter={[48, 12]} justify={"space-evenly"}>
          <Col span={6}>
            <S.SubAbout>Idade</S.SubAbout>
            <S.PetInfo>{calculateAge(pet.birthDate)} anos</S.PetInfo>
          </Col>

          <Col span={6}>
            <S.SubAbout>Raça</S.SubAbout>
            <S.PetInfo>{pet.breed}</S.PetInfo>
          </Col>

          <Col span={6}>
            <S.SubAbout>Espécie</S.SubAbout>
            <S.PetInfo>
              {pet.species === "cachorro" || pet.species === "dog"
                ? "Cachorro"
                : "Gato"}
            </S.PetInfo>
          </Col>
          <Col span={6}>
            <S.SubAbout>Adoção?</S.SubAbout>
            <S.PetInfo>{pet.adoption ? "Sim" : " Não"}</S.PetInfo>
          </Col>
        </Row>
      </S.AboutWrapper>

      <S.AboutWrapper style={{ paddingLeft: "1.2rem" }}>
        <S.TitleWrapper>
          <S.AboutPet>Saúde</S.AboutPet>
        </S.TitleWrapper>
        <Row
          gutter={[48, 12]}
          style={{ paddingLeft: "1.2rem" }}
          justify={"space-evenly"}
        >
          {vacinas.map((e) => {
            if (!pet[e.value]) {
              return "";
            }
            return pet[e.value].ativo ? (
              <Tag icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}>
                {e.label}
              </Tag>
            ) : (
              ""
            );
          })}
        </Row>
      </S.AboutWrapper>

      <S.AboutWrapper style={{ paddingLeft: "1.2rem", marginBottom: "4rem" }}>
        <S.TitleWrapper>
          <S.AboutPet>Características</S.AboutPet>
        </S.TitleWrapper>
        <Row
          gutter={[48, 12]}
          style={{ paddingLeft: "1.2rem" }}
          justify={"space-evenly"}
        >
          {petFeaturesProfile.map((e) => {
            if (!pet[e.value]) {
              return "";
            }
            return pet[e.value] >= 4 ? (
              <Tag icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}>
                {e.label}
              </Tag>
            ) : (
              ""
            );
          })}
        </Row>
      </S.AboutWrapper>

      {pet.notes.length > 0 && (
        <S.AboutWrapper style={{ paddingLeft: "1.2rem", marginBottom: "4rem" }}>
          <S.TitleWrapper>
            <S.AboutPet>Observações</S.AboutPet>
          </S.TitleWrapper>
          <Row
            gutter={[48, 12]}
            style={{ paddingLeft: "1.2rem" }}
            justify={"space-evenly"}
          >
            <Card>{pet.notes}</Card>
          </Row>
        </S.AboutWrapper>
      )}
    </RegisterPetLayout>
  );
};

export default ProfilePetSimulated;
