import Title from "../../../../components/Title";
import SubTitle from "../../../../components/Subtitle";

import { PAYMENTS, ENUM_PAYMENT } from "../../../../context/DonationContext";
import * as S from "./styles";
import { DoarAPI } from "../doarAPI";
import {
  getDonationValue,
  getSelectedPaymentMethod,
} from "../../../donate.slice";
import { useSelector } from "react-redux";

import { CreditCardOutlined, QrcodeOutlined } from "@ant-design/icons";

const SelectPayment = ({ onDonation, onStep, step }) => {
  const {
    changePayment,
    changeDonationValue,
    goToStep,
    changeDonationFrequency,
    donationFrequency,
  } = DoarAPI();
  const donationValue = useSelector(getDonationValue);
  const typePayment = useSelector(getSelectedPaymentMethod);
  const onSelect = (type) => {
    changePayment(type);
  };

  const toPayment = () => {
    if (typePayment === PAYMENTS.CREDIT_CARD) {
      goToStep("/pagamento-cartao");

      return;
    }

    goToStep("/detalhes-pagamento");
  };

  const onFrequencyChange = () => {
    const newFrequency = donationFrequency === "once" ? "recurrent" : "once";
    changeDonationFrequency(newFrequency);
  };

  return (
    <>
      <S.SectionTitle>
        <Title>Escolha o pagamento</Title>
        <S.WrapperValueDonation>
          <S.SectionInfo>
            <S.SubTitleSection>Valor a ser doado</S.SubTitleSection>
            <S.WrapperMoney>
              <S.DonationTag>{donationValue}</S.DonationTag>
              <S.LinkChange
                onClick={() => changeDonationValue("/selecionar-pagamento")}
              >
                Alterar
              </S.LinkChange>
            </S.WrapperMoney>
          </S.SectionInfo>
          <S.SectionInfo>
            <S.SubTitleSection>Forma de pagamento</S.SubTitleSection>
            <S.DonationTag>{ENUM_PAYMENT[typePayment]}</S.DonationTag>
          </S.SectionInfo>
        </S.WrapperValueDonation>
      </S.SectionTitle>
      <S.SectionTypePayment>
        <SubTitle>Escolha o meio de pagamento</SubTitle>
        <S.CardTypePayment
          onClick={() => onSelect(PAYMENTS.PIX_PAYMENT)}
          styled={typePayment === PAYMENTS.PIX_PAYMENT && "selected"}
        >
          <S.IconTextContainer
            styled={typePayment === PAYMENTS.PIX_PAYMENT && "selected"}
          >
            <QrcodeOutlined style={{ fontSize: "25px" }} />
            <p>Pix</p>
          </S.IconTextContainer>
          <S.RadialIndicator
            styled={typePayment === PAYMENTS.PIX_PAYMENT && "selected"}
          />
        </S.CardTypePayment>
        <S.CardTypePayment
          onClick={() => onSelect(PAYMENTS.CREDIT_CARD)}
          styled={typePayment === PAYMENTS.CREDIT_CARD && "selected"}
        >
          <S.IconTextContainer
            styled={typePayment === PAYMENTS.CREDIT_CARD && "selected"}
          >
            <CreditCardOutlined style={{ fontSize: "25px" }} />
            <p>Cartão de Credito</p>
          </S.IconTextContainer>
          <S.RadialIndicator
            styled={typePayment === PAYMENTS.CREDIT_CARD && "selected"}
          />
        </S.CardTypePayment>
      </S.SectionTypePayment>

      <S.SectionFrequencyPayment>
        <S.SectionFrequencyPaymentTitle>
          <S.FrequencyTitle>Frequência de doação</S.FrequencyTitle>
          <S.FrequencyDescription>
            Marque abaixo para fazer doações recorrentes todo mês.
          </S.FrequencyDescription>
        </S.SectionFrequencyPaymentTitle>
        <S.CardFrequencyPayment
          onClick={() =>
            changeDonationFrequency(
              donationFrequency === "once" ? "recurrent" : "once"
            )
          }
          styled={donationFrequency === "recurrent" && "selected"}
        >
          <S.IconTextContainer2>
            <p>Doação recorrente</p>
            <span>Cobrança mensal</span>
          </S.IconTextContainer2>
          <S.StyledCheckbox
            checked={donationFrequency === "recurrent"}
            onChange={onFrequencyChange}
          />
        </S.CardFrequencyPayment>
      </S.SectionFrequencyPayment>

      <S.WrapperButton>
        <S.SubmitButton fullWidth onClick={() => toPayment()}>
          Avançar
        </S.SubmitButton>
        <S.Description>
          AjudaPet Soluções para a Causa Animal© Copyright - Todos os direitos
          reservados.2021 CNPJ: 31.038.058/0001-58
        </S.Description>
      </S.WrapperButton>
    </>
  );
};

export default SelectPayment;
