import styled from "styled-components";
import media from "styled-media-query";

export const Banner = styled.div`
  position: relative;
  height: 200px;
  border-radius: 16px;
  cursor: pointer;

  button {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  ${media.lessThan("medium")`
    display: none;
  `}
`;

export const Imagem = styled.img`
  object-fit: cover;
  border-radius: 16px;
  height: 100%;
  width: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  border-radius: 16px;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

// const Botao = styled.button`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: #fff;
//   color: #000;
//   font-size: 1.2rem;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   z-index: 2;
// `;

export const Upload = styled.label`
  input[type="file"] {
    display: none;
  }

  width: 258px;
  display: flex;
  justify-content: center;
  border: 3px solid #f67e48;
  border-radius: 50px;
  margin-right: 1rem;
  padding: 6px 12px;
  background-color: #f67e48;
  cursor: pointer;
  color: white;
`;
