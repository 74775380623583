import { useSelector } from "react-redux";
import { useAPITag } from "../../../services/api";
import { getUser } from "../../../app/store/authSlice";

export const PetsAPI = () => {
  const { http } = useAPITag();
  const user = useSelector(getUser);

  const getPets = async () => {
    try {
      const response = await http.get("pets/user/" + user._id);
      return response.data;
    } catch (error) {}
  };

  return { getPets };
};
