import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAPI, useAPIPet, useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  saveState,
  selectConfig,
  selectRegisterstate,
  setCode,
  setPhone,
} from "../RegisterPetSlice";

const SelectPetAPI = () => {
  const { http } = useAPITag();
  const api = useAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const registerState = useSelector(selectRegisterstate);

  const selectPet = (id) => async () => {
    try {
      const response = await http.patch(
        `/pets/active-tag/${id}/${registerState.code}`
      );
      dispatch(saveState());
      navigate("/success");
    } catch (error) {
      throw error;
    }
  };

  return { selectPet };
};

export default SelectPetAPI;
