import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useAPIPet, useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  saveState,
  setCode,
  setConfig,
  setPhone,
  setWhatsappStatus,
} from "../RegisterPetSlice";
import { getUser, isLoggedIn } from "../../../app/store/authSlice";

const AuthPhoneApiPre = () => {
  const tagApi = useAPITag();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedIn);
  const user = useSelector(getUser);
  const AuthPhone = async (data) => {
    try {
      const phone = data.telefone.replace(/\D/g, "");
      dispatch(
        setPhone({
          phone: data.ddd + phone,
          maskedPhone: data.telefone,
          grantType: data.grantType,
        })
      );
      if (data.grantType === "whatsapp") {
        const whatsappCodeDTO = {
          grantType: "whatsapp",
          tokenType: "auth-code",
          phone: data.ddd + phone,
          userId: data.ddd + phone,
        };
        await tagApi.http.post("/user-token/whatsapp", whatsappCodeDTO);
        dispatch(saveState());

        navigate(`/confirm-code/${id}`);
      } else {
        await tagApi.http.post("/usuario/verify", {
          phone: data.ddd + phone,
        });
        dispatch(saveState());
        navigate(`/confirm-code/${id}`);
      }
    } catch {
      Swal.fire({ title: "Email já cadastrado!", icon: "error" });
    }
  };

  const ValidateTag = async (code) => {
    dispatch(setCode({ code }));
    try {
      const perfilResponse = await tagApi.http.get("tags/" + code);
      if (perfilResponse.data.hasPet) {
        navigate("/profile/" + code + "?scanned=true");
        return;
      }
      if (window.location.hostname === "6e.ae") {
        window.location.href = "https://ajuda.pet/6e" + code;
      }
      dispatch(setConfig(perfilResponse.data.config));
      const statusResponse = await tagApi.http.get("whatsapp-status");

      dispatch(setWhatsappStatus(statusResponse.data.status));

      if (isLogged) {
        const petsResponse = await tagApi.http.get("pets/user/" + user._id);

        if (petsResponse.data.length > 0) {
          navigate("/select-pet");
          return;
        }
        navigate("/register-pet");
      }
    } catch {
      console.log("ValidateTag error");
    }
  };

  const ValidatePreTag = async (code) => {
    dispatch(setCode({ code }));
    try {
      const perfilResponse = await tagApi.http.get("tags/" + code);
    console.log(code)
      if(perfilResponse.data.tag.statusQr === 'pending' && (perfilResponse.data.tag.messageStatusQr === 'simp1' || perfilResponse.data.tag.messageStatusQr === 'simp2' || perfilResponse.data.tag.messageStatusQr === 'ok' || perfilResponse.data.tag.messageStatusQr === "")){
        navigate(`/finalRegister/${code}`)
        return
      }
    } catch {
      console.log("ValidateTag error");
    }
  };

  return { ValidateTag, AuthPhone, ValidatePreTag };
};

export default AuthPhoneApiPre;
