import { MaskedInput } from "antd-mask-input";
import * as S from "./styles";
import { Checkbox, Form } from "antd";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RegisterPetLayout from "../../../components/layouts/RegisterPetLayout";
import RegisterUserAPI from "./RegisterUserAPI";

export const RegisterUser = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { CreateUser } = RegisterUserAPI();
  const [inputDisabled, setInputDisabled] = useState(true);

  const onFinishFailed = () => {
    Swal.fire({
      title: "Preencha todos os campos",
      icon: "error",
    });
  };

  const onChange = (e) => {
    if (e.unmaskedValue.length > 10) {
      setInputDisabled(false);
    } else {
      setInputDisabled(true);
    }
  };

  return (
    <RegisterPetLayout
      hasHeader
      description="Insira suas informações para reconhecermos você como tutor(a) do pet."
      title="Preencha seus dados"
      size={5}
      step={3}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        labelAlign="top"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{ width: "80%" }}
        initialValues={{ remember: true }}
        onFinish={CreateUser}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="nome"
          label="Nome"
          rules={[{ required: true, message: "Informe o seu nome" }]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          name="sobrenome"
          label="Sobrenome"
          rules={[{ required: true, message: "Informe o seu sobrenome" }]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: "Informe o seu email", type: "email" },
          ]}
        >
          <S.StyledInput />
        </Form.Item>
        <Form.Item
          initialValue={""}
          label={"Senha"}
          rules={[
            {
              type: "text",
              min: 8,
            },
            { required: true },
          ]}
          name="password"
        >
          <S.StylePassInput />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 12 }}
          labelAlign="left"
          valuePropName="checked"
          name="terms"
          label={"Termos"}
          rules={[
            { required: true, message: "Aceite os termos", type: "boolean" },
          ]}
        >
          <Checkbox>
            {" "}
            <span>
              Eu aceito{" "}
              <a href="/legal" target="_blank">
                os termos de uso
              </a>{" "}
              e{" "}
              <a href="/privacy" target="_blank">
                Politicas de privacidade
              </a>
            </span>
          </Checkbox>
        </Form.Item>
      </Form>

      <S.SubmitButton
        onClick={() => form.submit()}
        type="primary"
        htmlType="submit"
      >
        Avançar
      </S.SubmitButton>
    </RegisterPetLayout>
  );
};
