import { Button, Input } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f67e48;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  width: 440px;
  min-height: 550px;
  background-color: white;
  border: 1px solid #f67e48;
  box-shadow: 0 1px 5px #f67e48;
  border-radius: 25px;
  padding: 1.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  height: 50px;
  border-radius: 50px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  border-radius: 50px;
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
`;
