import React from "react"

const LockedIcon = ({
  title = 'LockedIcon',
  titleId = 'locked-icon',
  color = '#777E90',
  height = 16,
  width = 16,
  ...props
}) => (
  <svg
    height={height}
    width={width}
    viewBox={`0 0 ${height} ${width}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 7.33333C6.81828 7.33333 5.76562 7.37637 4.90558 7.43104C4.08981 7.4829 3.46253 8.10532 3.40398 8.91533C3.36233 9.49155 3.33333 10.0987 3.33333 10.6667C3.33333 11.2346 3.36233 11.8418 3.40398 12.418C3.46253 13.228 4.08981 13.8504 4.90559 13.9023C5.76562 13.957 6.81828 14 8 14C9.18172 14 10.2344 13.957 11.0944 13.9023C11.9102 13.8504 12.5375 13.228 12.596 12.418C12.6377 11.8418 12.6667 11.2346 12.6667 10.6667C12.6667 10.0987 12.6377 9.49155 12.596 8.91533C12.5375 8.10532 11.9102 7.4829 11.0944 7.43104C10.2344 7.37637 9.18172 7.33333 8 7.33333ZM4.821 6.10039C3.34251 6.19437 2.18092 7.34158 2.07412 8.81921C2.03101 9.41559 2 10.0566 2 10.6667C2 11.2767 2.03101 11.9177 2.07412 12.5141C2.18092 13.9918 3.34251 15.139 4.821 15.2329C5.70519 15.2891 6.78631 15.3333 8 15.3333C9.2137 15.3333 10.2948 15.2891 11.179 15.2329C12.6575 15.139 13.8191 13.9918 13.9259 12.5141C13.969 11.9177 14 11.2767 14 10.6667C14 10.0566 13.969 9.41559 13.9259 8.81921C13.8191 7.34158 12.6575 6.19437 11.179 6.10039C10.2948 6.04419 9.21369 6 8 6C6.78631 6 5.70519 6.04419 4.821 6.10039Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.6665 11.155C9.06504 10.9245 9.33317 10.4936 9.33317 10.0001C9.33317 9.2637 8.73622 8.66675 7.99984 8.66675C7.26346 8.66675 6.6665 9.2637 6.6665 10.0001C6.6665 10.4936 6.93464 10.9245 7.33317 11.155V12.0001C7.33317 12.3683 7.63165 12.6667 7.99984 12.6667C8.36803 12.6667 8.6665 12.3683 8.6665 12.0001V11.155Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6665 4.00008C4.6665 2.15913 6.15889 0.666748 7.99984 0.666748C9.84079 0.666748 11.3332 2.15913 11.3332 4.00008V6.66675C11.3332 7.03494 11.0347 7.33342 10.6665 7.33342C10.2983 7.33342 9.99984 7.03494 9.99984 6.66675V4.00008C9.99984 2.89551 9.10441 2.00008 7.99984 2.00008C6.89527 2.00008 5.99984 2.89551 5.99984 4.00008V6.66675C5.99984 7.03494 5.70136 7.33342 5.33317 7.33342C4.96498 7.33342 4.6665 7.03494 4.6665 6.66675V4.00008Z" fill={color}/>
  </svg>
)

export default LockedIcon