import Styled, { css } from "styled-components";

export const Wrapper = Styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperBanner = Styled.div`
  ${({ image }) => css`
    position: absolute;
    width: 1280px;
    height: 286px;
    position: absolute;
    top: 100px;

    background-image: url(${image});
  `}
`;
