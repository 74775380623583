// import LogoImage from '../Icons/LogoImage'

import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo_Laranja.svg";
// import SearchBar from "../SearchBar";
import MessageIcon from "../Icons/MessageIcon";
import Profile, { MenuItems } from "../Profile";
import BellIcon from "../Icons/BellIcon";
import BurgerIcon from "../Icons/BurgerIcon";
import MenuItem from "../MenuItem";
import { Drawer, Button } from "antd";
import * as S from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isLoggedIn, openModal } from "../../app/store/authSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { selectConfig } from "../../features/register-pet/RegisterPetSlice";
import { useMobileDetect } from "../../hooks/useMobileDetect";

const Header = ({ bgColor, searchBar = true, isProfile }) => {
  const [open, setOpen] = useState(false);
  const loggedIn = useSelector(isLoggedIn);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useMobileDetect();
  const closeDrawer = () => {
    setOpen(false);
  };

  const config = useSelector(selectConfig);

  const openConfig = () => {
    window.location.pathname = "/config-user";
  };
  const onDrawer = () => {
    return setOpen((prev) => !prev);
  };

  const menuItems = MenuItems();
  return (
    <S.StyledHeadContainer isMobile={isMobile} bgColor={bgColor}>
      <S.StyledHorizoalWrapper>
        <div className="w-full h-full flex items-center justify-center lg:justify-start lg:px-6">
          <Link className=" w-auto h-[35px] cursor-pointer" to="/">
            <img
              className="w-full h-full object-contain cursor-pointer"
              src={config.logo ?? logo}
              alt="Logo Gestor Pet"
            />
          </Link>
        </div>
      </S.StyledHorizoalWrapper>

      {loggedIn && (
        <S.WrapperSectionMenu>
          <S.StyledElementWrapper></S.StyledElementWrapper>
          <S.StyledElementWrapper style={{ display: "none" }}>
            <S.StyledHeadButton>
              <MessageIcon />
            </S.StyledHeadButton>
            <S.StyledHeadButton>
              <BellIcon />
            </S.StyledHeadButton>
          </S.StyledElementWrapper>
          <S.WrapperProfile>
            <Profile userName={user.name} />
          </S.WrapperProfile>
        </S.WrapperSectionMenu>
      )}
    </S.StyledHeadContainer>
  );
};

export const HeaderHome = ({ bgColor, searchBar = true, isProfile }) => {
  const [open, setOpen] = useState(false);
  const loggedIn = useSelector(isLoggedIn);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useMobileDetect();
  const closeDrawer = () => {
    setOpen(false);
  };

  const config = useSelector(selectConfig);

  const openConfig = () => {
    window.location.pathname = "/config-user";
  };
  return (
    <S.StyledHeadContainer isMobile={isMobile} bgColor={bgColor}>
      <S.StyledHorizoalWrapper>
        <div className="w-full h-full flex items-center justify-between px-5">
          <Link className=" w-auto h-[35px] cursor-pointer" to="/">
            <img
              className="w-full h-full object-contain cursor-pointer"
              src={config.logo ?? logo}
              alt="Logo Gestor Pet"
            />
          </Link>
          <button
            className="text-white lg:text-sm px-4 py-2 text-xs font-bold bg-[#FF6E30] rounded-md lg:px-4 lg:py-2"
            onClick={() => {
              if (loggedIn) {
                console.log();
                openConfig();
                return <Navigate to={"/config-user"}></Navigate>;
              }

              dispatch(openModal());
              closeDrawer();
            }}
          >
            Login
          </button>
        </div>
      </S.StyledHorizoalWrapper>

      {loggedIn && (
        <S.WrapperSectionMenu>
          <S.StyledElementWrapper></S.StyledElementWrapper>
          <S.StyledElementWrapper style={{ display: "none" }}>
            <S.StyledHeadButton>
              <MessageIcon />
            </S.StyledHeadButton>
            <S.StyledHeadButton>
              <BellIcon />
            </S.StyledHeadButton>
          </S.StyledElementWrapper>
          <S.WrapperProfile>
            <Profile userName={user.name} />
          </S.WrapperProfile>
        </S.WrapperSectionMenu>
      )}
    </S.StyledHeadContainer>
  );
};

export const GestorPetDrawer = ({
  bgColor,
  searchBar = true,
  closeDrawer,
  open,
}) => {
  const loggedIn = useSelector(isLoggedIn);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);

  const menuLinks = [
    {
      label: "início",
      href: "/",
    },
    {
      label: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (loggedIn) {
              console.log();
              openConfig();
              return <Navigate to={"/config-user"}></Navigate>;
            }

            dispatch(openModal());
            closeDrawer();
          }}
        >
          Gestor Pet
        </div>
      ),
    },
  ];

  const openConfig = () => {
    window.location.pathname = "/config-user";
  };

  const menuItems = MenuItems();
  return (
    <Drawer
      title={
        <S.StyledHeadLogo
          style={{ width: config.logo ? "85px" : "40px" }}
          src={config.logo ?? logo}
        />
      }
      width="85%"
      placement="right"
      onClose={closeDrawer}
      open={open}
    >
      <S.WrapperMenuMobile>
        {menuLinks?.map((option) => (
          <S.MenuItem to={option.href}>{option.label}</S.MenuItem>
        ))}
        {loggedIn ? <MenuItem links={menuItems} /> : ""}
      </S.WrapperMenuMobile>
    </Drawer>
  );
};

export default Header;
