import { Button, Input } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  width: 440px;
  min-height: 100vh;
  background-color: white;
  border-radius: 25px;
  padding: 1.4rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Tutor = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const AboutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const PetName = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin-left: 1rem;
  margin-right: 2rem;
  line-height: 40px;
  /* identical to box height, or 125% */

  letter-spacing: -0.01em;

  /* Neutrals/2 */

  color: #23262f;
`;

export const TutorName = styled.h1`
  margin-left: 1rem;
  margin-right: 2rem;
  font-family: Readex Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #777e90;
  margin-top: -1rem;
`;

export const WhatsappButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;
  color: white;
  width: 92%;
  height: 48px;
  background: ${({ theme }) => theme.primaryColor} !important;
  border-radius: 90px;
  margin-top: 2rem;
  &:hover {
    color: white !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
`;

export const WhatsappFloatingButton = styled(Button)`
  display: flex;
  position: fixed;
  bottom: 15px;
  color: white !important;
  border-color: white !important;
  /* -webkit-box-pack: center; */
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px 24px;
  /* gap: 12px; */
  color: white;
  width: 120px;
  height: 60px;
  border-radius: 90px;
  z-index: 50;
  right: 20px;
  /* margin-top: 2rem; */
  background: #128c7e !important;
`;

export const CallButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;
  color: ${({ theme }) => theme.primaryColor};
  width: 92%;
  height: 48px;
  background: white !important;
  border-color: ${({ theme }) => theme.primaryColor} !important;
  border-radius: 90px;
  margin-top: 2rem;
  &:hover {
    color: ${({ theme }) => theme.primaryColor} !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
`;

export const AboutPet = styled.h1`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  /* identical to box height, or 93% */
  margin-top: 3rem;
  display: flex;
  align-items: center;

  color: #2c2d42;
`;

export const SubAbout = styled.h1`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 9.61772px;
  line-height: 19px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  /* Neutrals 1 */

  color: #141416;
`;
export const PetInfo = styled.h1`
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14.4266px;
  line-height: 19px;
  /* identical to box height, or 133% */

  /* Neutrals 4 */

  color: #777e91;
`;

export const Avatar = styled.div`
  height: 80vw !important;
  width: 80vw !important;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;

  background-image: url(${(props) => props.url});

  @media screen and (min-width: 320px) {
    width: 300px !important;
    height: 440px !important;
    min-height: 320px;
    padding-top: 1rem;
  }

  @media screen and (min-width: 375px) {
    width: 320px !important;
    height: 440px !important;
    min-height: 375px;
    padding-top: 1rem;
  }

  @media screen and (min-width: 440px) {
    /* width: 440px !important; */
    height: 440px !important;
    min-height: 440px;
    padding-top: 1rem;
  }
`;
export const StyledInput = styled(Input)`
  height: 50px;
  border-radius: 50px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  border-radius: 50px;
`;

export const SubmitButton = styled(Button)`
  border-radius: 50px;
  height: 50px;
`;
