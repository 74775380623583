import { createSlice } from "@reduxjs/toolkit";
import { redirect } from "react-router-dom";

export const donateSlice = createSlice({
  name: "donate",
  initialState: () =>
    localStorage.getItem("donate") !== null
      ? JSON.parse(localStorage.getItem("donate"))
      : {
          donationValue: "0.00",
          previousStep: "/selecionar-pagamento",
          payer: {
            _id: "",
            nome: "",
            email: "",
          },
          selectedPayment: "PIX",
          donationFrequency: "once",
        },
  reducers: {
    selectPaymentMethod: (state, action) => {
      state.selectedPayment = action.payload;
    },
    setPayer: (state, action) => {
      state.payer = action.payload;
    },
    setDonationValue: (state, action) => {
      state.donationValue = action.payload;
    },
    setPreviousStep: (state, action) => {
      state.previousStep = action.payload;
    },
    setDonationFrequency: (state, action) => {
      state.donationFrequency = action.payload;
    },
    setCustomLinkValue: (state, action) => {
      state.customLink = action.payload;
    },
  },
});

export const getDonationPayer = (state) => state.donate.payer;
export const getSelectedPaymentMethod = (state) => state.donate.selectedPayment;
export const getDonationValue = (state) => state.donate.donationValue;
export const getPreviuosStep = (state) => state.donate.previousStep;
export const getDonationFrequency = (state) => state.donate.donationFrequency;
export const getCustomLink = (state) => state.donate.customLink;

export const {
  selectPaymentMethod,
  setPayer,
  setDonationValue,
  setPreviousStep,
  setDonationFrequency,
  setCustomLinkValue,
} = donateSlice.actions;

export default donateSlice.reducer;
