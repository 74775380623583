import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAPIPDF, useAPIPet, useAPITag } from "../../services/api";
import { setConfig } from "../../features/register-pet/RegisterPetSlice";

const useGetPet = () => {
  const { http } = useAPITag();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const run = async (id) => {
    try {
      if (id.length > 6) {
        const response = await http.get("pets/id/" + id);
        dispatch(setConfig(response.data.config));

        return [response.data];
      }
      const response = await http.get("pets/" + id);
      if (response.data.length > 0) {
        dispatch(setConfig(response.data[0].config));
      } else {
        navigate("/");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return run;
};

export default useGetPet;
