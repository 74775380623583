import { useAPITag } from "../../services/api";

export const GeolocationApi = () => {
  const { http } = useAPITag();

  const translate = async (lat, lng) => {
    const response = await http.get(`location-logs/translate/${lat}/${lng}`);

    return response.data;
  };

  const logLatLng = async (code, lat, lng) => {
    const address = await translate(lat, lng);
    const data = { code, address };

    return http.post("/location-logs", data);
  };

  return { logLatLng, translate };
};
