import React, { useState } from "react";
import * as S from "./stylesBanner";
import AvatarEditor from "react-avatar-editor";
import Button from "../Button";
import { Modal, Slider  } from "antd";
import { Col, Progress, notification } from "antd";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { useAPITag } from "../../services/api";
import { ConfigUserAPI } from "../FormConfigUser/ConfigUserAPI";
import EditIcon from "../../assets/Line.svg";
import { set } from "react-hook-form";

const ChangeBanner = ({ link, text, ...props }) => {
  const user = useSelector(getUser);
  const { http } = useAPITag();
  const { updateUser } = ConfigUserAPI();
  const [modalVisible, setModalVisible] = useState(false);
  const [editor, setEditor] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [editedImageDataURL, setEditedImageDataURL] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState(user && user.capa ? user.capa : "https://www.primegattai.com.br/estruturasmetalicas/wp-content/uploads/2020/05/Laranja.jpg");




  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalOk = async () => {
    const canvas = await editor.getImage();
    const dataURL = canvas.toDataURL();
    console.log(dataURL);
    setEditedImageDataURL(dataURL);
  
      const response = await uploadFilePromise(canvas, 1, 1, selectedImage.name, (percentage) => {
        openNotification(1, 1, selectedImage.name, percentage);
      });
      console.log(response);
      setModalVisible(false);
      setSelectedImage(null);
      setZoom(1);
      window.location.reload();
};

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedImage(null);
    setZoom(1);
  };

  const handleZoomChange = (value) => {
    setZoom(value);
  };

  const getTag = () => {
    function makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    }

    return makeid(16);
  };

  const NOTIFY_ID = getTag();

  const onChangeFiles = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };


  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  const uploadFilePromise = (canvas, index, totalFiles, name) => {
    return new Promise((resolve, reject) => {
      // Convertendo a imagem do canvas para Blob
      canvas.toBlob((blob) => {
        const bodyFormData = new FormData();
        bodyFormData.append("files", blob, name);
        bodyFormData.append("companyId", user._id);
        bodyFormData.append("filename", name);  // Usando o mesmo nome para o arquivo
        http.post("/files", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            openNotification(totalFiles, index, name, percentage);
          },
        })
        .then((e) => {
          const newFile = e.data;
          console.log(e.data);
          updateUser({ capa: e.data.url });
          resolve(newFile);
        });
      }, "image/png");
    });
  };

  const openNotification = (
    totalFiles,
    filesCount,
    currentFileName,
    currentFileProgress
  ) => {
    notification.open({
      key: NOTIFY_ID,
      message: "Arquivos sendo enviados - " + `${filesCount} / ${totalFiles}`,
      description: (
        <Col>
          <p>Total</p>
          <Progress percent={Math.floor((filesCount / totalFiles) * 100)} />
        </Col>
      ),
    });
  };

  return (
    <>
      <S.Upload onClick={showModal}>
        <img className="w-8" src={EditIcon} alt=""  />
      </S.Upload>
      <Modal
        title="Ajustar Foto"
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Salvar"
        okButtonProps={{ disabled: !selectedImage, style: {
          backgroundColor: selectedImage ? '#f67e48' : 'gray',
        }, }}
      >
        {selectedImage ? (
          <>
            <div style={{ marginBottom: 16 }}>
              <label>Zoom:</label>
              <Slider
                min={1}
                max={2}
                step={0.1}
                value={zoom}
                onChange={handleZoomChange}
              />
            </div>
            <AvatarEditor
              ref={setEditorRef}
              image={selectedImage}
              scale={zoom}
            />
          </>
        ) : (
          <input type="file" {...props} onChange={onChangeFiles} />
        )}
      </Modal>
    </>
  );
};

export default ChangeBanner;






