import { Button, Dropdown, QRCode, Row, Table } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TagsAPI from "./TagsAPI";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Tags = () => {
  const [collars, setCollars] = useState([]);
  const { getAllTags, downloadTags  } = TagsAPI();
  const navigate = useNavigate();
  useEffect(() => {
    getTags();
  }, []);

  const columns = [
    {
      title: "Código",
      render: (row) => `${row.code}`,
    },
    {
      title: "Dominio",
      render: (row) => `${row.path}`,
    },
    {
      title: "Status",
      render: (row) => `${row.pet ? "Ativo" : "Não vinculado"}`,
      filters: [
        {
          text: "Ativo",
          value: "Ativo",
        },
        {
          text: "Não vinculado",
          value: "Não vinculado",
        },
      ],
      onFilter: (value, record) =>
        (record.pet ? "Ativo" : "Não vinculado") === value,
    },

    {
      title: "Data de Criação",
      render: (row) => `${new Date(row.createdAt).toLocaleDateString("pt-BR")}`,
    },

    {
      title: "Data de Ativação",
      render: (row) =>
        `${
          row.pet
            ? new Date(row.pet.createdAt).toLocaleDateString("pt-BR")
            : "Não vinculado"
        }`,
    },
    {
      title: "Ações",
      render: (row, index, column, i) => {
        const items = [
          {
            key: "actions_3_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  MySwal.fire({
                    html: (
                      <Row justify={"center"}>
                        <QRCode value={`${row.path}/${row.code}`}></QRCode>
                      </Row>
                    ),
                  });
                }}
              >
                Visualizar QR
              </Button>
            ),
          },
        ];
        if (row.pet) {
          items.push({
            key: "actions_4_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  window.open(
                    "https://plataforma-v2.ajuda.pet/profile/" + row.code
                  );
                }}
              >
                Visualizar Pet
              </Button>
            ),
          });
        }
        return (
          <Dropdown
            menu={{
              items: [...items],
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const getTags = async () => {
    const response = await getAllTags();
    setCollars(response);
  };

  return (
    <Dashboard selected="4">
      <S.Wrapper>
      <div className="w-full justify-end items-end flex">
        <button onClick={downloadTags} className="w-44 bg-[#f67e4d] py-2 rounded-xl">Baixar todas as tags</button>
      </div>
        <Table
          style={{ width: "80vw" }}
          columns={columns}
          dataSource={collars}
        />
      </S.Wrapper>
    </Dashboard>
  );
};
