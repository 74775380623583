import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRegisterstate } from "../register-pet/RegisterPetSlice";
import { GeolocationApi } from "../../components/geolocation/geolocationAPI";
import Loader from "../../components/Loader";
import { notification } from 'antd';

const SendLocationProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const registerState = useSelector(selectRegisterstate);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(true);
  const { logLatLng } = GeolocationApi();

  useEffect(() => {
    console.log("geolocation");
    const timer = setTimeout(() => {
      navigate("/profile/" + id + "?scanned=true");
    }, 1000);

    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            if (id) {
              logLatLng(
                id,
                position.coords.latitude,
                position.coords.longitude
              );
              clearTimeout(timer);
              navigate("/profile/" + id + "?scanned=true");
            }
            setLoading(false);
          }, (error) => {
            console.log(error);
            clearTimeout(timer);
            navigate("/profile/" + id + "?scanned=true");
            setLoading(false);
          });
        } else if (result.state === 'denied') {
          clearTimeout(timer); 
          navigate("/profile/" + id + "?scanned=true");
          setLoading(false);
        }
      });
    } else {
      clearTimeout(timer);
      navigate("/profile/" + id + "?scanned=true");
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Loader />
    </div> 
  );
};

export default SendLocationProfile;
