import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { useAPIPDF, useAPITag } from "../../services/api";
import { APIFiles } from "./apiFiles";
import * as S from "./styles";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Modal,
  Progress,
  Row,
  Table,
  Upload,
  notification,
} from "antd";
import { EllipsisOutlined, UploadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState } from "../../features/admin/login/loginSlice";
import { getModalIsOpen, setModalFile } from "./filesModalSlice";

const getColor = (props) => {
  console.log(props.isDragAccept);
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  min-heigth: 400px;
  min-width: 500px;
  transition: border 0.24s ease-in-out;
`;

const ImageDiv = styled.div`
  height: 260px;
  width: 260px;
  margin: 1rem;
  border-radius: 15px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  display: flex;
  &:hover {
    background-color: rgba(0, 0, 0, 0.75);
  }
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .container {
    display: flex;
    border-radius: 15px;

    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    &:hover {
      display: flex;
    }
    button {
    }
  }
`;

function FilesModal(props) {
  const [paths, setPaths] = useState([]);
  const [selected, setSelected] = useState([]);
  const { getFiles, uploadFile } = APIFiles();
  const { http } = useAPITag();
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const modalOpen = useSelector(getModalIsOpen);
  const authState = useSelector(selectAuthState);
  const dispatch = useDispatch();
  const uploadFilePromise = (file, index, totalFiles, name) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.append("files", file);
      bodyFormData.append("companyId", authState.user._id);
      bodyFormData.append("filename", file.name);
      bodyFormData.append("type", "gabarit-files");
      http
        .post("/files", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            openNotification(totalFiles, index, name, percentage);
          },
        })
        .then((e) => {
          const newFile = e.data;
          newFile.key = newFile._id;
          paths.push(newFile);

          setPaths([...paths]);
          resolve(newFile);
        });
    });
  };

  const key = "FILES_UPLOAD_NOTICATION";

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (
    totalFiles,
    filesCount,
    currentFileName,
    currentFileProgress
  ) => {
    api.open({
      key,
      message: "Arquivos sendo enviados - " + `${filesCount} / ${totalFiles}`,
      description: (
        <Col>
          <p>Total</p>
          <Progress percent={Math.floor((filesCount / totalFiles) * 100)} />
        </Col>
      ),
    });
  };

  useEffect(() => {
    const loadFiles = async () => {
      const response = await getFiles();

      setPaths(
        response.map((e) => {
          e.key = e._id;
          return e;
        })
      );
    };

    loadFiles();
  }, []);

  const columns = [
    {
      title: "Nome",
      render: (row) => `${row.name}`,
    },
    {
      title: "Ações",
      render: (row, index, column, i) => {
        const items = [
          {
            key: "actions_2_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  Swal.fire({
                    title: "Deseja mesmo deletar esse arquivo",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Deletar",
                    denyButtonText: `Não Deletar`,
                  }).then(async (result) => {
                    if (result) {
                      await http.delete("files/" + row._id);
                      const response = await getFiles();

                      setPaths(
                        response.map((e) => {
                          e.key = e._id;
                          return e;
                        })
                      );
                    }
                  });
                }}
              >
                Deletar
              </Button>
            ),
          },
        ];
        return (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      setSelected(selectedRows);
    },
    getCheckboxProps: (row) => {
      return {
        name: row._id + row.name,
        id: row._id,
      };
    },
    type: "checkbox",
  };

  const onChangeFiles = async (e) => {
    const files = Array.from(e.target.files);
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      openNotification(files.length, i + 1, files[i].name);

      newFiles.push(
        await uploadFilePromise(files[i], i + 1, files.length, files[i].name, 0)
      );
    }

    // setPaths([...paths, ...newFiles]);
  };

  const onCSVFileChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const bodyFormData = new FormData();
      bodyFormData.append("files", file);
      bodyFormData.append("companyId", authState.user._id);
      http
        .post("/collar-preset/import", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((e) => {
          Swal.fire({
            title: "Importação iniciada!",
            icon: "success",
          });
        })
        .catch((e) => {
          Swal.fire({ title: e.response.data.message, icon: "error" });
        });

      e.target.value = [];
    }
  };

  const deleteSelected = async () => {
    await Promise.all(
      selected.map(async (row) => {
        const response = await http.delete("files/" + row._id);
        setPaths(
          response.data.map((e) => {
            e.key = e._id;
            return e;
          })
        );
      })
    );

    setSelected([]);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Arquivos"
        centered
        open={modalOpen}
        onCancel={() => {
          dispatch(setModalFile(false));
        }}
        onOk={() => {
          dispatch(setModalFile(false));
        }}
        width={1000}
      >
        <S.Wrapper>
          <Row>
            <S.Upload>
              <input type="file" multiple onChange={onChangeFiles} />
              Enviar arquivos
            </S.Upload>
            <S.Upload>
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={onCSVFileChange}
              />
              Enviar CSV
            </S.Upload>
            <Button onClick={deleteSelected}>
              Deleter selecionados ({selected.length})
            </Button>
          </Row>
          <Table
            rowSelection={rowSelection}
            pagination={{ pageSize: 100 }}
            columns={columns}
            dataSource={paths}
          />
        </S.Wrapper>
      </Modal>
    </>
  );
}

export default FilesModal;
