import React, { useEffect } from "react";
import * as S from "./styles";
import Security from "./Security";
import EditUser from "./EditUser";
import Address from "./Address";
import Payment from "./Payment";
import Pet from "./Pets";
import { ConfigUserAPI } from "./ConfigUserAPI";

const FormConfigUser = ({ step }) => {
  console.log(step);

  const { getUserData } = ConfigUserAPI();

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <S.Wrapper>
      {step === "pets" && <Pet />}
      {step === "security" && <Security />}
      {step === "perfil" && <EditUser />}
      {step === "endereco" && <Address />}
      {step === "payment" && <Payment />}
    </S.Wrapper>
  );
};

export default FormConfigUser;
