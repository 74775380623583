import CurrencyInput from "react-currency-input-field";
import styled, { css } from "styled-components";
import Styled from "styled-components";

import Button from "../../../components/Button";

const typePaymentModifiers = {
  selected: (color) => css`
    border: 2px solid #f67e48;
    background-color: #fef5f0;

    p {
      color: #192126;
    }
  `,
};

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
`

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b1b5c3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #f67e48;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #f67e48;
  }

  &:checked + ${Slider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export const SwitchLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonSubmit = styled(Button)`
  margin-bottom: 20px;
`;

export const SectionTitle = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const SectionDonation = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    margin-top: 40px;
`;

export const SectionDetails = Styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const Form = Styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 17px;
`;

export const WrapperInputs = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SubmitButton = Styled(Button)`
  margin-bottom: 20px;
`;

export const SectionTypePayment = Styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: center;
    gap: 12px;
    width: 100%;
`;

export const CardTypePayment = Styled.div`
  ${({ theme, styled }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 24px;
    border-radius: 12px;
    width: 100%;
    height: 72px;
    color: #d7d7d7;
    border: 2px solid #d7d7d7;
    cursor: pointer;

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #d7d7d7;
    }

    ${styled && typePaymentModifiers[styled]}
  `}
`;

export const IconTextContainer = Styled.div`
  display: flex;
  align-items: center;
  gap: 36px;

  .anticon {
    color: ${(props) => (props.styled === "selected" ? "#F67E48" : "inherit")};
  }
`;

export const RadialIndicator = Styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;

  background-color: ${(props) =>
    props.styled === "selected" ? "#F67E48" : "transparent"};
`;

export const WrapperOptionsValues = Styled.div`
  display: flex;
  margin-top: 52px;
  max-width: 288px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
export const WrapperInput = Styled.div`
  display: flex;
  flex-direction: row;
  max-width: 142px;
`;

export const InputStyled = Styled(CurrencyInput)`
  border: none;
  font-size: 40px;
  font-weight: 200;
  color: #B1B5C3;
  width: 100%;
  outline: 0;
  text-align: center;

  ::placeholder {
    color: #B1B5C3;
    font-size: 56px;
  }
`;

export const ContainerDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
`

export const WrapperButton = Styled.div`
  width: 100%;
  margin-top: 16px;
  text-align: center;
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #b1b5c3;

  @media (max-width: 480px) {
    display: none;
  }
`;
