import { createContext, useState, useContext } from "react";

export const DonationContext = createContext({})

export const useDonation = () => useContext(DonationContext)

export const STEPS = {
  DONATION_VALUE: 1,
  TYPE_PAYMENT: 2,
  BILLIN_DETAILS: 3,
  PIX_PAYMENT: 4,
  CREDIT_CARD: 5,
  THANKS: 6
}

export const PAYMENTS = {
  CREDIT_CARD: 5,
  PIX_PAYMENT: 4
}

export const ENUM_PAYMENT = {
  4: 'PIX',
  5: 'Cartão de crédito'
}

const DonationProvider = ({ children }) => {
  const [step, setStep] = useState(STEPS.DONATION_VALUE)
  const [donationValue, setDonationValue] = useState(50)
  const [typePayment, setTypePayment] = useState(PAYMENTS.PIX_PAYMENT)
  const [billinDetails, setBillinDetails] = useState({})
  // const [pixPayment, setPixPayment] = useState({})

  const changeStep = (step) => {
    setStep(step)
  }

  const changeTypePayment = (type) => {
    setTypePayment(type)
  }

  const changeDonationValue = (value) => {
    setDonationValue(value)
  }

  const changeBillinDetails = (value) => {
    setBillinDetails(value)
  }

  return (
    <DonationContext.Provider value={{
      changeStep,
      step,
      changeDonationValue,
      donationValue,
      changeTypePayment,
      typePayment,
      changeBillinDetails,
      billinDetails
    }}>
      {children}
    </DonationContext.Provider>
  )
}

export default DonationProvider
