import React, { useState, useEffect } from "react";
import * as S from "./stylesHome";
import AvatarEditor from "react-avatar-editor";
import Button from "../Button";
import { Col, Progress, notification, Modal, Slider } from "antd";
import { useSelector } from "react-redux";
import { getUser } from "../../app/store/authSlice";
import { useAPITag } from "../../services/api";
import { ConfigUserAPI } from "../FormConfigUser/ConfigUserAPI";
import EditIcon from "../../assets/Line.svg";
import { set } from "react-hook-form";

const AvatarChangerHome = ({ link, text, ...props }) => {
  const user = useSelector(getUser);
  const { http } = useAPITag();
  const { updateUser } = ConfigUserAPI();
  const [modalVisible, setModalVisible] = useState(false);
  const [editor, setEditor] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [editedImageDataURL, setEditedImageDataURL] = useState(null);


  useEffect(() => {
    setZoom(1.3);
  }, []);
  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalOk = async () => {
    const canvas = await editor.getImage();
    const dataURL = canvas.toDataURL();
    console.log(dataURL);
    setEditedImageDataURL(dataURL);
    try {
      const response = await uploadFilePromise(
        canvas,
        1,
        1,
        selectedImage.name,
        (percentage) => {
          openNotification(1, 1, selectedImage.name, percentage);
        }
      );
      setEditedImageDataURL(null);
      setSelectedImage(null);
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.value = "";
      }
      window.location.reload();
      setModalVisible(false);
    } catch (error) {
      console.log(error);
      setEditedImageDataURL(null);
      setSelectedImage(null);
    }
  };
  
    // const response = await uploadFilePromise(
    //   canvas,
    //   1,
    //   1,
    //   selectedImage.name,
    //   (percentage) => {
    //     openNotification(1, 1, selectedImage.name, percentage);
    //   }
    // );
    // console.log(response);
    // setModalVisible(false);
    // setEditedImageDataURL(null);
    // document.getElementById("fileInput").value = "";
    // window.location.reload();

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedImage(null);
  };

  const handleZoomChange = (value) => {
    setZoom(value);
  };

  const handleSliderChange = (value) => {
    setZoom(value);
  };

  const handleSliderAfterChange = (value) => {
    if (value === zoom) {
      handleZoomChange(1);
    }
  };


  const getTag = () => {
    function makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    }

    return makeid(16);
  };

  const NOTIFY_ID = getTag();

  const onChangeFiles = (e) => {
    const file = e.target.files[0];
    // Verificar se um arquivo foi selecionado
    if (file) {
      // Verificar se o tipo do arquivo é uma imagem
      if (file.type.startsWith("image/")) {
        setSelectedImage(file);
      } else {
        // Se não for uma imagem, exibir uma mensagem usando o Toast
        setSelectedImage(null);
        notification.error({
          message: "Erro",
          description: "Por favor, selecione uma imagem válida.",
        });
      }
    }
  };
  


  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  const uploadFilePromise = (canvas, index, totalFiles, name) => {
    return new Promise((resolve, reject) => {
      const prefixedName = `Up_${name}`;
      // Convertendo a imagem do canvas para Blob
      canvas.toBlob((blob) => {
        const bodyFormData = new FormData();
        bodyFormData.append("files", blob, prefixedName);
        bodyFormData.append("companyId", user._id);
        bodyFormData.append("filename", name);  // Usando o mesmo nome para o arquivo
        http.post("/files", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            openNotification(totalFiles, index, name, percentage);
          },
        })
        .then((e) => {
          const newFile = e.data;
          console.log(e.data);
          updateUser({ avatar: e.data.url });
          resolve(newFile);
        });
      }, "image/png");
    });
  };

  const openNotification = (
    totalFiles,
    filesCount,
    currentFileName,
    currentFileProgress
  ) => {
    notification.open({
      key: NOTIFY_ID,
      message: "Arquivos sendo enviados - " + `${filesCount} / ${totalFiles}`,
      description: (
        <Col>
          <p>Total</p>
          <Progress percent={Math.floor((filesCount / totalFiles) * 100)} />
        </Col>
      ),
    });
  };

  return (
    <>
      <S.Upload>
        <img className="w-8" src={EditIcon} alt="" onClick={showModal} />
      </S.Upload>
      <Modal
        title="Ajustar Foto"
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Salvar"
        okButtonProps={{ disabled: !selectedImage, style: {
          backgroundColor: selectedImage ? '#f67e48' : 'gray',
        }, }}
      >
        {selectedImage ? (
          <>
            <div style={{ marginBottom: 16 }}>
              <label>Zoom:</label>
              <Slider
                min={1}
                max={2}
                step={0.1}
                value={zoom}
                defaultValue={1.3}  // Definindo o valor padrão como 1.3
                onChange={handleSliderChange}
                onAfterChange={handleSliderAfterChange}
              />
            </div>
            <AvatarEditor
              ref={setEditorRef}
              image={selectedImage}
              borderRadius={150}
              scale={zoom}
            />
          </>
        ) : (
          <input type="file" {...props} onChange={onChangeFiles} />
        )}
      </Modal>
    </>
  );
};

export default AvatarChangerHome;






