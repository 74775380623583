import {
  AimOutlined,
  BarChartOutlined,
  ControlOutlined,
  DeliveredProcedureOutlined,
  ForkOutlined,
  GitlabOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TagOutlined,
  UserAddOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  selectAuthState,
  setPDF,
} from "../../../features/admin/login/loginSlice";
import { Link, useNavigate } from "react-router-dom";
import * as S from "./styles";
import { HomeAPI } from "../../../features/home/HomeAPI";

const { Sider, Content } = Layout;
const Dashboard = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector(selectAuthState);
  const { getPDFUrl } = HomeAPI();
  useEffect(() => {
    const load = async () => {
      const url = await getPDFUrl();

      console.log(url);
      dispatch(setPDF(url));
    };

    load();
  }, []);
  const superAdminRoutes = () => {
    let routes = [];
    if (userState.user.superadmin) {
      routes = [
        {
          key: "2",
          icon: <ControlOutlined />,
          label: <Link to={"/admin/collar-types"}> Tipos de Coleiras</Link>,
        },
        {
          key: "3",
          icon: <ControlOutlined />,
          label: <Link to={"/admin/collar-sizes"}> Tamanhos de QRCode</Link>,
        },
        {
          key: "4",
          icon: <TagOutlined />,
          label: <Link to={"/admin/tags"}> Códigos e Coleiras</Link>,
        },

        {
          key: "49",
          icon: <TagOutlined />,
          label: <Link to={"/admin/collar-assets"}> Assets</Link>,
        },

        {
          key: "11",
          icon: <ForkOutlined />,
          label: (
            <Link to="/admin/companies-collars"> Coleiras das Empresas</Link>
          ),
        },

        {
          key: "5",
          icon: <GitlabOutlined />,
          label: <Link to={"/admin/pets"}> Pets</Link>,
        },

        {
          key: "10",
          icon: <ControlOutlined />,
          label: <Link to={"/admin/companies"}> Empresas </Link>,
        },

        {
          key: "56",
          icon: <ControlOutlined />,
          label: <Link to={"/admin/petshops"}> Petshops </Link>,
        },
        {
          key: "20",
          icon: <WhatsAppOutlined />,
          label: <Link to={"/admin/whatsapp"}> Whatsapp </Link>,
        },
        {
          key: "leads",
          icon: <UserAddOutlined />,
          label: <Link to={"/admin/leads"}> Leads </Link>,
        },
      ];
    }

    return routes;
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout>
      <Sider
        style={{ backgroundColor: "#f2f2f2" }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo" />
        <Menu
          mode="inline"
          style={{ minHeight: "100vh", backgroundColor: "#f2f2f2" }}
          defaultSelectedKeys={[props.selected]}
          items={[
            {
              key: "0",
              icon: <BarChartOutlined />,
              label: <Link to="/admin">Home</Link>,
            },
            {
              key: "1",
              icon: <ForkOutlined />,
              label: <Link to="/admin/collars"> Coleiras</Link>,
            },
            {
              key: "12",
              icon: <UserOutlined />,
              label: <Link to="/admin/users"> Meus Usuários</Link>,
            },
            {
              key: "8",
              icon: <GitlabOutlined />,
              label: <Link to="/admin/pets/me"> Meus Pets</Link>,
            },

            {
              key: "9",
              icon: <DeliveredProcedureOutlined />,
              label: <Link to="/admin/shippments/me"> Minhas Remessas</Link>,
            },

            {
              key: "7",
              icon: <TagOutlined />,
              label: <Link to="/admin/tags/me"> Meus Códigos</Link>,
            },

            {
              key: "6",
              icon: <ControlOutlined />,
              label: <Link to="/admin/config"> Configurações</Link>,
            },

            {
              key: "13",
              icon: <GitlabOutlined />,
              label: <Link to="/admin/tags/qr">Pets Pré Cadastro</Link>,
            },

            ...superAdminRoutes(),
            {
              key: "70",
              icon: <ForkOutlined />,
              label: <Link to="/admin/plates">Customizado</Link>,
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <S.StyledHeader
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <Dropdown
            trigger={["click"]}
            placement="bottomLeft"
            menu={{
              style: { backgroundColor: "#f2f2f2" },
              items: [
                {
                  key: "1",
                  label: (
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(logout());
                        navigate("/");
                      }}
                    >
                      Sair
                    </a>
                  ),
                },
              ],
            }}
          >
            <a
              href="/admin"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Avatar>Admin AjudaPET</Avatar>
            </a>
          </Dropdown>
        </S.StyledHeader>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
