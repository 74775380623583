import React from "react"

const AddressIcon = ({
  title = 'AddressIcon',
  titleId = 'address-icon',
  color = '#777E90',
  width = 16,
  height = 16,
  ...props
}) => (
  <svg
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3332 3.33333H2.6665C2.29831 3.33333 1.99984 3.63181 1.99984 4V12C1.99984 12.3682 2.29831 12.6667 2.6665 12.6667H13.3332C13.7014 12.6667 13.9998 12.3682 13.9998 12V4C13.9998 3.63181 13.7014 3.33333 13.3332 3.33333ZM2.6665 2C1.56193 2 0.666504 2.89543 0.666504 4V12C0.666504 13.1046 1.56193 14 2.6665 14H13.3332C14.4377 14 15.3332 13.1046 15.3332 12V4C15.3332 2.89543 14.4377 2 13.3332 2H2.6665Z" fill={color}/>
    <path d="M5.3335 10.3333C5.3335 10.8855 4.88578 11.3333 4.3335 11.3333C3.78121 11.3333 3.3335 10.8855 3.3335 10.3333C3.3335 9.78097 3.78121 9.33325 4.3335 9.33325C4.88578 9.33325 5.3335 9.78097 5.3335 10.3333Z" fill={color}/>
    <path d="M8.6665 10.3333C8.6665 10.8855 8.21879 11.3333 7.6665 11.3333C7.11422 11.3333 6.6665 10.8855 6.6665 10.3333C6.6665 9.78097 7.11422 9.33325 7.6665 9.33325C8.21879 9.33325 8.6665 9.78097 8.6665 10.3333Z" fill={color}/>
    <path d="M0.666504 5.33325H15.3332V6.66658H0.666504V5.33325Z" fill={color}/>
  </svg>
)

export default AddressIcon