import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import { useAPITag } from "../../../services/api";
import { useParams } from "react-router-dom";
import { WhatsappButton } from "../../../components/WhatsappButton";




export default function PreRegister() {

  const { id } = useParams();
  const { http } = useAPITag();
  const [userPhone, setUserPhone] = useState("");

  const getTag = async (id) => {
    try {
      const response = await http.get("tags/" + id);
      const phone = response.data.tag.userPhone;
      setUserPhone(phone);
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getTag(id);
  } ,[])
  return (
    <>
      <Header />
      <div className="w-full h-screen px-4">
        <div className="flex flex-col justify-center items-center bg-[#F67E48] text-white w-full h-48 mt-12 mx-auto rounded-lg">
          <spa className="text-xl">ENCONTROU ESSE PET?</spa>
          <span className="text-sm mt-2">Clique abaixo e avise os tutores!</span>
        </div>
        <div className="text-white h-[515px]">
          <Button 
            onClick={() => {
              window.open(`https://wa.me/+55${userPhone.replace(/\D/g, "")}`);
            }} 
            className="w-full mt-8">
              Whatsapp
          </Button>
          <button 
            onClick={() => {
              window.open(`tel:+55${userPhone.replace(/\D/g, "")}`);
            }}
            className="mt-4 w-full border border-[#F67E48] text-[15px] min-h-[48px] p-[12px] rounded-full text-[#F67E48]">
              Ligar
          </button>
        </div>
          <WhatsappButton></WhatsappButton>
      </div>
    
    </>
  );
}