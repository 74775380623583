import { Button, Input } from "antd";
import { MaskedInput } from "antd-mask-input";
import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 95%;
  background-color: white;
  padding: 0 2rem 0 2rem;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

export const StyledInput = styled(Input)`
  height: 50px;
  border-radius: 50px;
`;

export const StylePassInput = styled(Input.Password)`
  height: 50px;
  border-radius: 50px;
`;
export const SubmitButton = styled(Button)`
  border-radius: 50px;
  height: 50px;
  width: 344px;
  margin-bottom: 15px;
  margin-top: 25px;
  background-color: #F07E4A;

  @media screen and (max-width: 480px) {
    width: 95%;
    position: fixed;
    bottom: 15px;
    margin-bottom: 0;
    margin: auto;
  }
`;
