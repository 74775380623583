import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useAPIPet, useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { authUser, isWhatsapp, saveState, setCode } from "../RegisterPetSlice";
import { authCustomer } from "../../../app/store/authSlice";

const ConfirmCodeAPI = () => {
  const { http } = useAPITag();
  const { id } = useParams();
  const navigate = useNavigate();
  const isWpp = useSelector(isWhatsapp);
  const dispatch = useDispatch();

  const savePreRegister = async (data) => {
    try {
      const response = await http.patch('/tags/change-status/' + id, data);
    } catch (error) {
      console.log(error);
    }
  }
  const resendSMS = async (phone) => {
    return http.post("/usuario/verify", {
      phone,
    });
  };

  const resendWhatsappCode = async (phone) => {
    return http.post("/user-token/whatsapp", {
      phone,
      userId: phone,
      grantType: "Whatsapp",
      tokenType: "auth-code",
    });
  };

  const CheckCode = async (data) => {
    try {
      if (!isWpp) {
        const response = await http.post("/usuario/check", data);
        if (!response.data.checked) {
          Swal.fire({ icon: "error", title: "Código incorreto ou expirado" });
          return;
        }
        const user = response.data.user;
        if (user) {
          dispatch(authUser(response.data.user));
          dispatch(authCustomer({ user, token: user }));
          const petsResponse = await http.get("pets/user/" + user._id);

          if (petsResponse.data.length > 0) {
            navigate("/select-pet");
            return;
          }

          navigate("/register-pet");
        } else {
          navigate("/register-user");
        }
        dispatch(saveState());
      } else {
        const response = await http.get("/user-token/auth-code/" + data.code);

        const user = response.data;
        if (user) {
          dispatch(authUser(response.data.user));
          dispatch(authCustomer({ user, token: user }));
          const petsResponse = await http.get("pets/user/" + user._id);

          if (petsResponse.data.length > 0) {
            navigate("/select-pet");
            return;
          }
          dispatch(saveState());

          navigate("/register-pet");
        } else {
          navigate("/register-user");
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({ icon: "error", title: "Código incorreto" });
    }
  };
  return { CheckCode, resendSMS, resendWhatsappCode, savePreRegister };
};

export default ConfirmCodeAPI;
