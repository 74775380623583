import React from "react"

const UserIcon = ({
  title,
  titleId,
  size = 24,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20.485 3.515A11.922 11.922 0 0 0 12 0a11.922 11.922 0 0 0-8.485 3.515A11.922 11.922 0 0 0 0 12c0 3.205 1.248 6.219 3.515 8.485A11.922 11.922 0 0 0 12 24c3.205 0 6.219-1.248 8.485-3.515A11.921 11.921 0 0 0 24 12c0-3.205-1.248-6.219-3.515-8.485ZM12 22.594a10.557 10.557 0 0 1-7.35-2.973A7.747 7.747 0 0 1 12 14.297a7.747 7.747 0 0 1 7.35 5.324A10.557 10.557 0 0 1 12 22.594ZM8.484 9.375A3.52 3.52 0 0 1 12 5.859a3.52 3.52 0 0 1 3.516 3.516A3.52 3.52 0 0 1 12 12.891a3.52 3.52 0 0 1-3.516-3.516Zm11.923 9.064a9.167 9.167 0 0 0-5.521-5.08 4.919 4.919 0 0 0 2.036-3.984A4.928 4.928 0 0 0 12 4.453a4.927 4.927 0 0 0-4.922 4.922c0 1.637.803 3.09 2.036 3.985a9.167 9.167 0 0 0-5.52 5.079A10.54 10.54 0 0 1 1.405 12C1.406 6.159 6.16 1.406 12 1.406c5.841 0 10.594 4.753 10.594 10.594 0 2.42-.817 4.653-2.187 6.439Z"
      fill="#6D6E70"
    />
  </svg>
)

export default UserIcon
