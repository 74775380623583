import { useNavigate } from 'react-router-dom';
import { useAPITag } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { saveState, selectConfig } from '../RegisterPetSlice';
import Swal from 'sweetalert2';

const RegisterPetAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);

  const createPet = async (values) => {
    try {
      await http.post('/pets', {
        ...values,
        companyId: config.companyId,
      });
      dispatch(saveState());
      navigate('/success');
    } catch (error) {
      const errorMessage = error.response?.data?.message ||
                          error.response?.data?.error ||
                          error.message ||
                          'Erro ao cadastrar o pet. Tente novamente.';

      Swal.fire({
        icon: 'error',
        title: 'Não foi possível cadastrar o pet',
        text: errorMessage,
        confirmButtonText: "Ir para perfil",
        confirmButtonColor: '#f67e48',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/user-settings");
        }
      });

      throw error;
    }
  };

  return { createPet };
};

export default RegisterPetAPI;
