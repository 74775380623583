import { useNavigate } from "react-router-dom";
import { useAPITag } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { auth, selectAuthState } from "../login/loginSlice";

const DashboardAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthState);

  const loadDashData = async () => {
    try {
      const response = await http.get("dashboard/" + authState.user._id);

      return response.data;
    } catch (error) {}
  };
  return {
    loadDashData,
  };
};

export default DashboardAPI;
