import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAPIPet, useAPITag } from "../../../services/api";
import { useDispatch } from "react-redux";
import { authUser, saveState, setCode } from "../RegisterPetSlice";
import { authCustomer } from "../../../app/store/authSlice";

const ConfirmCodeAPI = () => {
  const { http } = useAPITag();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const CheckCode = async (code, changePassword = false) => {
    try {
      const response = await http.get("/user-token/confirm-code/" + code);

      const user = response.data;
      dispatch(authUser(user));
      dispatch(authCustomer({ user: user, token: user }));

      if (changePassword) {
        navigate("/config-user");
      } else {
        const petsResponse = await http.get("pets/user/" + user._id);

        if (petsResponse.data.length > 0) {
          navigate("/select-pet");
          return;
        }

        navigate("/register-pet");
      }

      dispatch(saveState());
    } catch (error) {
      console.log(error);
      Swal.fire({ icon: "error", title: "Código incorreto" });
    }
  };
  return { CheckCode };
};

export default ConfirmCodeAPI;
