export const racasCachorro = [
  { label: "SRD - Sem raça definida", value: "SRD - Sem raça definida" },
  { label: "Affenpinscher", value: "Affenpinscher" },
  { label: "Afghan Hound", value: "Afghan Hound" },
  { label: "Airedale Terrier", value: "Airedale Terrier" },
  { label: "Akita", value: "Akita" },
  { label: "Akita Americano", value: "Akita Americano" },
  { label: "American Bully", value: "American Bully" },
  { label: "American Pit Bull Terrier", value: "American Pit Bull Terrier" },
  {
    label: "American Staffordshire Terrier",
    value: "American Staffordshire Terrier",
  },
  { label: "Australian Shepherd", value: "Australian Shepherd" },
  { label: "Basenji", value: "Basenji" },
  { label: "Basset Fulvo da Bretanha", value: "Basset Fulvo da Bretanha" },
  { label: "Basset Hound", value: "Basset Hound" },
  { label: "Beagle", value: "Beagle" },
  { label: "Beagle-Harrier", value: "Beagle-Harrier" },
  { label: "Bearded Collie", value: "Bearded Collie" },
  { label: "Bedlington Terrier", value: "Bedlington Terrier" },
  { label: "Bernese Mountain Dog", value: "Bernese Mountain Dog" },
  { label: "Bichon Bolonhês", value: "Bichon Bolonhês" },
  { label: "Bichon Frisé", value: "Bichon Frisé" },
  { label: "Bichon Havanês", value: "Bichon Havanês" },
  { label: "Blue Heeler", value: "Blue Heeler" },
  { label: "Boerboel", value: "Boerboel" },
  { label: "Boiadeiro de Entlebuch", value: "Boiadeiro de Entlebuch" },
  { label: "Border Collie", value: "Border Collie" },
  { label: "Border Terrier", value: "Border Terrier" },
  { label: "Borzoi", value: "Borzoi" },
  { label: "Boston Terrier", value: "Boston Terrier" },
  { label: "Bouvier de Flandres", value: "Bouvier de Flandres" },
  { label: "Boxer", value: "Boxer" },
  { label: "Braco Alemão Pelo Curto", value: "Braco Alemão Pelo Curto" },
  { label: "Braco Alemão Pelo Duro", value: "Braco Alemão Pelo Duro" },
  { label: "Braco Italiano", value: "Braco Italiano" },
  { label: "Buldogue Americano", value: "Buldogue Americano" },
  { label: "Buldogue Francês", value: "Buldogue Francês" },
  { label: "Buldogue Inglês", value: "Buldogue Inglês" },
  { label: "Bull Terrier", value: "Bull Terrier" },
  { label: "Bullmastiff", value: "Bullmastiff" },
  { label: "Cairn Terrier", value: "Cairn Terrier" },
  { label: "Cane Corso", value: "Cane Corso" },
  { label: "Cão de Crista Chinês", value: "Cão de Crista Chinês" },
  { label: "Cão de Santo Humberto", value: "Cão de Santo Humberto" },
  { label: "Cão D’água Espanhol", value: "Cão D’água Espanhol" },
  { label: "Cão D’água Português", value: "Cão D’água Português" },
  { label: "Cão Lobo Checoslovaco", value: "Cão Lobo Checoslovaco" },
  { label: "Cão Pelado Mexicano", value: "Cão Pelado Mexicano" },
  { label: "Cão Pelado Peruano", value: "Cão Pelado Peruano" },
  {
    label: "Cavalier King Charles Spaniel",
    value: "Cavalier King Charles Spaniel",
  },
  { label: "Cesky Terrier", value: "Cesky Terrier" },
  { label: "Chesapeake Bay Retriever", value: "Chesapeake Bay Retriever" },
  { label: "Chihuahua", value: "Chihuahua" },
  { label: "Chin", value: "Chin" },
  { label: "Chow-chow Pelo Curto", value: "Chow-chow Pelo Curto" },
  { label: "Chow-chow Pelo Longo", value: "Chow-chow Pelo Longo" },
  { label: "Cirneco do Etna", value: "Cirneco do Etna" },
  { label: "Clumber Spaniel", value: "Clumber Spaniel" },
  { label: "Cocker Spaniel Americano", value: "Cocker Spaniel Americano" },
  { label: "Cocker Spaniel Inglês", value: "Cocker Spaniel Inglês" },
  { label: "Collie pelo longo", value: "Collie pelo longo" },
  { label: "Coton de Tulear", value: "Coton de Tulear" },
  {
    label: "Dachshund Teckel - Pelo Curto",
    value: "Dachshund Teckel - Pelo Curto",
  },
  { label: "Dálmata", value: "Dálmata" },
  { label: "Dandie Dinmont Terrier", value: "Dandie Dinmont Terrier" },
  { label: "Dobermann", value: "Dobermann" },
  { label: "Dogo Argentino", value: "Dogo Argentino" },
  { label: "Dogo Canário", value: "Dogo Canário" },
  { label: "Dogue Alemão", value: "Dogue Alemão" },
  { label: "Dogue de Bordeaux", value: "Dogue de Bordeaux" },
  { label: "Elkhound Norueguês Cinza", value: "Elkhound Norueguês Cinza" },
  { label: "Fila Brasileiro", value: "Fila Brasileiro" },
  { label: "Flat-Coated Retriever", value: "Flat-Coated Retriever" },
  { label: "Fox Terrier Pelo Duro", value: "Fox Terrier Pelo Duro" },
  { label: "Fox Terrier Pelo Liso", value: "Fox Terrier Pelo Liso" },
  { label: "Foxhound Inglês", value: "Foxhound Inglês" },
  { label: "Galgo Espanhol", value: "Galgo Espanhol" },
  { label: "Golden Retriever", value: "Golden Retriever" },
  { label: "Grande Münsterländer", value: "Grande Münsterländer" },
  { label: "Greyhound", value: "Greyhound" },
  { label: "Griffon Belga", value: "Griffon Belga" },
  { label: "Griffon de Bruxelas", value: "Griffon de Bruxelas" },
  { label: "Husky Siberiano", value: "Husky Siberiano" },
  { label: "Ibizan Hound", value: "Ibizan Hound" },
  { label: "Indefinida", value: "Indefinida" },
  {
    label: "Irish Soft Coated Wheaten Terrier",
    value: "Irish Soft Coated Wheaten Terrier",
  },
  { label: "Irish Wolfhound", value: "Irish Wolfhound" },
  { label: "Jack Russell Terrier", value: "Jack Russell Terrier" },
  { label: "Kerry Blue Terrier", value: "Kerry Blue Terrier" },
  { label: "Komondor", value: "Komondor" },
  { label: "Kuvasz", value: "Kuvasz" },
  { label: "Labrador Retriever", value: "Labrador Retriever" },
  { label: "Lagotto Romagnolo", value: "Lagotto Romagnolo" },
  { label: "Lakeland Terrier", value: "Lakeland Terrier" },
  { label: "Leonberger", value: "Leonberger" },
  { label: "Lhasa Apso", value: "Lhasa Apso" },
  { label: "Malamute do Alasca", value: "Malamute do Alasca" },
  { label: "Maltês", value: "Maltês" },
  { label: "Mastiff", value: "Mastiff" },
  { label: "Mastim Espanhol", value: "Mastim Espanhol" },
  { label: "Mastino Napoletano", value: "Mastino Napoletano" },
  { label: "Mudi", value: "Mudi" },
  { label: "Nordic Spitz", value: "Nordic Spitz" },
  { label: "Norfolk Terrier", value: "Norfolk Terrier" },
  { label: "Norwich Terrier", value: "Norwich Terrier" },
  {
    label: "Nova Scotia Duck Tolling Retriever",
    value: "Nova Scotia Duck Tolling Retriever",
  },
  { label: "Old English Sheepdog", value: "Old English Sheepdog" },
  { label: "Papillon", value: "Papillon" },
  { label: "Parson Russell Terrier", value: "Parson Russell Terrier" },
  { label: "Pastor Alemão", value: "Pastor Alemão" },
  { label: "Pastor Beauceron", value: "Pastor Beauceron" },
  { label: "Pastor Belga", value: "Pastor Belga" },
  { label: "Pastor Bergamasco", value: "Pastor Bergamasco" },
  { label: "Pastor Branco Suíço", value: "Pastor Branco Suíço" },
  { label: "Pastor Briard", value: "Pastor Briard" },
  { label: "Pastor da Ásia Central", value: "Pastor da Ásia Central" },
  { label: "Pastor de Shetland", value: "Pastor de Shetland" },
  { label: "Pastor dos Pirineus", value: "Pastor dos Pirineus" },
  { label: "Pastor Maremano Abruzês", value: "Pastor Maremano Abruzês" },
  { label: "Pastor Polonês", value: "Pastor Polonês" },
  { label: "Pastor Polonês da Planície", value: "Pastor Polonês da Planície" },
  {
    label: "Pequeno Basset Griffon da Vendéia",
    value: "Pequeno Basset Griffon da Vendéia",
  },
  { label: "Pequeno Cão Leão", value: "Pequeno Cão Leão" },
  { label: "Pequeno Lebrel Italiano", value: "Pequeno Lebrel Italiano" },
  { label: "Pequinês", value: "Pequinês" },
  { label: "Perdigueiro Português", value: "Perdigueiro Português" },
  { label: "Petit Brabançon", value: "Petit Brabançon" },
  { label: "Pharaoh Hound", value: "Pharaoh Hound" },
  { label: "Pinscher Miniatura", value: "Pinscher Miniatura" },
  { label: "Pitbull", value: "Pitbull" },
  { label: "Podengo Canário", value: "Podengo Canário" },
  { label: "Podengo Português", value: "Podengo Português" },
  { label: "Pointer Inglês", value: "Pointer Inglês" },
  { label: "Poodle Anão", value: "Poodle Anão" },
  { label: "Poodle Médio", value: "Poodle Médio" },
  { label: "Poodle Standard", value: "Poodle Standard" },
  { label: "Poodle Toy", value: "Poodle Toy" },
  { label: "Pug", value: "Pug" },
  { label: "Puli", value: "Puli" },
  { label: "Pumi", value: "Pumi" },
  { label: "Rhodesian Ridgeback", value: "Rhodesian Ridgeback" },
  { label: "Rottweiler", value: "Rottweiler" },
  { label: "Saluki", value: "Saluki" },
  { label: "Samoieda", value: "Samoieda" },
  { label: "São Bernardo", value: "São Bernardo" },
  { label: "Schipperke", value: "Schipperke" },
  { label: "Schnauzer", value: "Schnauzer" },
  { label: "Schnauzer Gigante", value: "Schnauzer Gigante" },
  { label: "Schnauzer Miniatura", value: "Schnauzer Miniatura" },
  { label: "Scottish Terrier", value: "Scottish Terrier" },
  { label: "Sealyham Terrier", value: "Sealyham Terrier" },
  { label: "Setter Gordon", value: "Setter Gordon" },
  { label: "Setter Inglês", value: "Setter Inglês" },
  { label: "Setter Irlandês Vermelho", value: "Setter Irlandês Vermelho" },
  {
    label: "Setter Irlandês Vermelho e Branco",
    value: "Setter Irlandês Vermelho e Branco",
  },
  { label: "Shar-pei", value: "Shar-pei" },
  { label: "Shiba", value: "Shiba" },
  { label: "Shih-Tzu", value: "Shih-Tzu" },
  { label: "Silky Terrier Australiano", value: "Silky Terrier Australiano" },
  { label: "Skye Terrier", value: "Skye Terrier" },
  { label: "Smoushond Holandês", value: "Smoushond Holandês" },
  { label: "Spaniel Bretão", value: "Spaniel Bretão" },
  { label: "Spinone Italiano", value: "Spinone Italiano" },
  { label: "Spitz Alemão Anão", value: "Spitz Alemão Anão" },
  { label: "Spitz Finlandês", value: "Spitz Finlandês" },
  { label: "Spitz Japonês Anão", value: "Spitz Japonês Anão" },
  { label: "Springer Spaniel Inglês", value: "Springer Spaniel Inglês" },
  { label: "Stabyhoun", value: "Stabyhoun" },
  { label: "Staffordshire Bull Terrier", value: "Staffordshire Bull Terrier" },
  { label: "Terra Nova", value: "Terra Nova" },
  {
    label: "Terrier Alemão de caça Jagd",
    value: "Terrier Alemão de caça Jagd",
  },
  { label: "Terrier Brasileiro", value: "Terrier Brasileiro" },
  {
    label: "Terrier Irlandês de Glen do Imaal",
    value: "Terrier Irlandês de Glen do Imaal",
  },
  { label: "Terrier Preto da Rússia", value: "Terrier Preto da Rússia" },
  { label: "Tibetan Terrier", value: "Tibetan Terrier" },
  { label: "Tosa Inu", value: "Tosa Inu" },
  { label: "Vira-Latas", value: "Vira-Latas" },
  { label: "Vizsla", value: "Vizsla" },
  { label: "Volpino Italiano", value: "Volpino Italiano" },
  { label: "Weimaraner", value: "Weimaraner" },
  { label: "Welsh Corgi Cardigan", value: "Welsh Corgi Cardigan" },
  { label: "Welsh Corgi Pembroke", value: "Welsh Corgi Pembroke" },
  { label: "Welsh Springer Spaniel", value: "Welsh Springer Spaniel" },
  { label: "Welsh Terrier", value: "Welsh Terrier" },
  {
    label: "West Highland White Terrier",
    value: "West Highland White Terrier",
  },
  { label: "Whippet", value: "Whippet" },
  { label: "Yorkshire Terrier", value: "Yorkshire Terrier" },
];

export const racasGato = [
  { label: "SRD - Sem raça definida", value: "SRD - Sem raça definida" },
  { label: "Abyssinian", value: "Abyssinian" },
  { label: "American Bobtail Longhair", value: "American Bobtail Longhair" },
  { label: "American Bobtail Shorthair", value: "American Bobtail Shorthair" },
  { label: "American Shorthair", value: "American Shorthair" },
  { label: "American Wirehair", value: "American Wirehair" },
  { label: "Arabian Mau", value: "Arabian Mau" },
  { label: "Asian Semi-long Hair", value: "Asian Semi-long Hair" },
  { label: "Australian Mist", value: "Australian Mist" },
  { label: "Bengal", value: "Bengal" },
  { label: "Bobtail Japonês", value: "Bobtail Japonês" },
  { label: "Bombaim", value: "Bombaim" },
  { label: "Brazilian Shorthair", value: "Brazilian Shorthair" },
  { label: "British Longhair", value: "British Longhair" },
  { label: "Burmês", value: "Burmês" },
  { label: "California Spangled Cat", value: "California Spangled Cat" },
  { label: "Chausie", value: "Chausie" },
  { label: "Cornish Rex", value: "Cornish Rex" },
  { label: "Curl Americano Pelo Curto", value: "Curl Americano Pelo Curto" },
  { label: "Curl Americano Pelo Longo", value: "Curl Americano Pelo Longo" },
  { label: "Cymric", value: "Cymric" },
  { label: "Devon Rex", value: "Devon Rex" },
  { label: "Domestic Long-Haired", value: "Domestic Long-Haired" },
  { label: "Domestic Short-Haired", value: "Domestic Short-Haired" },
  { label: "Don Sphynx", value: "Don Sphynx" },
  { label: "Egyptian Mau", value: "Egyptian Mau" },
  { label: "European", value: "European" },
  { label: "Exotic Shorthair", value: "Exotic Shorthair" },
  { label: "German Rex", value: "German Rex" },
  { label: "Havana", value: "Havana" },
  { label: "Himalaio", value: "Himalaio" },
  { label: "Indefinida", value: "Indefinida" },
  { label: "Khao Manee", value: "Khao Manee" },
  { label: "Korat", value: "Korat" },
  { label: "Kurilian Bobtail Longhair", value: "Kurilian Bobtail Longhair" },
  { label: "Kurilian Bobtail Shorthair", value: "Kurilian Bobtail Shorthair" },
  { label: "LaPerm Longhair", value: "LaPerm Longhair" },
  { label: "LaPerm Shorthair", value: "LaPerm Shorthair" },
  { label: "Maine Coon", value: "Maine Coon" },
  { label: "Manx", value: "Manx" },
  { label: "Mekong Bobtail", value: "Mekong Bobtail" },
  { label: "Munchkin Longhair", value: "Munchkin Longhair" },
  { label: "Munchkin Shorthair", value: "Munchkin Shorthair" },
  { label: "Nebelung", value: "Nebelung" },
  { label: "Norwegian Forest Cat", value: "Norwegian Forest Cat" },
  { label: "Ocicat", value: "Ocicat" },
  { label: "Ojos Azules Shorthair", value: "Ojos Azules Shorthair" },
  { label: "Oriental Longhair", value: "Oriental Longhair" },
  { label: "Oriental Shorthair", value: "Oriental Shorthair" },
  { label: "Persa", value: "Persa" },
  { label: "Peterbald", value: "Peterbald" },
  { label: "Pixiebob Longhair", value: "Pixiebob Longhair" },
  { label: "Pixiebob Shorthair", value: "Pixiebob Shorthair" },
  { label: "Ragamuffin", value: "Ragamuffin" },
  { label: "Ragdoll", value: "Ragdoll" },
  { label: "Russo Azul", value: "Russo Azul" },
  { label: "Sagrado da Birmânia", value: "Sagrado da Birmânia" },
  { label: "Savannah Cat", value: "Savannah Cat" },
  { label: "Scottish Fold", value: "Scottish Fold" },
  { label: "Selkirk Rex Longhair", value: "Selkirk Rex Longhair" },
  { label: "Selkirk Rex Shorthair", value: "Selkirk Rex Shorthair" },
  { label: "Serengeti", value: "Serengeti" },
  { label: "Siamês", value: "Siamês" },
  { label: "Siberian", value: "Siberian" },
  { label: "Singapura", value: "Singapura" },
  { label: "Snowshoe", value: "Snowshoe" },
  { label: "Sokoke", value: "Sokoke" },
  { label: "Somali", value: "Somali" },
  { label: "Sphynx", value: "Sphynx" },
  { label: "Thai", value: "Thai" },
  { label: "Tonkinese Shorthair", value: "Tonkinese Shorthair" },
  { label: "Toyger", value: "Toyger" },
  { label: "Turkish Angorá", value: "Turkish Angorá" },
  { label: "Turkish Van", value: "Turkish Van" },
  { label: "York Chocolate", value: "York Chocolate" },
];
export const generos = [
  {
    label: "Macho",
    value: "macho",
  },
  {
    label: "Fêmea",
    value: "femea",
  },
];
export const especies = [
  {
    label: "Cachorro",
    value: "cachorro",
  },
  {
    label: "Gato",
    value: "gato",
  },
];
export const tamanhos = [
  {
    label: "Micro",
    value: "micro",
  },
  {
    label: "Pequeno",
    value: "pequeno",
  },
  {
    label: "Médio",
    value: "medio",
  },
  {
    label: "Grande",
    value: "grande",
  },
];
export const rabos = [
  {
    label: "Sim",
    value: true,
  },
  {
    label: "Não",
    value: false,
  },
];

export const tiposSaude = [
  {
    label: "Tudo certo",
    value: "tudoCerto",
  },
  {
    label: "Em tratamento",
    value: "emTratemento",
  },
  {
    label: "Necessita tratamento",
    value: "necessitaTratamento",
  },
];

export const petFeatures = [
  {
    label: "Medroso",
    value: "medroso",
  },
  {
    label: "Late/Mia",
    value: "latidor",
  },
  {
    label: "Brincalhão",
    value: "brincalhao",
  },
  {
    label: "Carinhoso",
    value: "carinhoso",
  },
  {
    label: "Agitado",
    value: "agitado",
  },
  {
    label: "Caçador",
    value: "cacador",
  },
  {
    label: "Curioso",
    value: "curioso",
  },
  {
    label: "Agressivo",
    value: "agressivo",
  },
  {
    label: "Sociável com outros animais",
    value: "socialAnimais",
  },
  {
    label: "Sociável com humanos",
    value: "socialvelHumanos",
  },
  {
    label: "Atento",
    value: "atento",
  },

  {
    label: "Impusilvo",
    value: "impulsivo",
  },
  {
    label: "Assustado",
    value: "assustado",
  },
  {
    label: "Marca território",
    value: "demarcaTudo",
  },
  {
    label: "Arisco",
    value: "arisco",
  },
  {
    label: "Quieto",
    value: "quieto",
  },
  {
    label: "Encrenqueiro",
    value: "encrenqueiro",
  },
  {
    label: "Bagunceiro",
    value: "bagunceiro",
  },
  {
    label: "Reservado",
    value: "reservado",
  },
];

export const petFeaturesProfile = [
  {
    label: "Medroso",
    value: "scared",
  },
  {
    label: "Late/Mia",
    value: "barking",
  },
  {
    label: "Brincalhão",
    value: "playful",
  },
  {
    label: "Carinhoso",
    value: "affectionate",
  },
  {
    label: "Agitado",
    value: "agitated",
  },
  {
    label: "Caçador",
    value: "hunter",
  },
  {
    label: "Curioso",
    value: "curious",
  },
  {
    label: "Agressivo",
    value: "aggressive",
  },
  {
    label: "Sociável com outros animais",
    value: "sociableToAnimals",
  },
  {
    label: "Sociável com humanos",
    value: "sociableToHumans",
  },
  {
    label: "Atento",
    value: "attentive",
  },

  {
    label: "Impusilvo",
    value: "impulsive",
  },
  {
    label: "Assustado",
    value: "scared",
  },
  {
    label: "Marca território",
    value: "marksTerritory",
  },
  {
    label: "Arisco",
    value: "skittish",
  },
  {
    label: "Quieto",
    value: "quieto",
  },
  {
    label: "Encrenqueiro",
    value: "troublesome",
  },
  {
    label: "Bagunceiro",
    value: "messy",
  },
  {
    label: "Reservado",
    value: "reserved",
  },
];
