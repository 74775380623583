import styled, { css } from 'styled-components'
import { darken } from 'polished'

const wrapperModifiers = {
  fullWidth: () => css`
    width: 100%;
  `,

  minimal: (theme) => css`
    background: none;
    color: ${theme.colors.primary};
    text-transform: none;
    letter-spacing: normal;
    font-weight: 600;
    font-size: 14px;
    min-height: 40px;
    min-width: 99px;
    max-width: 108px;
    line-height: 12px;
    background: none;
    
    min-height: fit-content;
    &:hover {
      color: ${darken(0.1, theme.colors.primary)};
    }
  `,

  secundary: (theme) => css`
    background-position: 100% 0%;
    background-size: 200% 1%;
    border: 2px solid ${theme.colors.primary};
    background: ${theme.colors.secondary};
    color: ${theme.colors.primary};
  `,

  disabled: () => css`
    &:disabled {
      cursor: not-allowed;
      filter: saturate(30%);
    }
  `,

  primary: (theme) => css`
    background-position: 100% 0%;
    background-size: 200% 1%;
    background: ${theme.colors.primary};
    color: ${theme.colors.secondary};
  `,

  gray: () => css`
    background: #bdbdbd;
  `
}

export const Wrapper = styled.button`
  ${({ theme, fullWidth, minimal, disabled, color }) => css`
    align-items: center;
    border-radius: 99px;
    border: 0;
    color: ${theme.colors.secondary};
    cursor: pointer;
    display: inline-flex;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 2px;
    line-height: 16px;
    min-height: 48px;
    padding: 15px 36px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: background 0.3s ease-in-out;
    position: relative;
    outline: none;
    &:hover {
      background-position: 0% 0%;
    }
    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${color && wrapperModifiers[color](theme)};
    ${!!minimal && wrapperModifiers.minimal(theme)};
    ${disabled && wrapperModifiers.disabled()};
  `}
`

export const WrapperText = styled.div`
  ${({ isLoading }) => css`
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    ${isLoading &&
    css`
      transform: translateY(12px) scale(0.75);
      opacity: 0;
    `}
  `}
`
