import axios from "axios"
import { _baseUrl } from "../utils/constants"

export const getDoneeById = (id) => {
    return axios.get(`${_baseUrl}/donee/${id}`)
        .then(res => {
            return res
        })
        .catch(err => {
            return {
                err
            }
        })
}


export const getUserByEmail = (email) => {
    return axios.get(`${_baseUrl}/user/email/${email}`)
        .then(res => {
            return res
        })
        .catch(err => {
            return {
                err
            }
        })
}

export const getDoneeByEntityId = (entityId) => {
    return axios.get(`${_baseUrl}/donee/entityId/${entityId}`)
        .then(res => {
            console.log('res: ', res)
            // if (res && res.data) {
                return res
            // }
        })
        .catch(err => {
            return {
                ...err
            }
        })
}

export const getDoneeByCustomLink = (customLink) => {
    return axios.get(`${_baseUrl}/donee/customLink/${customLink}`)
        .then(res => {
            console.log('customLink: ', customLink)
            console.log('res: ', res)
            return res
        })
        .catch(err => {
            return {
                ...err
            }
        })
}

export const getDoneeEntityByCustomLink = (customLink) => {
    return axios.get(`${_baseUrl}/custom-link/${customLink}`)
        .then(res => {
            console.log('customLink: ', customLink)
            console.log('res: ', res)
            if (res && res.data) {
                return getDoneeById(res.data._id)
            }
        })
        .catch(err => {
            return {
                ...err
            }
        })
}

export const getDoneeEntityByTotem = (totem) => {
    return axios.get(`${_baseUrl}/totemId/${totem}`)
        .then(res => {
            if (res && res.data) {
                return getDoneeById(res.data._id)
            }
        })
        .catch(err => {
            return {
                ...err
            }
        })
}
export const getDoneeByTotem = (totem) => {
    return axios.get(`${_baseUrl}/totemId/${totem}`)
        .then(res => {
            if (res && res.data) {
                return res.data
            }
        })
        .catch(err => {
            return {
                ...err
            }
        })
}

export const createDonee = (body) => {
    return axios.post(`${_baseUrl}/donee/create`, body)
        .then(res => {
            console.log('res.data: ', res.data)
            if (res && res.data) {
                return res.data
            }
        })
        .catch(err => {
            return {
                errors: [
                    err.response.data.errors
                ]
            }
        })
}

// export const updateCustomLink = (body) => {
//     return axios.put(`${_baseUrl}/donee/update`, body)
//         .then(res => {
//             console.log('res.data: ', res.data)
//             return res
//         })
// }

export const linkTotemToDonee = (id, body) => {
    return axios.put(`${_baseUrl}/totemId/${id}`, body)
        .then(res => {
            console.log('res.data: ', res.data)
            return res
        })
}
