import { configureStore } from "@reduxjs/toolkit";
import AuthCustomerReducer from "./store/authSlice";
import AuthReducer from "../features/admin/login/loginSlice";
import RegisterPetReducer from "../features/register-pet/RegisterPetSlice";
import SimulatorReducer from "../features/simulator/simulator.slice";

import ModalFileReducer from "../components/filesModal/filesModalSlice";
import thunkMiddleware from "redux-thunk";
import DonationReducer from "../donate/donate.slice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    registerPet: RegisterPetReducer,
    fileModal: ModalFileReducer,
    authCustomer: AuthCustomerReducer,
    donate: DonationReducer,
    simulator: SimulatorReducer,
  },
  middleware: [thunkMiddleware],
});
