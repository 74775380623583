import Styled from "styled-components";

export const Box = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 512px;
  min-height: 700px;
  height: 100%;
  border-radius: 32px;
  padding: 30px 60px;
  background: #fff;
  z-index: 100;

  @media (max-width: 480px) {
    width: 100vw;
    padding: 20px 20px;
    }
`;
