import Swal from "sweetalert2";
import useLogin from "../../../data/usecases/use-login";

export const Auth = () => {
  const signin = useLogin();
  const run = (data) => {
    signin(data);
  };

  return run;
};
